.partner-lower {
    padding: 30px 5vw;
    padding-bottom: 50px;
    // background-image: url(../../assets/images/footer-bg.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  
    h1 {
      text-align: center;
      color: #fff;
      margin: 30px 0;
      margin-bottom: 72px;
      font-size: 42px;
    }
  
    .top {
      display: flex;
      justify-content: center;
      align-items: center;
      // gap: 88px;
  
      .img {
        width: 150px;
        margin: 0 44px;
  
        img {
          width: 100%;
        }
      }
    }
  
    .bottom {
      margin-top: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .img {
        img {
          width: 180px;
        }
      }
    }
  }
  
  .partner-info {
    background: none;
  }
  
  @media screen and (max-width: 760px) {
    .partner-lower {
      .top {
        // gap: 32px;
        flex-wrap: wrap;
  
        .img {
          width: 120px;
          margin: 16px;
          min-width: 120px;
        }
      }
  
      h1 {
        font-size: 36px;
      }
  
      .bottom {
        .img {
          img {
            width: 140px;
          }
        }
      }
    }
  
    .partner-info {
      padding: 0;
    }
  }