.jobdescription {
  .top {
    .img {
      background-color: #000000;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .title {
      color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 56px;
        text-transform: uppercase;
      }
      .back-to-prev {
        display: block;
        text-decoration: none;
        color: #ffffff;
        width: max-content;
        font-size: 28px;
        margin-bottom: 16px;
        width: max-content;
      }
    }
  }
  .content-container {
    background-color: #ffffff;
    .content {
      background-color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      .body-text {
        margin: 32px auto;
        padding: 0 20%;
        .desc {
          p,
          h3,
          h4,
          h5,
          h6,
          ol,
          ul {
            margin-bottom: 20px;
            font-family: "Cabinet Grotesk";
            font-size: 20px;
          }
          ol,
          ul {
            margin-left: 20px;
          }
        }
        h2 {
          font-size: 52px;
          margin-bottom: 48px;
          color: #202e55;
        }
        p {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          margin-bottom: 80px;
        }
        .apply {
          margin-top: 40px;
          a {
            background-color: #202e55;
            color: #ffffff;
            text-decoration: none;
            font-size: 24px;
            font-family: "Gratelos";
            padding: 14px 24px;
            border-radius: 26px;
            border: 2px solid #202e55;
            transition: all ease-in-out 0.3s;
          }

          :hover {
            background-color: transparent;
            color: #202e55;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .jobdescription {
    .top {
      .title {
        padding: 30px 24px;
        h1 {
          font-size: 48px;
        }
      }
    }
    .content-container {
      .content {
        .body-text {
          padding: 0 12%;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .jobdescription {
    .content-container {
      .content {
        padding: 30px 24px;
        .body-text {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .jobdescription {
    .top {
      .title {
        padding: 30px 16px;
        .back-to-prev {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
    .content-container {
      .content {
        padding: 30px 16px;
        .body-text {
          margin-top: 20px;
          .application {
            .buttons {
              flex-direction: column-reverse;
              gap: 48px;
              margin-top: 24px;
            }
          }
          h2 {
            font-size: 36px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
