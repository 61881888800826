// @tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.PhoneInputInput {
  flex: 1 1;
  // min-width: 0;
  height: 40px;
  border: none;
  font-size: larger;
  font-weight: 100;
  width: 100%;
}
