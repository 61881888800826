.shop_card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; // Ensure details stay at the bottom
  height: 400px; // Set a fixed height for uniformity
  background-color: #fafafa;
  padding: 24px;
  overflow: hidden;
  transition: transform 0.3s ease; // Add transition for smooth zoom effect

  &:hover {
    transform: scale(1.05); // Scale up on hover
    // box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); // Optional: Add a subtle shadow
  }

  .img {
    flex: 1; // Allow the image section to grow and take available space
    display: flex; // Use flex to center the image
    align-items: center; // Center the image vertically
    justify-content: center; // Center the image horizontally

    .shop_card_carousel {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        border-radius: 8px;
        max-width: 80%; // Ensure images don't exceed card width
        max-height: 70%; // Set a max height to prevent overflow
        object-fit: cover; // Maintain aspect ratio
        display: block; // Ensures that images are centered in flex
        transition: transform 0.3s ease; // Add transition for image zoom

        // Optional: Slight zoom effect on the image itself on hover
        .shop_card:hover & {
          transform: scale(1.05); // Scale the image slightly on hover
        }
      }
    }
  }

  .details {
    color: #000000;

    .name {
      text-align: center;
      font-size: 16px;
      margin-top: 12px; // Adjust margin to prevent excessive spacing
      font-family: "Cabinet Grotesk";
      text-transform: capitalize;
      font-weight: bold;
      color: #202e55;
      height: 40px; // Keep height to align with other details
    }
  }
}

@media screen and (max-width: 414px) {
  .shop_card {
    height: 350px; // Adjust height for smaller screens if necessary
    .details {
      .name {
        font-size: 20px;
      }
    }
  }
}
