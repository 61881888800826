.newspage {
  //   padding: 0 64px;
  margin-bottom: 80px;
  .title {
    // height: 100vh;
    max-width: 1512px;
    margin: auto;
    margin-top: 146px;
    padding: 0 64px;
    @media screen and (max-width: 690px) {
      padding: 0 10px;
    }
    h2 {
      font-size: 56px;
      color: #ffffff;
      margin-bottom: 30px;
    }
    .featured-news {
      .news {
        .news_container {
          .items {
            .side {
              background-color: rgba($color: #ffffff, $alpha: 0.05);
            }
          }
        }
      }
    }
  }
}
.view {
  width: fit-content;
  margin: auto;
  margin-top: 24px;
  // margin-bottom: 80px;
  button {
    background-color: #202e55;
    color: #ffffff;
    font-size: 24px;
    font-family: "Gratelos";
    text-decoration: none;
    padding: 14px 24px;
    border-radius: 26px;
    border: none;
  }
}

@media screen and (max-width: 1024px) {
  .newspage {
    .title {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .newspage {
    margin-bottom: 40px;
    .title {
      margin-top: 120px;
      h2 {
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .newspage {
    .title {
      padding: 0 16px;
    }
  }
  .view {
    button {
      font-size: 16px;
      padding: 12px 24px;
    }
  }
}
