.lineup-container {
  background-color: #ffffff;
  padding: 48px 64px;
  width: 30%;
  z-index: 1;
  .title {
    font-size: 28px;
    color: #202e55;
    text-align: center;
  }
  .team-icons {
    display: flex;
    align-items: center;
    justify-content: space-around;

    margin-top: 50px;
    .host-logo,
    .opponent-logo {
      text-align: center;
      img {
        height: 84px;
        opacity: 1;
      }
    }
    p {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
    }
  }
  .icon_rev {
    flex-direction: row-reverse;
  }
  .sub-title {
    font-family: "Cabinet Grotesk";
    font-size: 20px;
    text-align: center;
    margin: 48px 0;
  }
  .players-list {
    display: flex;
    justify-content: space-around;
    .left-players {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // gap: 10px;
      .flank {
        height: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .player_name {
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        text-align: right;
        justify-content: right;
        display: flex;
        align-items: center;
        .player_no {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          font-weight: bold;
        }
        .y-card {
          height: 15px;
          width: 12px;
          background: #fadf4a;
          margin-right: 4px;
        }
        .r-card {
          height: 15px;
          width: 12px;
          background: #f24822;
          margin-right: 4px;
        }
      }
      .substitute {
        font-family: "Cabinet Grotesk";
        font-size: 14px;
        display: flex;
        align-items: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        border-radius: 12px;
        padding: 4px;
        width: max-content;
        .sub-icon {
          opacity: 1;
          width: 12px;
          margin-left: 4px;
          height: unset;
        }
        .player_no {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          font-weight: bold;
        }
        .sub-minute {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.5);
          margin-right: 4px;
        }
      }
    }
    .right-players {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .flank {
        height: 70px;
      }

      .player_name {
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        display: flex;
        align-items: center;
        .player_no {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          font-weight: bold;
        }
        .y-card {
          height: 15px;
          width: 12px;
          background: #fadf4a;
          margin-left: 4px;
        }
        .r-card {
          height: 15px;
          width: 12px;
          background: #f24822;
          margin-left: 4px;
        }
      }
      .substitute {
        font-family: "Cabinet Grotesk";
        font-size: 14px;
        display: flex;
        // flex-direction: row-reverse;
        align-items: center;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        border-radius: 12px;
        padding: 4px;
        width: max-content;
        .sub-icon {
          opacity: 1;
          width: 12px;
          margin-right: 4px;
          height: unset;
        }
        .player_no {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          font-weight: bold;
        }
        .sub-minute {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.5);
          margin-left: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .lineup-container {
    padding: 48px 24px;
    .players-list {
      gap: 16px;
    }
  }
}

@media screen and (max-width: 980px) {
  .lineup-container {
    width: 90%;
    margin: auto;
    border-radius: 8px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 414px) {
  .lineup-container {
    width: max-content;
    .players-list {
      .right-players,
      .left-players {
        .player_name {
          font-size: 16px;
          .player_no {
            font-size: 16px;
          }
        }
        .substitute {
          font-size: 12px;
          .player_no {
            font-size: 12px;
          }
          .sub-minute {
            font-size: 12px;
          }
        }
      }
    }
  }
}
