.landing_story {
  background-image: linear-gradient(to bottom left, #fadf4a, #181f39);
  display: flex;
  // height: 418px;
  // width: 100%;
  // gap: 10%;
  // padding: 40px 64px;
  align-items: center;
  overflow: hidden;
  .banner {
    position: relative;
    height: 418px;
    width: 100%;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // border-radius: 8px;
    }
    .overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      padding-left: 64px;
      height: 100%;
      width: 100%;
      top: 0;
      // border-radius: 8px;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 83.29%);
      p {
        color: #ffffff;
        font-size: 68px;
        width: 414px;
        height: auto;
        margin-top: 20px;
        // margin: auto;
      }
      .mantra {
        font-family: "Cabinet Grotesk";
        font-size: 32px;
      }
    }
  }
  // .left {
  //   color: #ffffff;
  //   background-image: url("../../../../assets/images/logo2.png");
  //   background-repeat: no-repeat;
  //   background-size: contain;
  //   background-attachment: scroll;
  //   background-position: right;
  //   margin: 64px;
  //   width: 30%;
  //   .quote {
  //     font-size: 50px;
  //   }
  //   p {
  //     font-size: 68px;
  //   }
  .link {
    margin: 20px 0;
    color: #ffffff;
    font-size: 25px;
    text-decoration: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 20px;
    width: max-content;
    span {
      margin-top: 4px;
      font-size: 32px;
    }
    // a {
    //   background-color: transparent;
    //   color: inherit;
    //   text-decoration: none;
    //   display: flex;
    //   align-items: center;
    //   gap: 20px;
    //   font-size: 25px;
    //   width: max-content;
    //   color: #ffffff;
    // }
  }
  // }
  // .right {
  //   flex-grow: 2;
  //   width: 100%;
  //   margin: 0;
  //   overflow: hidden;
  //   align-self: stretch;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     transform: rotate(4deg);
  //   }
  // }

  // .img {
  //   width: 100%;
  // }
}

@media screen and (max-width: 768px) {
  .landing_story {
    .banner {
      .overlay {
        background: rgba($color: #000000, $alpha: 0.5);
        align-items: center;
        padding-left: 0px;
        p {
          font-size: 36px;
          text-align: center;
          width: unset;
        }
        .mantra {
          font-size: 22px;
          padding: 0 24px;
        }
      }
    }
    .link {
      font-size: 16px;
      gap: 8px;
      span {
        font-size: 18px;
        margin-top: 2px;
      }
    }
  }
}
