.review-details-container {
  background-color: #f2f2f2;
  .review-details {
    display: flex;
    // max-width: 1512px;
    // margin: 0 auto;
    // padding: 0 64px;
  }
  //   .right-bg {
  //     background-color: #ffffff;
  //     height: 100%;
  //     width: 50%;
  //     position: absolute;
  //     right: 0;
  //   }
  .left {
    flex-grow: 1;
  }
}
@media screen and (max-width: 980px) {
  .review-details-container {
    .review-details {
      flex-direction: column;
      padding: 0 24px;
    }
  }
}
