.all-news {
  background-color: #f2f2f2;
  .news-container {
    max-width: 1512px;
    margin: auto;
    padding: 40px 64px;
    .news_title {
      font-size: 56px;
      color: #202e55;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
    .content {
      display: grid;
      grid-auto-rows: 300px;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      min-height: 600px;

      .items {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
      @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .news-title {
    h1 {
      text-transform: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .all-news .news-container .content {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 1024px) {
  .all-news {
    .news-container {
      padding: 40px 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .all-news {
    .news-container {
      h2 {
        font-size: 36px;
      }
      .content {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
      }
      .news_title {
        font-size: 40px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .all-news {
    .news-container {
      padding: 40px 16px;
    }
  }
}
