.matches-page {
  //   margin-bottom: 80px;
  .tab {
    cursor: pointer;
  }
  .matches-title {
    max-width: 1512px;
    margin: auto;
    margin-top: 146px;
    padding: 0 64px;
    h2 {
      font-size: 56px;
      color: #ffffff;
      margin-bottom: 32px;
    }
    .team-menu {
      display: flex;
      ul {
        display: flex;
        list-style: none;
        // gap: 32px;
        .tab {
          margin-right: 32px;
          text-transform: uppercase;
        }

        .team-active {
          p {
            text-decoration: underline;
            text-decoration-thickness: 4px;
          }
        }
        p {
          color: #ffffff;
          font-size: 28px;
          text-transform: uppercase;
        }
      }
    }
  }
  .sticky-match-menu {
    position: -webkit-sticky;
    position: sticky;
    top: v.$header_h;
    z-index: 1;
    .match-menu {
      background-color: #ffffff;
      margin-top: 3px;
      padding-top: 24px;
      padding-bottom: 12px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      ul {
        display: flex;
        list-style: none;
        justify-content: center;
        align-items: center;
        max-width: 240px;
        margin: auto;
        p {
          color: rgba($color: #000000, $alpha: 0.3);
        }
        .match-menu-active {
          p {
            color: #202e55;
          }
        }
        li {
          width: 50%;
          height: 100%;
          p {
            text-align: center;
            font-size: 33px;
          }
        }
      }
      .underline {
        display: flex;
        max-width: 240px;
        margin: auto;
        margin-top: 12px;
        background-color: rgba($color: #000000, $alpha: 0.3);
        height: 2px;
        align-items: center;
        .active-1 {
          width: 50%;
          height: 4px;
          background-color: #202e55;
        }
      }
      .right_line {
        flex-direction: row-reverse;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .matches-page {
    .matches-title {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .matches-page {
    .matches-title {
      margin-top: 120px;
      h2 {
        font-size: 40px;
        margin-bottom: 16px;
      }
      .team-menu {
        ul {
          p {
            font-size: 20px;
          }
        }
      }
    }
    .sticky-match-menu {
      top: 48px;
      z-index: 1;
      .match-menu {
        padding-top: 16px;
        padding-bottom: 4px;
        ul {
          li {
            p {
              font-size: 20px;
            }
          }
        }
        .underline {
          margin-top: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .matches-page {
    .matches-title {
      padding: 0 16px;
    }
  }
}
