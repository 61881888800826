.profile-top-container {
  //   background-color: #181f39;
  overflow: hidden;
  .bg {
    width: 100%;
    height: 460px;
    position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    img {
      position: absolute;
      width: 150vw;
      opacity: 0.05;
    }
  }
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     height: 500px;
  //     opacity: 0.05;
  //     // z-index: -1;
  //     background-image: url(../../../assets/images/sporting-logo-large.svg);
  //     background-repeat: no-repeat;
  //     background-size: 150vw;
  //     background-position: center;
  //   }

  .profile-top {
    // display: flex;
    // justify-content: space-between;
    max-width: 1512px;
    padding: 80px 200px;
    margin: 0 auto;

    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .user-identity {
        display: flex;
        align-items: center;
        .user-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .user-name {
          h1 {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 84px;
            margin-left: 40px;
          }
        }
      }
      .ctas {
        .f_member {
          width: 180px;
          //   height: 80px;
          background-color: #ffffff;
          border: 2px solid #ffffff;
          border-radius: 8px;
          text-align: center;
          margin-bottom: 20px;
          a {
            color: #202e55;
            text-transform: uppercase;
            padding: 12px 32px;
            //   text-align: center;
            p {
              font-size: 24px;
            }
          }
        }
        .buy_jersey {
          width: 180px;
          //   height: 80px;
          background-color: transparent;
          border: 2px solid #ffffff;
          border-radius: 8px;
          text-align: center;
          margin-bottom: 20px;
          a {
            color: #ffffff;
            text-transform: uppercase;
            padding: 12px 32px;
            //   text-align: center;
            p {
              font-size: 24px;
            }
          }
        }
      }
    }
    .profile-menu {
      display: flex;
      margin-left: 210px;
      margin-top: 72px;
      .logout,
      .edit-profile {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
        p {
          color: #ffffff;
          text-transform: uppercase;
          font-size: 24px;
        }
      }
      .logout {
        p {
          margin-right: 32px;
        }
      }
    }
  }
}

.profile-content-container {
  background: #ffffff;
  .profile-content {
    max-width: 1512px;
    margin: 0 auto;
    padding: 80px 200px;
    .body {
      p {
        font-family: "Cabinet Grotesk";
        font-size: 36px;
      }
    }
    .user-stats {
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      gap: 24px;
      .stat-item {
        .property {
          p {
            font-family: "Cabinet Grotesk";
            font-size: 24px;
            // text-transform: uppercase;
            color: #202e55;
          }
        }
        .value {
          p {
            font-size: 78px;
            color: #202e55;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .profile-top-container {
    .profile-top {
      padding: 80px 64px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .profile-top-container {
    .profile-top {
      padding: 80px 24px;
      .top {
        .user-identity {
          .user-name {
            h1 {
              font-size: 64px;
            }
          }
        }
      }
      .profile-menu {
        margin-left: 160px;
      }
    }
  }
  .profile-content-container {
    .profile-content {
      padding: 80px 24px;
      .body {
        p {
          font-size: 26px;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .profile-top-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .bg {
      top: 5px;
      height: 100%;
      img {
        width: 230vw;
        top: auto;
        bottom: auto;
        // height: 100%;
      }
    }
    .profile-top {
      height: 630px;
      padding: unset;
      margin: unset;
      max-width: unset;
      //   width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .top {
        flex-direction: column;
        .user-identity {
          flex-direction: column;
          .user-name {
            margin-top: 24px;
            h1 {
              margin-left: unset;
              text-align: center;
            }
          }
        }
        .ctas {
          position: absolute;
          top: 480px;
          left: auto;
          right: auto;
          width: 90%;
          .f_member {
            width: 100%;
          }
          .buy_jersey {
            width: 100%;
          }
        }
      }
      .profile-menu {
        margin-left: unset;
        margin-top: 44px;
      }
    }
  }
  .profile-content-container {
    .profile-content {
      .user-stats {
        flex-direction: column;
        margin-top: 64px;
        .stat-item {
          .value {
            p {
              font-size: 56px;
            }
          }
        }
      }
    }
  }
}
