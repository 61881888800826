.header {
  background-color: v.$accent;
  height: v.$header_h;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 50;
  padding-top: 10px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 64px;
    color: #ffffff;
    max-width: 1512px;
    gap: 3vw;
    margin: auto;
    .menu {
      font-size: 25px;
      cursor: pointer;
      display: none;
      img {
        width: 24px;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
      }
    }
    .logo {
      height: 100%;
      width: max-content;
      display: flex;
      align-items: center;
      img {
        width: 42px;
        // width: 56px; old logo
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
      }
    }
    .nav-container {
      flex-grow: 1;
      display: flex;
      gap: 32px;
      flex-wrap: nowrap;
      a {
        text-decoration: none;
        font-family: "Gratelos";
        font-size: 28px;
        color: inherit;
        display: flex;
      }
      .more {
        cursor: pointer;
        font-family: "Gratelos";
        font-size: 28px;
        color: inherit;
        position: relative;
        div {
          display: flex;
        }

        .chevron {
          align-self: center;
          font-size: 18px;
          margin-left: 8px;
        }

        .sub_overlay {
          position: absolute;
          top: 0;
          left: -50%;
          width: 250px;
          .sub_link {
            cursor: default;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            right: 0;
            background-color: #ffffff;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0px 0px 5px grey;
            :hover {
              background-color: #f2f2f2;
            }
            .link {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding: 8px 16px 20px 16px;

              a {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                text-decoration: none;
                font-family: "Gratelos";
                font-size: 22px;
                color: v.$accent;
                padding: 8px 0;
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
      .more2 {
        cursor: pointer;
        font-family: "Gratelos";
        font-size: 28px;
        color: inherit;
        position: relative;
        div {
          display: flex;
        }

        .chevron {
          align-self: center;
          font-size: 18px;
          margin-left: 8px;
        }

        .sub_overlay {
          position: absolute;
          top: 0;

          width: 250px;
          .sub_link {
            cursor: default;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            right: 0;
            background-color: #ffffff;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0px 0px 5px grey;
            :hover {
              background-color: #f2f2f2;
            }
            .link {
              border-bottom: 1px solid rgba(0, 0, 0, 0.1);
              padding: 8px 16px 20px 16px;

              a {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                text-decoration: none;
                font-family: "Gratelos";
                font-size: 22px;
                color: v.$accent;
                padding: 8px 0;
              }
              &:last-child {
                border: none;
              }
            }
          }
        }
      }
    }
    .other-items {
      flex-grow: 1;
      display: flex;
      gap: 2vw;
      justify-content: flex-end;
      // filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
      .search-input {
        position: absolute;
        background-color: v.$accent;
        padding: 0 64px;
        left: 0 !important;
        right: 0 !important;
        z-index: -1;
        padding-top: calc(v.$header_h + 10px);
        padding-bottom: 10px;
        top: 0 !important;
        transition: all ease-in-out 0.4s;
        display: flex;
        column-gap: 30px;
        align-items: center;
        .search_close {
          cursor: pointer;
        }
        .delay {
          opacity: 1;
          transition: all ease-in-out 0.3s;
          transition-delay: 0.3s;
        }
        .delay_non {
          opacity: 0;
          transition: all ease-in-out 0.1s;
        }
        .search_button {
          cursor: pointer;
          border: 2px solid #ffffff;
          border-radius: 50px;
          height: 100%;
          //   width: 10%;
          color: #ffffff;
          justify-content: center;
          align-items: center;
          padding: 12px 24px;
          display: flex;
          p {
            font-size: 24px;
            text-transform: uppercase;
          }
        }
        .search_input {
          width: 100%;
          background-color: rgba(255, 255, 255, 0.1);
          height: 70%;
          align-items: center;
          display: flex;
          border-radius: 8px;
          .input {
            display: block;
            outline: none;
            border: none;
            color: #ffffff;
            padding: 12px;
            font-size: 28px;
            box-sizing: border-box;
            border: none;
            width: 98%;
            height: 100%;
            background-color: transparent;
            &::placeholder {
              color: rgba($color: #ffffff, $alpha: 0.3);
            }
          }
          .close {
            // font-size: 30px;
            cursor: pointer;
            // color: #000000;
            // width: 20px;
            margin-right: 8px;
          }
        }
      }

      .hide_search {
        padding-top: 0;
        overflow: hidden;
        left: 20%;
        right: 20%;
        z-index: -1;
        top: 10px;
        align-items: center;
        display: flex;
        opacity: 0;
        transition: all ease-in-out 0.3s;
      }
      .right-items {
        display: flex;
        align-items: center;
        .search {
          font-size: 32px;
          padding: 10px 16px;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
          }
        }
        .mobile {
          display: none;
        }
        .user {
          font-size: 32px;
          margin-left: 32px;
          position: relative;
          cursor: pointer;
          .avatar {
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 100px;
            margin: 0;
            object-fit: cover;
          }
          .avatar2 {
            width: 2.5rem;
            height: 1.8rem;
            border-radius: 100px;
            margin: 0;
            object-fit: cover;
          }
          .text {
            background-color: #59c150;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0px 6px 0px;
            p {
              font-family: "Cabinet Grotesk";
              text-transform: uppercase;
              font-size: 23px;
            }
          }
          .sgn {
            display: flex;
            align-items: center;

            span {
              font-family: "Cabinet Grotesk";
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .header-content {
      padding: 0 24px;
      .nav-container {
        a,
        .more,
        .more2 {
          font-size: 24px;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
        }
      }
      .other-items {
        flex-grow: 0;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .header {
    height: 48px;
    // padding-top: 4px; new logo
    padding-top: 7px;
    padding-bottom: 4px;
    .header-content {
      padding: 0 24px;
      .logo {
        img {
          width: 26px;
          // width: 38px; old logo
          // filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
        }
      }
      .menu {
        display: block;
        // width: 33.33%;
        // filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.25));
      }
      .logo {
        width: 33.33%;
        justify-content: center;
      }
      .nav-container {
        display: none;
      }
      .other-items {
        flex-grow: 0;
        // width: 33.33%;
        .search-input {
          display: none;
        }
        .right-items {
          .search {
            display: none;
          }
          .mobile {
            margin-top: -5px;
            display: flex;
            padding: 0;
            img {
              width: 24px;
            }
          }
          .user {
            padding-left: 0px;
            margin-left: 0;
            .sgn {
              img {
                width: 24px;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .header {
    .header-content {
      padding: 0 16px;
    }
  }
}
