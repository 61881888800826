.hometickets {
  .top {
    .img {
      background-color: #000000;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .title {
      color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 60px;
      }
      .back-to-prev {
        display: block;
        text-decoration: none;
        color: #ffffff;
        width: max-content;
        font-size: 28px;
        margin-bottom: 16px;
        width: max-content;
      }
    }
  }
  .content-container {
    background-color: #ffffff;
  }
  .content {
    background-color: #ffffff;
    padding: 30px 64px;
    max-width: 1512px;
    margin: auto;
    .landing_story {
      height: 350px;
      box-sizing: border-box;
      .right {
        width: initial;
      }
    }
    .inner_content {
      margin: 25px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        "i1 i2 i3"
        "i4 i4 i4";
      grid-auto-rows: 330px;
      gap: 16px;

      .inner1 {
        grid-area: i1;
      }
      .inner2 {
        grid-area: i2;
      }
      .inner3 {
        grid-area: i3;
      }
      .inner4 {
        grid-area: i4;
      }
      .inner5 {
        grid-area: i5;
      }
      .inner6 {
        grid-area: i6;
      }
      .inner7 {
        grid-area: i7;
      }
      .club_card {
        width: 100%;
        height: 100%;

        position: relative;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 90%
        );
        .img {
          height: 100%;
          width: 100%;

          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .bottom {
          position: absolute;
          bottom: 0;
          height: 100%;
          font-size: 25px;
          left: 0;
          right: 0;
          border-radius: 8px;
          color: #ffffff;
          .text {
            margin: 20px;
            text-transform: uppercase;
            position: absolute;
            bottom: 0;
          }
          background-image: linear-gradient(
            360deg,
            #000000 -15.11%,
            rgba(0, 0, 0, 0.494575) 31.74%,
            rgba(0, 0, 0, 0) 69.6%
          );
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .hometickets {
    .top {
      .title {
        padding: 30px 24px;
        h1 {
          font-size: 48px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .hometickets {
    .top {
      .title {
        padding: 30px 16px;
        .back-to-prev {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
  }
}
