.tv_landing {
  max-width: 1512px;
  margin: 0 auto;
  padding: 40px 64px;
  color: #ffffff;
  background-color: v.$primary;
  .tv-header {
    h1 {
      font-family: "Gratelos";
      font-size: 56px;

      z-index: 10;
    }
  }
  .tv {
    margin-top: -45px;
  }
  .link {
    margin: 46px auto;
    width: fit-content;
    a {
      display: flex;
      align-items: center;
      width: fit-content;
      background-color: transparent;
      color: #ffffff;
      font-family: "Gratelos";
      font-size: 24px;
      text-decoration: none;
      letter-spacing: 0;
      padding: 8px 24px;
      padding-left: 36px;
      border-radius: 26px;
      border: 2px solid #ffffff;
      span {
        font-size: 24px;
        margin-top: 4px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .tv_landing {
    padding: 40px 0px;
    .tv-header {
      padding: 0 64px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tv_landing {
    // padding: 40px 0px;
    .tv-header {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .tv_landing {
    .link {
      a {
        font-size: 16px;
        padding: 6px 14px;
        padding-left: 26px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tv_landing {
    padding: 40px 0px;
    .tv-header {
      padding: 0 16px;
      h1 {
        font-size: 36px;
      }
    }
  }
}
