.player_card_display {
  // border-radius: 8px;
  max-width: 100%;
  overflow: hidden;
  height: 695px;
  position: relative;
  background-image: url("../../../assets/images/playerbackground.svg");
  background-color: #4b7aee;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;

  .img {
    position: absolute;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding-top: 20px;
    }
  }
  .details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 42px;
    padding-bottom: 252px;
    color: #ffffff;
    // background-image: linear-gradient(
    //   to top,

    //   #080e27,
    //   #181f39c0,
    //   #181f3900
    // );
    .number {
      font-size: 271px;
      z-index: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .player_card_display {
    height: 650px;
  }
}

@media screen and (max-width: 1024px) {
  .player_card_display {
    height: 500px;
    .img {
      display: flex;
      justify-content: center;
      height: 100%;
      img {
        width: auto;
      }
    }
    .details {
      padding: 16px;
      padding-bottom: 182px;
      .number {
        font-size: 181px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .player_card_display {
    background-position: center center;
    height: 430px;
    .details {
      padding-bottom: 144px;
    }
    .img {
      img {
        // width: 400px;
        padding-top: 32px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .player_card_display {
    height: 400px;
    .details {
      padding-bottom: 126px;
      .number {
        font-size: 145px;
      }
    }
    .img {
      img {
        // width: 342px;
        padding-top: 62px;
      }
    }
  }
}
