.careers {
  .top {
    .img {
      background-color: #000000;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .title {
      color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 60px;
      }
      .back-to-prev {
        display: block;
        text-decoration: none;
        color: #ffffff;
        width: max-content;
        font-size: 28px;
        margin-bottom: 16px;
        width: max-content;
      }
    }
  }
  .content-container {
    background-color: #ffffff;
    .content {
      background-color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      .body-text {
        margin: 32px auto;
        // width: 50%;
        padding: 0 20%;
        .info {
          font-size: 20px;
          margin-bottom: 30px;
          font-family: "Cabinet Grotesk";
          font-size: 20px;
        }
        .application {
          padding: 40px 30px;
          background-color: #f2f2f2;
          border-radius: 8px;
          margin-bottom: 40px;
          .desc {
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 8;
            -webkit-line-clamp: 8;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            p,
            h3,
            h4,
            h5,
            h6,
            ol,
            ul {
              margin-bottom: 20px;
              font-family: "Cabinet Grotesk";
              font-size: 20px;
            }
            ol,
            ul {
              margin-left: 20px;
            }
          }
          .buttons {
            margin-top: 40px;
            display: flex;
            gap: 24px;
            .jd {
              a {
                color: #202e55;
                text-decoration: none;
                font-size: 24px;
                font-family: "Gratelos";
                padding: 3px 0;
                border-bottom: 2px solid transparent;
                transition: all ease-in-out 0.3s;
              }

              :hover {
                color: #202e55;
                border-bottom: 2px solid #202e55;
              }
            }
            .apply {
              a {
                background-color: #202e55;
                color: #ffffff;
                text-decoration: none;
                font-size: 24px;
                font-family: "Gratelos";
                padding: 14px 24px;
                border-radius: 26px;
                border: 2px solid #202e55;
                transition: all ease-in-out 0.3s;
              }

              :hover {
                background-color: transparent;
                color: #202e55;
              }
            }
          }
        }
        h2 {
          font-size: 50px;
          margin-bottom: 40px;
          color: #202e55;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .careers {
    .top {
      .title {
        padding: 30px 24px;
        h1 {
          font-size: 48px;
        }
      }
    }
    .content-container {
      .content {
        .body-text {
          padding: 0 12%;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .careers {
    .content-container {
      .content {
        padding: 30px 24px;
        .body-text {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .careers {
    .top {
      .title {
        padding: 30px 16px;
        .back-to-prev {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
    .content-container {
      .content {
        padding: 30px 16px;
        .body-text {
          margin-top: 20px;
          .application {
            .buttons {
              flex-direction: column-reverse;
              gap: 48px;
              margin-top: 24px;
            }
          }
          h2 {
            font-size: 36px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
