.news_top {
  width: 100%;
  height: 93vh;
  object-fit: cover;
  position: relative;
  //   margin-top: 40px;

  overflow: hidden;
  .img {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.9;
    }
  }

  .card {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
      360deg,
      #000000 -15.11%,
      rgba(0, 0, 0, 0.494575) 31.74%,
      rgba(0, 0, 0, 0) 69.6%
    );
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .news-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 48px 23%;
    }

    .topp {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      // gap: 4px;
      .icon {
        font-size: 20px;
      }
      .category {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        font-weight: bold;
      }
      .duration {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        font-weight: bold;
        color: rgba(225, 225, 225, 0.6);
      }
    }

    .topp > * + * {
      margin-left: 4px;
    }

    h1 {
      font-family: "Gratelos";
      font-size: 84px;
      width: 100%;
      text-transform: uppercase;
    }
  }
}
.news_bottom {
  background-color: #ffffff;
  .author {
    .data {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px 0;
      p {
        font-family: "Cabinet Grotesk";
        font-size: 16px;
      }
    }

    font-size: 30px;
    padding: 0 23%;
  }
  .share {
    padding: 20px 23%;
    font-size: 30px;
    .items {
      display: flex;
      align-items: center;
      // gap: 20px;
      p {
        font-family: "Cabinet Grotesk";
        font-size: 16px;
        font-weight: bold;
        margin-right: 20px;
      }
      button {
        margin-right: 20px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .news_top {
    .card {
      .news-title {
        padding: 30px 12%;
        h1 {
          font-size: 64px;
        }
      }
    }
  }
  .news_bottom {
    .author {
      padding: 0 12%;
    }
    .share {
      padding: 20px 12%;
    }
  }
}

@media screen and (max-width: 760px) {
  .news_top {
    height: 70vh;
    .card {
      .news-title {
        padding: 24px;
        h1 {
          font-size: 48px;
        }
      }
      .topp {
        margin-bottom: 12px;
        .category,
        .duration {
          font-size: 14px;
        }
      }
    }
  }
  .news_bottom {
    .author {
      padding: 0 24px;
      .data {
        flex-direction: column;
      }
    }
    .share {
      padding: 20px 24px;
    }
  }
}

@media screen and (max-width: 414px) {
  .news_top {
    // height: 50vh;
    .card {
      .news-title {
        padding: 16px;
        h1 {
          font-size: 38px;
        }
      }
      .topp {
        margin-bottom: 12px;
        .category,
        .duration {
          font-size: 12px;
        }
      }
    }
  }
  .news_bottom {
    .author {
      padding: 0 16px;
    }
    .share {
      padding: 20px 16px;
    }
  }
}
