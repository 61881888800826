.error-container {
  //   height: 50vh;
  margin-top: 80px;
  background: #ffffff;

  .error {
    display: flex;
    padding: 64px;
    max-width: 1200px;
    margin: 0 auto;
    .error-banner {
      width: 100%;
      height: max-content;
      overflow: hidden;
      border-radius: 8px;
      margin-right: 32px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .error-message {
      width: 100%;
      h1 {
        font-size: 80px;
        margin-bottom: 24px;
      }
      p {
        font-family: "Cabinet Grotesk";
        font-size: 24px;
        margin-bottom: 16px;
      }
      .small {
        font-size: 18px;
      }
      button {
        font-size: 24px;
        text-transform: uppercase;
        background: v.$accent;
        color: #ffffff;
        padding: 14px 24px;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .error-container {
    margin-top: 40px;
    .error {
      padding: 24px;
      flex-direction: column;
      .error-banner {
        margin-right: unset;
        margin-bottom: 24px;
      }
      .error-message {
        button {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .error-container {
    .error {
      padding: 16px;
      .error-message {
        h1 {
          font-size: 56px;
        }
        button {
          font-size: 16px;
          padding: 12px 24px;
        }
      }
    }
  }
}
