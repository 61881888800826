.whoswho {
  .member {
    // margin: 20px 0;
    margin-bottom: 40px;
    h1 {
      font-size: 56px;
      font-family: "Gratelos";
      color: v.$primary;
      margin-bottom: 30px;
    }
    .members_display {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      .whocard {
        .avi {
          height: 100%;
          img {
            width: 100%;
            background-color: #f2f2f2;
            border-radius: 8px;
          }
        }

        .name {
          margin-top: 28px;
          font-size: 24px;
          font-family: "Cabinet Grotesk";
          font-weight: bold;
        }
        .position {
          margin: 5px 0;
          font-size: 24px;
          font-family: "Cabinet Grotesk";
          //   font-weight: 100;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .whoswho {
    .member {
      .members_display {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .whoswho {
    .member {
      .members_display {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .whoswho {
    .member {
      h1 {
        font-size: 36px;
      }
      .members_display {
        display: block;
        .whocard {
          display: flex;
          gap: 20px;
          margin-bottom: 24px;
          .avi {
            height: 64px;
            width: 64px;
            // overflow: hidden;
            // border-radius: 8px;
            img {
              width: unset;
              height: 100%;
            }
          }

          .name {
            margin-top: unset;
            font-size: 16px;
          }
          .position {
            font-size: 16px;
          }
        }
      }
    }
  }
}
