.foundingmembers {
  .top {
    .img {
      background-color: #000000;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .title {
      color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 60px;
      }
      .back-to-prev {
        display: block;
        text-decoration: none;
        color: #ffffff;
        width: max-content;
        font-size: 28px;
        margin-bottom: 16px;
        width: max-content;
      }
    }
  }
  .cta {
    background-color: #000000;
    padding: 56px;
    .host-logo {
      min-width: 130px;
      text-align: center;
      margin-bottom: 64px;
      img {
        height: 130px;
      }
    }
    p {
      width: 90%;
      margin: auto;
      font-family: "Cabinet Grotesk";
      font-size: 24px;
      text-align: center;
      color: #ffffff;
    }

    .link {
      margin: 46px auto;
      width: fit-content;
      a,
      .paystack_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: transparent;
        color: #ffffff;
        font-family: "Gratelos";
        font-size: 24px;
        text-decoration: none;
        letter-spacing: 0;
        padding: 8px 24px;
        // padding-left: 36px;
        border-radius: 26px;
        border: 2px solid #ffffff;
        span {
          font-size: 24px;
          margin-top: 4px;
        }
      }
    }
  }
  .content-container {
    background-color: #ffffff;
    .content {
      background-color: #f2f2f2;
      display: flex;
      max-width: 1512px;
      padding: 48px 64px;
      margin: auto;
      h2 {
        font-size: 52px;
        color: #202e55;
        // margin-right: 64px;
        width: 50%;
        @media screen and (max-width: 500px) {
          width: unset;
          font-size: 36px;
        }
      }
      .members {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 48px;
        width: 100%;
        @media screen and (max-width: 500px) {
          grid-template-columns: 1fr;
        }

        .members-id {
          //   margin-right: 80px;
          margin-bottom: 40px;
          @media screen and (max-width: 500px) {
            margin-bottom: 24px;
          }
          .name {
            font-family: "Cabinet Grotesk";
            font-size: 26px;
            @media screen and (max-width: 500px) {
              font-size: 20px;
            }
          }
          .occupation {
            font-family: "Cabinet Grotesk";
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            color: rgba($color: #000000, $alpha: 0.5);
            @media screen and (max-width: 500px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .foundingmembers {
    .top {
      .title {
        padding: 30px 24px;
        h1 {
          font-size: 48px;
        }
      }
    }
    .content-container {
      .content {
        padding: 48px 24px;
      }
    }
  }
}
@media screen and (max-width: 760px) {
  .foundingmembers {
    .content-container {
      .content {
        display: block;
        padding: 24px;
        h2 {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .foundingmembers {
    .top {
      .title {
        padding: 30px 16px;
        .back-to-prev {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
    .cta {
      padding: 56px 24px;
      p {
        width: unset;
        font-size: 20px;
      }
    }
    .content-container {
      .content {
        padding: 24px 16px;
      }
    }
  }
}
