// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@use "variables" as v;

@font-face {
  font-family: "Gratelos";
  src: url("../assets/fonts/GratelosDisplay.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../assets/fonts/CabinetGrotesk-Regular.otf") format("truetype");
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  --swiper-theme-color: #ffffff;
  font-family: "Gratelos";
  font-weight: normal;
}
a {
  text-decoration: none;
  color: inherit;
}

body {
  background-color: v.$primary;
}
.nothing {
  margin-top: v.$header_h;
  padding: 20%;
  h1 {
    text-align: center;
  }
}
.section_title {
  color: v.$accent;
  font-size: 56px;
  font-family: "Gratelos";
  text-transform: uppercase;
  width: max-content;
}
.loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .lottie_loader {
    width: 25%;
  }
}
.blur {
  filter: blur(10px);
  clip-path: inset(0);
}
.blur_done {
  filter: blur(0px);
  transition: filter 0.5s linear;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgba(255, 255, 255, 0.568);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: v.$accent;
}
.MuiInputBase-root,
.MuiInput-root:before {
  border-bottom: none !important;
}
.MuiInputBase-root,
.MuiInput-root:hover {
  border-bottom: none !important;
}
.MuiInputBase-root,
.MuiInput-root:after {
  border-bottom: none !important;
}
.overlay {
  background-color: #00000084;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .overlay_text {
    width: fit-content;
    margin: 20vh auto;
    text-transform: uppercase;
  }
}
.Toastify__toast-container {
  @media screen and (min-width: 700px) {
    width: 80%;
  }
  @media screen and (min-width: 1000px) {
    width: 70%;
  }
  @media screen and (min-width: 1512px) {
    width: 50%;
  }
}

@media screen and (max-width: 414px) {
  .section_title {
    font-size: 36px;
  }
}

@import "./components/carousel/carousel.scss";
@import "./layout/header.scss";
@import "./layout/sidebar.scss";
@import "./components/landing/matches/countdown.scss";
@import "./components/landing/matches/matchlanding.scss";
@import "./components/landing/matches/standing.scss";
@import "./components/matchcard.scss";
@import "./components/landing/matches/matchreview.scss";
@import "./components/landing/news/landingnews.scss";
@import "./components/newscard.scss";
@import "./components/landing/social/sociallanding.scss";
@import "./components/landing/story/story.scss";
@import "./components/videoplayer.scss";
@import "./components/landing/tvlanding/tvcarousel.scss";
@import "./components/landing/tvlanding/tvlanding.scss";
@import "./components/landing/storelanding/storelanding.scss";
@import "./components/shop/shopcard.scss";
@import "./components/shop/shopcarousel.scss";
@import "./components/player/playercard.scss";
@import "./components/landing/playerlanding/playercarousel.scss";
@import "./components/landing/playerlanding/playerlanding.scss";
@import "./components/achieve/achieve.scss";
@import "./components/partner.scss";
@import "./components/partnerlower.scss";
@import "./layout/footer.scss";
@import "./pages/news.scss";
@import "./components/news/featurednews.scss";
@import "./components/news/categorymenu.scss";
@import "./components/news/allnews.scss";
@import "./layout/layout.scss";
@import "./pages/player.scss";
@import "./components/player/playerdetails.scss";
@import "./components/player/playercarddisplay.scss";
@import "./components/player/playerprofile.scss";
@import "./components//player/playerstats.scss";
@import "./pages/matches.scss";
@import "./components/matches/schedule.scss";
@import "./components/matches/resultcard.scss";
@import "./components/matches/nextmatch.scss";
@import "./components/matches/fixturecard.scss";
@import "./components/matches/competitions.scss";
@import "./components/matches/leaguetable.scss";
@import "./components/matches/resultreview.scss";
@import "./components/matches/reviewdetails.scss";
@import "./components/matches/matchblog.scss";
@import "./components/matches/lineup.scss";
@import "./components/player/playernews.scss";
@import "./components/player/playerdata.scss";
@import "./components/news/newsdisplaytop.scss";
@import "./components/news/newsdisplay.scss";
@import "./components/news/gallerydisplay.scss";
@import "~video-react/styles/scss/video-react";
@import "./components/news/highlightdisplay.scss";
@import "./pages/clubinfo.scss";
@import "./pages/tickets.scss";
@import "./components/tickets/hometickets.scss";
@import "./components/tickets/hometicketslist.scss";
@import "./components/tickets/ticketscard.scss";
@import "./pages/clubhistory.scss";
@import "./pages/foundingmembers.scss";
@import "./pages/careers/careers.scss";
@import "./pages/careers/jobdescription.scss";
@import "./pages/shop/shophome.scss";
@import "./components/clubinformation/whoswho/whocard.scss";
@import "./components/clubinformation/partner/partnercard.scss";
@import "./components/clubinformation/trophy/trophycard.scss";
@import "./pages/careers/jobapply.scss";
@import "./pages/auth/signin.scss";
@import "./pages/auth/signup.scss";
@import "./pages/auth/membershipform.scss";
@import "./pages/profile/profile.scss";
@import "./pages/sportingtv/videodisplay.scss";
@import "./pages/sportingtv/sportingtv.scss";
@import "./components/reset.scss";
@import "./components/profile/viewprofile.scss";
@import "./pages/searchpage.scss";
@import "./spinner.scss";
@import "./components/error404.scss";
@import "./pages/membership.scss";
@import "./pages/Academy.scss";
@import "./pages/JerseyPage.scss";
@import "./pages/viewmember/viewmember.scss";
