.player_landing {
  max-width: 1512px;
  margin: auto;
  padding: 40px 64px;
  .title {
    color: #fff;
    text-transform: uppercase;
    font-size: 56px;
  }
  .link {
    margin: 20px auto;
    a {
      display: flex;
      margin: 32px auto;
      align-items: center;
      width: fit-content;
      color: #ffffff;
      font-size: 26px;
      text-decoration: none;
      letter-spacing: 0;
      padding: 14px 24px;
      padding-left: 32px;
      border: 2px solid #fff;
      border-radius: 26px;
      span {
        font-size: 20px;
        margin-top: 5px;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .player_landing {
    padding: 40px 0;
    .title {
      padding: 0 64px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .player_landing {
    // padding: 40px 24px;
    .title {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 414px) {
  .player_landing {
    // padding: 40px 16px;
    .title {
      font-size: 36px;
      padding: 0 16px;
    }
    .link {
      a {
        font-size: 16px;
        padding: 6px 14px;
        padding-left: 26px;
      }
    }
  }
}
