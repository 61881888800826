.featured-news {
  //   background-color: black;
  .news_container {
    display: grid;
    grid-template-areas:
      "tl tl tl tr tr"
      "tl tl tl bc br";
    grid-gap: 16px;
    grid-auto-rows: 360px;
    @media screen and (max-width: 1100px) {
      grid-template-areas:
        "tl tl tl"
        "tl tl tl"
        "tl tl tl"
        "tr tr tr"
        "bc bc bc"
        "br br br";
      grid-auto-rows: 150px;
    }

    .item1 {
      grid-area: tl;
    }
    .item2 {
      grid-area: tr;
    }
    .item3 {
      grid-area: bc;
    }
    .item4 {
      grid-area: br;
    }
    .items {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // border-radius: 8px;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .featured-news {
    .news_container {
      grid-auto-rows: 120px;
    }
  }
}

@media screen and (max-width: 414px) {
  .featured-news {
    .news_container {
      grid-auto-rows: 90px;
    }
  }
}
