.player_card {
  border-radius: 8px;
  min-width: 300px;
  min-height: 200px;
  overflow: hidden;
  // height: 350px;
  height: auto;
  position: relative;
  background-image: url("../../../assets/images/playerbackground.svg");
  background-color: #4b7aee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding-top: 20px;
    }
  }
  .details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    padding-bottom: 30px;
    color: #ffffff;
    background: linear-gradient(0deg, #1c213e 0%, rgba(28, 33, 62, 0) 70%);
    // background-image: linear-gradient(
    //   to top,

    //   #181f39,
    //   #181f3900
    // );
    .number {
      font-size: 114px;
    }
    .first {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
      // font-weight: 800;
      text-transform: uppercase;
      margin: 0;
    }
    .last {
      font-size: 56px;
      text-transform: uppercase;
      margin: 0;
    }
    .pos {
      margin-top: 4px;
      font-family: "Cabinet Grotesk";
      // font-size: 20px;
      // font-weight: bold;
    }
  }
}

@media screen and (max-width: 1400px) {
  .player_card {
    min-width: 224px;
    .details {
      padding: 24px;
      .number {
        font-size: 94px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .player_card {
    .details {
      .number {
        font-size: 114px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .player_card {
    min-height: 200px;
    .details {
      padding: 16px;
      .number {
        font-size: 94px;
      }
      .first {
        font-size: 16px;
      }
      .last {
        font-size: 42px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .player_card {
    min-width: 116px;
    min-height: 200px;
    .details {
      .number {
        font-size: 72px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .player_card {
    min-width: 116px;
    min-height: 150px;
    .details {
      padding: 12px;
      .number {
        font-size: 56px;
      }
      .first {
        font-size: 16px;
      }
      .last {
        font-size: 36px;
      }
      .pos {
        margin-top: 4px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
