.gallery_display {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.img {
  .image-viewer {
    position: fixed;
    z-index: 100;
    padding-top: 100px;
    left: 0;
    // cursor: pointer;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.6);
    .close {
      position: absolute;
      top: 0px;
      right: 5%;
      z-index: 200;
      cursor: pointer;
      color: #f1f1f1;
      font-size: 100px;
      font-weight: bold;
      transition: 0.3s;
      & :hover {
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .modal-content {
      margin: 0 auto;
      display: block;
      width: 100% !important;
      height: 80%;

      animation-name: zoom;
      animation-duration: 0.6s;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .modal {
      margin: 0 auto;
      display: block;
      width: 80%;
      height: 80%;
      object-fit: contain;
      animation-name: zoomout;
      animation-duration: 0.6s;
    }
  }
  .gallery_display_img {
    .gallery_img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      transition: 0.3s;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoomout {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

@media screen and (max-width: 768px) {
  .gallery_display {
    .img {
      .gallery_display_img {
        .gallery_img {
          height: 180px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .gallery_display {
    grid-template-columns: repeat(2, 1fr);
    .img {
      .gallery_display_img {
        .gallery_img {
          height: 120px;
        }
      }
    }
  }
}
