.store_landing {
  background-color: #ffffff;
  .top {
    background-color: #efefef;
    .inner-top {
      display: flex;
      // gap: 25px;
      padding: 20px 64px;
      max-width: max-content;
      margin: 0 auto;
      max-width: 1512px;
      justify-content: space-between;
      // INFO CARD STYLE
      .info_card {
        border-radius: 8px;
        overflow: hidden;
        height: 612px;
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        .img {
          flex-grow: 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 36px 32px;
          background-color: v.$accent;
          display: flex;
          color: #ffffff;
          gap: 5%;
          justify-content: space-between;
          align-items: center;
          box-sizing: content-box;
          height: 120px;
          .left {
            h1 {
              font-family: "Gratelos";
              font-size: 36px;
              text-transform: uppercase;

              margin-bottom: 10px;
            }
            p {
              font-family: "Cabinet Grotesk";
              font-weight: bold;
              white-space: wrap;
            }
          }
          .right {
            .link {
              width: fit-content;
              a {
                width: fit-content;
                color: #ffffff;
                font-family: "Gratelos";
                font-size: 24px;
                text-decoration: none;
                letter-spacing: 0;
                padding: 14px 24px;
                text-transform: uppercase;
                border-radius: 26px;
                white-space: nowrap;
                border: 2px solid #ffffff;
              }
            }
          }
        }
      }
    }
  }
  .store {
    padding: 40px 64px;
    max-width: 1512px;
    margin: auto;
    h1 {
      margin-bottom: 30px;
      font-size: 56px;
      color: v.$accent;
    }
    .link {
      margin: 20px auto;
      width: fit-content;
      a {
        display: flex;
        align-items: center;
        width: fit-content;
        background-color: v.$accent;
        color: #ffffff;
        font-size: 26px;
        text-decoration: none;
        letter-spacing: 0;
        padding: 14px 24px;
        padding-left: 32px;
        border-radius: 26px;
        border: 1px solid #202e55;
        span {
          font-size: 20px;
          margin-top: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .store_landing {
    .store {
      padding: 40px 0;
      h1 {
        padding: 0 64px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .store_landing {
    .store {
      padding: 40px 0px;
      h1 {
        padding: 0 24px;
      }
    }
    .top {
      .inner-top {
        padding: 20px 24px;
        flex-direction: column;
        max-width: unset;
        padding-bottom: 0px;
        .info_card {
          // height: unset;
          width: unset;
          margin-bottom: 24px;
          .img {
            height: 192px;
          }
          .details {
            flex-direction: column;
            position: static;
            padding: 24px 16px;
            height: unset;
            .left {
              h1,
              p {
                text-align: center;
              }

              h1 {
                font-size: 30px;
                margin-bottom: 16px;
              }

              p {
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 16px;
              }
            }
            .right {
              .link {
                margin-top: 30px;
                margin-bottom: 24px;
                a {
                  font-size: 16px;
                  padding: 12px 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .store_landing {
    .top {
      .inner-top {
        flex-direction: column;
        .info_card {
          height: 500px;
        }
      }
    }
    // .store {
    //   padding: 40px 24px;
    // }
  }
}

@media screen and (max-width: 414px) {
  .store_landing {
    .top {
      .inner-top {
        padding: 20px 16px;
        .info_card {
          height: 400px;
        }
      }
    }
    .store {
      padding: 40px 0px;
      h1 {
        font-size: 36px;
        padding: 0 16px;
      }
      .link {
        a {
          padding: 6px 14px;
          padding-left: 26px;
          font-size: 16px;
        }
      }
    }
  }
}
