.search_page {
  margin-top: v.$header_h;
  background-color: #ffffff;
  padding-bottom: 20px;
  .search-input-container {
    background-color: v.$accent;
    .search-input {
      padding: 0 64px;
      max-width: 1512px;
      margin: 0 auto;
      padding-top: 20px;
      margin-top: -20px;
      height: 80px;
      display: flex;
      column-gap: 30px;
      align-items: center;

      .search_close {
        cursor: pointer;
      }

      .search_button {
        cursor: pointer;
        border: 2px solid #ffffff;
        border-radius: 50px;
        //   height: 100%;
        color: #ffffff;
        justify-content: center;
        align-items: center;
        padding: 12px 24px;
        display: flex;
        p {
          font-size: 30px;
        }
      }
      .search_input {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.1);
        height: 70%;
        align-items: center;
        display: flex;
        border-radius: 8px;
        .input {
          display: block;
          outline: none;
          border: none;
          color: #ffffff;
          padding: 12px;
          font-size: 28px;
          box-sizing: border-box;
          border: none;
          width: 98%;
          height: 100%;
          background-color: transparent;
          &::placeholder {
            color: rgba($color: #ffffff, $alpha: 0.3);
          }
        }
        .close {
          cursor: pointer;
          margin-right: 8px;
          // width: 20px;
        }
      }
    }
  }

  .search_filter_container {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    position: sticky;
    top: 80px;
    .search_filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1512px;
      padding: 0 64px;
      margin: 0 auto;
      .title {
        // text-align: center;
        max-width: 1512px;
        //   padding: 0 64px;
        //   margin: 40px auto;
        text-transform: uppercase;
        font-size: 56px;
        color: v.$accent;
      }
      .tabs {
        display: flex;
        // gap: 40px;
        overflow-x: auto;
        max-width: 1512px;
        margin: 20px auto;
        //   padding: 0 64px;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .tab {
          text-transform: capitalize;
          color: v.$accent;
          padding: 10px;
          white-space: nowrap;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          transition: all ease-in-out 0.2s;
          font-size: 16px;
          font-family: "Cabinet Grotesk";
        }
        .active {
          border-bottom: 2px solid red;
        }
      }
      .tabs > * + * {
        margin-left: 40px;
      }
    }
  }

  .more {
    width: max-content;
    margin: 20px auto;
    button {
      border: none;
      cursor: pointer;
      padding: 15px 30px;
      font-size: 30px;
      background-color: v.$accent;
      color: #ffffff;
      border-radius: 5px;
    }
  }
  .search_inner {
    max-width: 1512px;
    margin: 64px auto;
    padding: 0px 64px;
    // box-sizing: border-box;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 50px;
    .card_container {
      .search_card {
        cursor: pointer;
        // box-shadow: 0px 0px 5px black;
        margin: 10px 0;
        padding: 10px 0;
        // border-radius: 8px;
        height: 130px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        .match_details {
          display: flex;
          //   grid-template-columns: 0.2fr 1fr;
          //   column-gap: 20px;
          align-items: center;
          flex-direction: row-reverse;
          .opp_logo {
            width: 147px;
            height: 90px;
            border-radius: 8px;
            overflow: hidden;

            .part {
              background-color: v.$accent;
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            img {
              width: 100%;
              //   height: 100%;
              object-fit: contain;
            }
          }
          .logoo {
            width: 147px;
            height: 90px;
            background: #f2f2f2;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 80%;
            }
          }
          .s_details {
            flex-grow: 2;
            width: 100%;
            .sta {
              font-family: "Cabinet Grotesk";
            }
            .match_names {
              //   min-height: 40px;
              display: flex;
              margin-bottom: 8px;
              .sta {
                font-family: "Cabinet Grotesk";
              }
              h1 {
                // text-align: center;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                white-space: normal;
              }
              p {
                text-align: center;
              }
            }
            .match_names > * + * {
              margin-left: 8px;
            }
            .news_name {
              //   min-height: 90px;
              margin-top: 8px;
              .sta {
                font-family: "Cabinet Grotesk";
              }
              h1 {
                // text-align: center;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                font-size: 32px;
                margin: 8px 0;
              }
              h3 {
                text-align: center;
              }
              .intro {
                // text-align: center;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-clamp: 3;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                h1,
                h2,
                h3,
                h4,
                h5,
                p {
                  font-size: 16px;
                  font-family: "Cabinet Grotesk";
                  // padding-top: 16px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 100%;
                  white-space: nowrap;
                  b,
                  i,
                  u {
                    font-size: 16px;
                    font-family: "Cabinet Grotesk";
                    padding-top: 16px;
                    // font-weight: bold;
                  }
                }
              }
            }
          }

          .s_stadium {
            align-self: flex-end;
            // margin-top: 5px;
            display: flex;
            gap: 16px;
            align-items: center;
            // justify-content: space-between;
            div {
              display: flex;
              align-items: center;
              gap: 8px;
              img {
                width: 16px;
              }
              .label {
                font-family: "Cabinet Grotesk";
                font-size: 12px;
              }
            }
            div {
              .sta {
                font-family: "Cabinet Grotesk";
                font-size: 16px;
              }
            }
          }
          .s_matches {
            display: flex;
            // flex-direction: column-reverse;
            align-items: flex-start;
          }
        }
        .match_details > * + * {
          margin-left: 0px;
        }
      }
      .shop {
        cursor: default;
        .buy_now {
          font-size: 20px;
          border: 1px solid v.$accent;
          padding: 5px 15px;
          border-radius: 10px;
          color: v.$accent;
          transition: all ease-in-out 0.3s;
          &:hover {
            background-color: v.$accent;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .search_page {
    .search-input-container {
      .search-input {
        padding: 0 24px;
        padding-top: 20px;
      }
    }
    .search_filter_container {
      .search_filter {
        padding: 0 24px;
        flex-direction: column;
        align-items: flex-start;
        .title {
          margin-top: 20px;
        }
        .searchtabs {
          width: 100%;
        }
      }
    }

    .search_inner {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .search_page {
    .search-input-container {
      .search-input {
        margin-top: -50px;
      }
    }

    .search_filter_container {
      top: 48px;
      .search_filter {
        .title {
          font-size: 40px;
        }
      }
    }
    .search_inner {
      .card_container {
        .search_card {
          .match_details {
            .opp_logo,
            .logoo {
              display: none;
            }
            .s_details {
              .match_names {
                flex-direction: column;
                .vs {
                  display: none;
                }
              }
              .match_names > * + * {
                margin-left: unset;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .search_page {
    .search-input-container {
      .search-input {
        padding-left: 16px;
        padding-right: 16px;
        .search_button {
          display: none;
        }
      }
    }
    .search_filter_container {
      .search_filter {
        padding: 0 16px;
        .tabs > * + * {
          margin-left: 16px;
        }
      }
    }
    .search_inner {
      padding: 0 16px;
      .card_container {
        .search_card {
          height: max-content;
          .match_details {
            .s_matches,
            .s_stadium {
              display: block;
            }
          }
        }
      }
    }
  }
}
