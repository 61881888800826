.match_landing {
  background-color: #ffffff;
  // padding: 30px 3vw;
  // margin-top: -10vh;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    max-width: 1512px;
    margin: auto;
    padding: 0 64px;
    .left {
      display: flex;
      align-items: center;
    }
    .right {
      margin-right: 130px;
      z-index: 2;
      a {
        text-decoration: underline;
        font-style: italic;
        color: #202e55;
        cursor: pointer;
      }
    }

    .section-title {
      color: #202e55;
      font-size: 56px;
      font-family: "Gratelos";
      // text-transform: uppercase;
    }
    a {
      //   margin-left: 28px;
      font-size: 20px;
      font-family: "Cabinet Grotesk";
      // font-weight: bold;
      // letter-spacing: -0.001em;
      z-index: 2;
      color: v.$accent;
      text-decoration: none;
    }
    .all_matches {
      margin-left: 24px;
    }
  }
  .match_title {
    // margin: 40px 0;
    // margin-bottom: 0;
    padding-top: 40px;
  }
  .bottom {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .match_landing {
    .title {
      padding: 0 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .match_landing {
    .title {
      .right {
        display: none;
      }
    }
    .bottom {
      display: flex;
      max-width: 1512px;
      margin: 0 auto;
      justify-content: center;
      a {
        font-size: 20px;
        font-family: "Cabinet Grotesk";
        color: #202e55;
        font-style: italic;
        margin-bottom: 32px;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 490px) {
  .match_landing {
    .title {
      padding: 0 16px;
      .all_matches {
        display: none;
      }
      .section-title {
        font-size: 36px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .match_landing {
    .bottom {
      a {
        font-size: 16px;
      }
    }
  }
}
