.tickets-card-container {
  padding: 0 64px;
  max-width: 1512px;
  margin: auto;
  margin-top: 24px;
  .tickets-format {
    background-color: #ffffff;
    border-radius: 8px;
  }
  .tickets-card {
    background-color: #ffffff;
    display: flex;
    max-width: 1512px;
    margin: auto;
    align-items: center;
    padding: 0 24px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    .tickets-deets1 {
      display: flex;
      align-items: center;
      .league-icon {
        margin-right: 2vw;
        img {
          width: 36px;
        }
      }
      .matchcard-time {
        font-size: 36px;
      }
      .matchcard-date {
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        font-weight: 700;
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }
    .tickets-deets2 {
      display: flex;
      flex-grow: 2;
      align-items: center;
      padding-left: 7vw;
      .competitors-logo {
        display: flex;
        gap: 24px;
      }
      img {
        width: 72px;
        height: 72px;
      }
      .opponent {
        flex-grow: 1;
        display: flex;

        flex-direction: column;
        // gap: 8px;
        margin-left: 32px;
        .opp {
          text-transform: uppercase;
        }
        div {
          display: flex;
          //   gap: 10px;
          font-family: "Gratelos";
          font-size: 36px;
        }
        .matchcard-venue {
          div {
            font-family: "Cabinet Grotesk";
            font-size: 20px;
            font-weight: 700;
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
      }
    }
    .ticket {
      margin: 46px 0;
      .link {
        a {
          background-color: #202e55;
          color: #ffffff;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          border: 2px solid #202e55;
        }

        :hover {
          background-color: #ffffff;
          color: #202e55;
        }
      }
    }
  }
  .note {
    padding: 16px;
    p {
      font-family: "Cabinet Grotesk";
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .tickets-card-container {
    .tickets-card {
      flex-direction: column;
      align-items: unset;
      gap: unset;
      padding: 24px;
      .tickets-deets1 {
        margin-bottom: 20px;
        .league-icon {
          margin-right: 1vw;
        }
        .matchcard-date {
          margin-left: 12px;
        }
        .date {
          display: flex;
          align-items: center;
          // gap: 12px;
        }
      }
      .tickets-deets2 {
        padding-left: unset;
        flex-direction: row-reverse;
        padding-right: 16px;
        margin-bottom: 20px;
        .competitors-logo {
          margin-bottom: 16px;
          gap: unset;
          .host {
            margin-right: 24px;
          }
        }
        .opponent {
          margin-left: unset;
          // gap: 8px;
          .matchcard-venue {
            margin-top: 8px;
          }
        }
      }
      .ticket {
        margin: 16px 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .tickets-card-container {
    padding: 0 24px;
  }
}

@media screen and (max-width: 760px) {
  .tickets-card-container {
    .tickets-card {
      .tickets-deets1 {
        justify-content: space-between;
        flex-direction: row-reverse;
        .matchcard-time {
          font-size: 28px;
        }
        .matchcard-date {
          font-size: 16px;
          font-weight: 400;
          margin-left: 12px;
        }
      }
      .tickets-deets2 {
        flex-direction: column;
        align-items: unset;
        // gap: 16px;
        .opponent {
          div {
            font-size: 28px;
          }
          .matchcard-venue {
            div {
              font-size: 16px;
              font-weight: 400;
            }
          }
        }
        img {
          // width: max-content;
          width: 52px;
          height: 52px;
          .host {
            width: max-content;
          }
        }
      }
      .ticket {
        .link {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
    .note {
      p {
        font-size: 12px;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tickets-card-container {
    padding: 0 16px;
    .tickets-card {
      padding: 16px;
    }
  }
}
