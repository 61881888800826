.video_player {
  position: relative;
  padding-top: 56%;
  background: rgba($color: #000000, $alpha: 0.2);
  background-image: url(../../assets/images/logo2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20%;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .react-player__preview {
    border-radius: 4px;
    position: relative;
    img {
      transform: all ease-in-out 0.3s;
      width: 32px;
    }
  }

  .react-player__preview:hover {
    img {
      // z-index: 3;
      width: 36px;
    }
  }
}
