.social {
  background-color: #ffffff;
}

.social_landing {
  background-color: #ffffff;
  padding: 40px 64px;
  display: flex;
  gap: 10vw;
  max-width: 1512px;
  margin: auto;
  @media screen and (max-width: 1150px) {
    display: block;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 24px;
  }
  @media screen and (max-width: 414px) {
    padding: 40px 16px;
  }
  h1 {
    font-size: 56px;
    color: #202e55;
    margin-bottom: 30px;
  }
  .social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: unset;
    }
    iframe {
      width: calc(100% / 3);
      // height: 600px !important;
      border-radius: 10px !important;
      overflow: scroll;
      @media screen and (max-width: 480px) {
        height: 500px !important;
      }
    }
    .iframe {
      height: 100% !important;
      @media screen and (max-width: 1150px) {
        width: 100% !important;
      }
      @media screen and (max-width: 900px) {
        width: 400px !important;
        margin: auto;
      }
      @media screen and (max-width: 580px) {
        width: 100% !important;
        margin: 0 0;
      }
    }
  }

  .subtext {
    // display: flex;
    justify-content: space-between;
    // box-shadow: 0px 0px 5px grey;
    margin: 24px 0;
    // padding: 20px 30px;
    align-items: center;
    border-radius: 4px;
    p {
      //   width: 50%;
      font-size: 28px;
      font-family: "Cabinet Grotesk";
      color: #000000b2;
      span {
        color: v.$accent;
        font-family: "Cabinet Grotesk";
        font-weight: bold;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      //   gap: 48px;
      margin-top: 24px;
      a {
        // margin-right: 48px;
        img {
          width: 70%;
        }
      }
    }
  }
  .mobile-social {
    display: none;
  }
}

@media screen and (max-width: 1150px) {
  .social_landing {
    .subtext {
      display: none;
    }
    .mobile-social {
      display: block;
    }
  }
}

@media screen and (max-width: 760px) {
  .social_landing {
    .subtext {
      margin: 16px 0;
      p {
        font-size: 20px;
      }
      .icons {
        margin-top: 16px;
        // gap: 16px;
        a {
          img {
            width: 70%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .social_landing {
    h1 {
      font-size: 36px;
    }
    .subtext {
      margin: 16px 0;
      p {
        font-size: 16px;
      }
    }
  }
}
