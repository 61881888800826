.kolo{
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.membership-page {
  .membership-title {
    max-width: 1512px;
    margin: auto;
    margin-top: 146px;
    padding: 0 64px;
    h2 {
      font-size: 56px;
      color: #ffffff;
      margin-bottom: 32px;
    }
  }
  .content {
    background-color: #f2f2f2;
    color: v.$accent;
    // padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .general-copy {
      background: #ffffff;
      // width: 100%;
      overflow: hidden;
      display: flex;
      padding: 0px 64px;
      align-items: center;
      .copy {
        margin: auto;
        display: flex;
        overflow: hidden;
        align-items: center;
        position: relative;
        // width: 100%;
        padding: 24px 0px;
        h2 {
          font-family: "Gratelos";
          font-size: 113px;
          text-transform: uppercase;
          margin-top: 24px;
          transform: rotate(-10deg);
          position: absolute;
          right: -10vh;
          color: #f2f2f2;
          // opacity: 0.5;
        }
        p {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          width: 50%;
          z-index: 1;
          // color: #ffffff;
        }
      }
    }
    .packages {
      //   width: 100%;
      padding: 0 64px;
      padding-bottom: 88px;
      max-width: 1512px;
      margin-top: 40px;

      .currency-tab {
        // background: #ffffff;
        // width: max-content;
        margin-bottom: 24px;
        display: none;
        justify-content: right;
        @media screen and (max-width: 1024px) {
          justify-content: center;
        }
        ul {
          display: flex;
          list-style-type: none;
          justify-content: flex-end;
          background: #ffffff;
          border-radius: 8px;
          overflow: hidden;
          padding: 8px;
          @media screen and (max-width: 414px) {
            padding: 4px;
          }
          li {
            // border: 1px solid #f2f2f2;
            p {
              text-align: center;
              font-size: 24px;
              color: v.$accent;
              background: rgba($color: v.$accent, $alpha: 0.3);
              border-radius: 8px;
              padding: 8px 16px;
              @media screen and (max-width: 414px) {
                font-size: 20px;
                padding: 8px 16px;
              }
            }
            .unselct {
              background: unset;
              color: rgba($color: v.$accent, $alpha: 0.6);
              cursor: pointer;
            }
          }
        }
      }


      .packages-item {
        // width: 100%;
        display: flex;
        justify-content: center;
        gap: 48px;
        margin: 0 auto;
        // align-items: center;
        .feature-container {
          //   border: 1px solid rgba($color: #000000, $alpha: 0.5);
          border-radius: 8px;
          overflow: hidden;
          background: #ffffff;
          width: 33%;

          .heading {
            background: #ffffff;
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            .cardimg {
              padding: 24px;
              img {
                width: 100%;
                margin: 0 auto;
                border-radius: 15px;
              }
            }

            h3 {
              background: white;
              text-align: center;
              padding: 24px;
              // padding-bottom: 0px;
              color: #202e55;
              font-size: 32px;
            }
            h1 {
              text-align: center;
              color: #4b7aee;
              font-size: 54px;
              background: #ffffff;
              margin: 8px 0 8px 0;
            }
            p {
              font-family: "Cabinet Grotesk";
              text-align: center;
              background: #ffffff;
              font-size: 20px;
            }
          }
          .features {
            padding: 24px;
            padding-bottom: 8px;
            background: #ffffff;
            hr {
              color: #202e55;
              opacity: 0.2;
              margin-bottom: 24px;
            }
            .feature-item {
              display: flex;
              margin-bottom: 24px;
              img {
                margin-right: 16px;
              }
              p {
                font-family: "Cabinet Grotesk";
                font-size: 20px;
              }
              .footnote {
                font-size: 16px;
                font-style: italic;
                // text-align: center;
                margin-top: 8px;
              }
            }
          }
        }
      }
      .register {
        width: fit-content;
        margin: auto;
        margin-top: 64px;
        margin-bottom: 24px;
        position: sticky;
        bottom: 5vh;
        a {
          background-color: #202e55;
          color: #ffffff;
          font-size: 24px;
          font-family: "Gratelos";
          text-decoration: none;
          padding: 14px 24px;
          border-radius: 26px;
          border: none;
        }
      }
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark translucent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modern-modal {
  background: #ffffff;
  padding: 20px 30px;
  max-width: 400px;
  width: 90%;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

.celebration-icon {
  margin-bottom: 15px;
}

.close-button {
  background-color: #202e54; /* Soft blue for success */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 15px;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #202e70; /* Darker blue on hover */
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  gap: 10px;
}

.checkbox-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}


@media screen and (max-width: 1024px) {
  .membership-page {
    .membership-title {
      padding: 0 24px;
      @media screen and (max-width: 760px) {
        h2 {
          font-size: 40px;
        }
      }
    }
    .content {
      .general-copy {
        @media screen and (max-width: 600px) {
          // padding: 0 24px;
          // width: 100vw;
        }
        .copy {
          // display: flex;
          p {
            width: 90%;
            margin: 0 auto;
            @media screen and (max-width: 600px) {
              width: 80%;
            }
          }
        }
      }
      .packages {
        @media screen and (max-width: 760px) {
          padding: 0 24px;
          padding-bottom: 40px;
        }
        .packages-item {
          flex-direction: column;
          align-items: center;
          .feature-container {
            width: 80%;
            @media screen and (max-width: 760px) {
              width: 70%;
            }
            @media screen and (max-width: 600px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .membership-page {
    .content {
      .packages {
        padding: 0 16px;
        padding-bottom: 40px;

        .register {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
  }
}
