.videodisplay-top {
  margin: 40px 0;
  display: flex;
  padding: 9px 24px;
  .logo {
    margin: 0 auto;
    cursor: pointer;
    img {
      width: 36px; //for old logo
    }
  }
  .close {
    cursor: pointer;
    position: absolute;
    right: 24px;
  }
}

.share_icon {
  display: flex;
  div {
    margin: 0 5px;
  }
}
.video_display_bottom {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  padding: 0 24px;
  .video-content {
    display: flex;
    // padding: 10px;
    margin-right: 24px;
    flex-direction: column;
    overflow: auto;
    .mobile-video {
      display: none;
      padding-right: 10px;
      .active-video-description {
        // border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.3);
        .video-name {
          color: #ffffff;
          font-size: 40px;
          text-transform: uppercase;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
        .video-name-2 {
          color: #ffffff;
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          font-weight: bold;
          text-transform: capitalize;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-clamp: 2;
          -webkit-line-clamp: 2;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .avd-bottom {
          padding: 16px 0;

          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            color: rgba($color: #ffffff, $alpha: 0.5);
            font-family: "Cabinet Grotesk";
            font-size: 24px;
          }
        }
      }
    }
    .under {
      margin-top: 24px;
      margin-bottom: 24px;
      // margin-bottom: 40px;
      .episode {
        // height: 25vh;
        overflow: auto;
        padding: 10px;
        border-radius: 8px;
        // max-width: 50%;
        background-color: rgba($color: #000000, $alpha: 0.2);
        transition: all ease-in-out 0.4s;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .video-list {
          display: flex;
        }
        a {
          color: inherit;
          text-decoration: none;
        }
        .active {
          background-color: rgba($color: #ffffff, $alpha: 0.1);
          border-radius: 8px;
        }
        .video-item {
          // display: flex;
          gap: 16px;
          align-items: center;
          width: 270px;
          // margin-bottom: 24px;
          padding: 16px;

          //   height: 124px;
          .thumbnail {
            width: 100%;
            height: 140px;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .play {
              position: absolute;
              z-index: 1;
              top: 35%;
              left: 40%;
              width: 32px;
              transition: all ease-in-out 0.2s;
            }

            .tv_image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .thumbnail-description {
            padding-top: 16px;
            width: 100%;
            max-height: 100%;
            // border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.3);

            .video-name-2 {
              color: #ffffff;
              font-family: "Cabinet Grotesk";
              font-size: 20px;
              font-weight: bold;
            }
            .time {
              color: rgba($color: #ffffff, $alpha: 0.5);
              font-family: "Cabinet Grotesk";
              font-size: 16px;
            }
          }
        }
      }
      .episode_hide {
        height: 0;
      }
    }
    .active-video {
      width: 100%;
      // height: 100%;
      .no_show {
        width: 100%;
        height: auto;
        background-color: rgba($color: #000000, $alpha: 0.2);
        padding: 24%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10%;
        border-radius: 4px;
        h1 {
          font-size: 24px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 24px;
        }
        button {
          border: none;
          border-radius: 8px;
          padding: 14px 24px;
          background-color: #ffffff;
          color: v.$accent;
          font-size: 30px;
          cursor: pointer;
          width: max-content;
          margin: 0 auto;
        }
      }
    }
  }
  .more-video {
    padding-right: 10px;
    .active-video-description {
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.3);
      .video-name {
        color: #ffffff;
        font-size: 50px;
        text-transform: uppercase;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      .video-name-2 {
        color: #ffffff;
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        font-weight: bold;
        text-transform: capitalize;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }

      .avd-bottom {
        padding: 16px 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
        .time {
          color: rgba($color: #ffffff, $alpha: 0.5);
          font-family: "Cabinet Grotesk";
          font-size: 24px;
        }
      }
    }
    .more {
      max-height: 70vh;
      overflow: auto;
      padding-top: 20px;
      .video-list {
        .video-card-container {
          height: 140px;
        }
      }
    }

    .mv-title {
      color: #ffffff;
      font-size: 32px;
      padding: 30px 0;
      text-transform: uppercase;
    }

    .video-item {
      display: flex;
      gap: 16px;
      // align-items: center;
      // width: 90%;
      // margin-bottom: 24px;
      padding: 5px;
      cursor: pointer;

      //   height: 124px;
    }
    .active {
      background-color: rgba($color: #ffffff, $alpha: 0.1);
      border-radius: 8px;
      padding: 16px 5px;
      padding-left: 16px;
      margin-top: -16px;
      .thumbnail-description {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
    .thumbnail {
      min-width: 150px;
      max-width: 200px;
      height: 80px;
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      .lock {
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-top: 5px;
        position: absolute;
        top: 44px;
        left: 4px;
        font-size: 24px;
      }

      .play {
        position: absolute;
        z-index: 1;
        top: 35%;
        left: 40%;
        width: 32px;
        transition: all ease-in-out 0.2s;
      }
      .tv_image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .thumbnail-description {
      padding-bottom: 20px;
      width: 100%;
      max-height: 100%;
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);
      .video-meta {
        height: 80px;
      }
      .video-name {
        color: #ffffff;
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        font-weight: bold;
        box-orient: vertical;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      .time {
        color: rgba($color: #ffffff, $alpha: 0.5);
        font-family: "Cabinet Grotesk";
        font-size: 16px;
      }
      // .lock {
      //   color: #ffffff;
      //   display: flex;
      //   align-items: center;
      //   margin-top: 5px;
      // }
    }
  }
}
.btn_more {
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  cursor: pointer;
}

.more::-webkit-scrollbar {
  width: 5px;
}
.more::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgba(255, 255, 255, 0.568);
  border-radius: 10px;
}

.more::-webkit-scrollbar-thumb {
  background-color: v.$accent;
  // outline: 1px solid #1976d2;
}
// .episode::-webkit-scrollbar {
//   width: 5px;
// }
// .episode::-webkit-scrollbar-track {
//   box-shadow: inset 10px 10px 10px #fff;
//   border-radius: 10px;
// }

// .episode::-webkit-scrollbar-thumb {
//   background-color: grey;
//   // outline: 1px solid #1976d2;
// }

@media screen and (max-width: 1024px) {
  .video_display_bottom {
    display: unset;
    .video-content {
      margin-left: 24px;
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.3);
      .mobile-video {
        display: unset;
        .active-video-description {
          .video-name {
            font-size: 50px;
          }
        }
      }
    }
    .more-video {
      padding: 0 24px;
      .web-video {
        display: none;
      }

      .more {
        overflow: unset;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .video_display_bottom {
    .video-content {
      .mobile-video {
        .active-video-description {
          .video-name {
            font-size: 40px;
          }
        }
      }
    }
    .more-video {
      .active-video-description {
        .video-name {
          font-size: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .video_display_bottom {
    padding: 0 16px;
    .video-content {
      margin-left: 16px;
      margin-right: 16px;
      .active-video {
        .no_show {
          button {
            font-size: 16px;
            padding: 16px 20px;
          }
        }
      }
      .under {
        .episode {
          .video-item {
            width: 130px;
            padding: 8px;
            .thumbnail {
              height: 80px;
            }
            .thumbnail-description {
              .video-name-2 {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .more-video {
      padding: 0 16px;
      .thumbnail-description {
        .video-name {
          font-size: 16px;
        }
      }
    }
  }
}
