.player_news {
  background-color: #f2f2f2;
  border: none;
  .news {
    background-color: #f2f2f2;
    padding: 40px 64px;
    max-width: 1512px;
    margin: auto;
    .section_title {
      margin-bottom: 30px;
    }
    .news_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
      .no-news {
        font-family: "Cabinet Grotesk";
        font-size: 24px;
        font-style: italic;
        white-space: nowrap;
      }
      .items {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }
    .link {
      margin: 5vh auto 30px auto;
      width: max-content;
      a {
        background-color: v.$accent;
        color: #ffffff;
        white-space: nowrap;
        font-size: 24px;
        font-family: "Gratelos";
        text-decoration: none;
        padding: 14px 24px;
        border-radius: 26px;
      }
    }
  }
  .news_card {
    width: 100%;
    min-width: 140px;
    height: 300px;
    object-fit: cover;
    position: relative;
  }
}

@media screen and (max-width: 760px) {
  .player_news {
    .news {
      padding: 40px 24px;
      .news_container {
        grid-template-columns: 1fr;
        .no-news {
          text-align: center;
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .player_news {
    .news {
      padding: 40px 16px;
      .news_container {
        .no-news {
          font-size: 16px;
        }
      }
    }
    .news_card {
      height: 240px;
    }
  }
}
