.job_body {
	.intro {
		font-weight: bold;
	}
	.job_form {
		margin: 50px 0;
		font-size: 20px;
		font-family: "Cabinet Grotesk";
		label {
			display: block;
			margin: 15px 0;
			font-weight: bold;
			span {
				color: red;
			}
		}
		.lbl {
			display: inline;
		}
		.form_control {
			border: none;
			width: 100%;
			padding: 10px 0;
			outline: none;
			font-size: 18px;
			border-bottom: 1px solid #808080;
		}
		.radio {
			margin: 15px 0;
		}
		// UPLOAD BUTTON
		.upload-pic {
			width: fit-content;
			height: 25px;
			.upload-btn-wrapper {
				overflow: hidden;
				display: inline;
				position: relative;
			}
			.btn {
				color: #4b7aee;
				border-bottom: 2px solid #4b7aee;
			}
			#progress {
				margin-left: 10px;
			}

			.upload-btn-wrapper input[type="file"] {
				position: absolute;
				left: 0;
				top: 0;
				width: max-content;
				opacity: 0;
			}
			#file_name {
				margin: 5px 0;
				font-size: 18px;
			}
		}
		.buttons {
			margin-top: 30px;
			display: flex;
			gap: 20px;
			.btn {
				padding: 10px 15px;
				border-radius: 50px;
				border: none;
				cursor: pointer;
				font-size: 20px;
				font-family: "Gratelos";
				letter-spacing: 1px;
			}
			.reset {
				margin-top: 0;
				border: 2px solid v.$accent;
				color: v.$accent;
				background-color: #fff;
			}
			.btn_submit {
				width: 180px;
				height: 60px;
				background-color: v.$accent;
				color: #fff;
			}
		}
	}
}
