.achieve {
  background-color: #000;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  gap: 20px;
  .achieve_card {
    background-color: #fff;
    width: 332px;
    height: 110px;
    border-radius: 8px;
    padding: 8px;
    gap: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .icon {
      font-size: 70px;
      align-self: center;
      opacity: 0.2;
      position: absolute;
      width: 92px;
      height: auto;
      svg {
        width: 100%;
      }
    }
    .ddata {
      position: relative;
      display: flex;
      // margin-left: 32px;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 16px;
      .count {
        color: #4b7aee;
        font-size: 78px;
        margin-right: 16px;
      }
      .name {
        font-family: "Cabinet Grotesk";
        // font-weight: bold;
        font-size: 20px;
        white-space: wrap;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .achieve {
    .achieve_card {
      flex-direction: column;
      height: max-content;
      min-width: 136px;
      gap: unset;
      padding-top: 24px;
      .ddata {
        flex-direction: column;
        padding: 0 16px;

        .count {
          // font-size: 60px;
          // margin-top: -30px;
          margin-right: unset;
        }
        .name {
          text-align: center;
          height: 70px;
        }
      }
      .icon {
        position: static;
      }
    }
  }
}

@media screen and (max-width: 930px) {
  .achieve {
    .achieve_card {
    }
  }
}

@media screen and (max-width: 760px) {
  .achieve {
    gap: 8px;
    .achieve_card {
      min-width: 36px;
      .ddata {
        .count {
          font-size: 60px;
        }
        .name {
          font-size: 16px;
        }
      }
      .icon {
        width: 50%;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .achieve {
    gap: 8px;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .achieve_card {
      width: unset;
      flex-direction: unset;
      min-width: 100px;
      align-items: center;
      // justify-content: center;
      padding-top: 8px;
      .ddata {
        display: unset;
        padding: 0;
        .count {
          font-size: 40px;
          height: unset;
          margin-top: unset;
          z-index: 1;
          text-align: center;
        }
        .name {
          font-size: 12px;
          height: unset;
          z-index: 1;
          text-align: unset;
          text-align: center;
        }
      }

      .icon {
        height: 52px;
        width: unset;
        text-align: center;
        font-size: 50px;
        position: absolute;
        // margin-right: 130px;
      }
    }
  }
}
