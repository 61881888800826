.side {
  width: 100%;
  min-width: 140px;
  height: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-color: #ffffff;
  .img {
    transition: all ease-in-out 0.4s;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .yes {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
      360deg,
      #000000 -15.11%,
      rgba(0, 0, 0, 0.494575) 31.74%,
      rgba(0, 0, 0, 0) 69.6%
    );
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 8px;

    .news-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      box-sizing: border-box;
    }

    .white {
      background-color: #ffffff;
      color: v.$primary;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .topp {
        .duration {
          font-size: 16px;
          font-family: "Cabinet Grotesk";
          // font-weight: 700;
          color: v.$primary;
        }
      }
    }

    .topp {
      display: flex;
      align-items: center;

      // gap: 4px;
      .icon {
        font-size: 20px;
        margin-right: 4px;
      }
      .category {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        margin-right: 4px;
        // font-weight: 700;
      }
      .duration {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        // font-weight: 700;
        color: rgba(225, 225, 225, 0.6);
        margin-right: 4px;
      }
    }

    h1 {
      font-family: "Gratelos";
      font-size: 36px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      line-height: 36px;
    }
  }
}
.news_card {
  width: 100%;
  min-width: 140px;
  height: 100%;
  object-fit: cover;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  .img {
    transition: all ease-in-out 0.4s;
  }

  .card {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(
      360deg,
      #000000 -15.11%,
      rgba(0, 0, 0, 0.494575) 31.74%,
      rgba(0, 0, 0, 0) 69.6%
    );
    color: #ffffff;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0px;

    .news-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      box-sizing: border-box;
    }

    .white {
      background-color: #ffffff;
      color: v.$primary;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .topp {
        .duration {
          font-size: 16px;
          font-family: "Cabinet Grotesk";
          // font-weight: 700;
          color: v.$primary;
        }
      }
    }

    .topp {
      display: flex;
      align-items: center;

      // gap: 4px;
      .icon {
        font-size: 20px;
        margin-right: 4px;
      }
      .category {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        margin-right: 4px;
        // font-weight: 700;
      }
      .duration {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        // font-weight: 700;
        color: rgba(225, 225, 225, 0.6);
        margin-right: 4px;
      }
    }

    h1 {
      font-family: "Gratelos";
      font-size: 36px;
      margin-bottom: 0px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      line-height: 36px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .news_card {
    min-width: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .side {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    gap: 20px;
    position: relative;
    @media screen and (max-width: 550px) {
      grid-template-columns: 0.5fr 1fr;
      gap: 16px;
    }

    a {
      display: block;
      .yes {
        background-image: none !important;
        color: #ffffff;
        box-sizing: border-box;
        border-radius: 8px;
        // position: relative;
        display: flex;
        align-items: center;
        width: 62%;
        .news-title {
          padding: 16px;
          box-sizing: border-box;
          position: unset;
          @media screen and (max-width: 550px) {
            padding: 0px;
            padding-right: 16px;
          }
        }

        .white {
          background-color: #ffffff;
          color: v.$primary;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          .topp {
            .duration {
              font-size: 16px;
              font-family: "Cabinet Grotesk";
              // font-weight: 700;
              color: v.$primary;
            }
          }
        }

        .topp {
          display: flex;
          align-items: center;

          // gap: 4px;
          .icon {
            font-size: 20px;
            margin-right: 4px;
          }
          .category {
            font-size: 16px;
            font-family: "Cabinet Grotesk";
            margin-right: 4px;
            // font-weight: 700;
            @media screen and (max-width: 550px) {
              font-size: 12px;
            }
          }
          .duration {
            font-size: 16px;
            font-family: "Cabinet Grotesk";
            // font-weight: 700;
            color: rgba(225, 225, 225, 0.6);
            margin-right: 4px;
            @media screen and (max-width: 550px) {
              font-size: 12px;
            }
          }
        }

        h1 {
          font-family: "Gratelos";
          font-size: 36px;
          margin-bottom: 0px;
          width: 100%;
          box-orient: vertical;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-clamp: 1;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          line-height: 36px;
        }
      }
    }
  }
}

// @media screen and (max-width: 1024px) {
//   .news_card {
//     .card {
//     }
//   }
// }

@media screen and (max-width: 550px) {
  .side {
    a {
      .yes {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
  .news_card {
    .card {
      h1 {
        font-size: 30px;
        line-height: unset;
      }
      .topp {
        .icon {
          font-size: 16px;
        }
        .category,
        .duration {
          font-size: 12px;
        }
      }
      .white {
        .topp {
          .duration {
            font-size: 12px;
            color: rgba($color: #000000, $alpha: 0.4);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .side {
    a {
      .yes {
        h1 {
          font-size: 26px;
        }
      }
    }
  }
  .news_card {
    .card {
      h1 {
        font-size: 26px;
      }
    }
  }
}
