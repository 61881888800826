.fixture-card-container {
  padding: 0 64px;
  max-width: 1512px;
  margin: auto;
  margin-top: 24px;
  .fixture-card {
    background-color: #ffffff;
    display: flex;
    max-width: 1512px;
    margin: auto;
    align-items: center;
    padding: 0 24px;
    border-radius: 8px;
    .fixture-card-top-small {
      display: none;
    }
    .deets-and-cta {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .fixture-deets {
        display: flex;
      }
      .fixture-deets1 {
        display: flex;
        align-items: center;
        .fixture-card-venue {
          font-family: "Cabinet Grotesk";
          font-size: 16px;
          // font-weight: 700;
          color: rgba($color: #000000, $alpha: 0.5);
          margin-right: 2vw;
        }
        .league-icon {
          margin-right: 2vw;
          img {
            width: 36px;
          }
        }
        .matchcard-time {
          font-size: 36px;
        }
        .date {
          width: 150px;
          .matchcard-date {
            font-family: "Cabinet Grotesk";
            font-size: 20px;
            // font-weight: 700;
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
      }

      .fixture-deets2 {
        display: flex;
        flex-grow: 2;
        align-items: center;
        img {
          width: 72px;
          padding-left: 4vw;
        }
        .opponent {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          // gap: 8px;
          margin-left: 32px;
          div {
            display: flex;
            //   gap: 10px;
            font-family: "Gratelos";
            font-size: 36px;
            text-transform: uppercase;
          }
          .matchcard-venue {
            div {
              font-family: "Cabinet Grotesk";
              font-size: 20px;
              // font-weight: 700;
              text-transform: none;
              color: rgba($color: #000000, $alpha: 0.5);
            }
          }
        }
      }
    }

    .ticket {
      .no_ticket {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        font-family: "Gratelos";
        width: max-content;
        display: inline;
        margin: 36px auto;
        text-transform: uppercase;
        padding: 14px 24px;
        cursor: not-allowed;
        border-radius: 26px;
        border: 2px solid rgba(0, 0, 0, 0.5);
        white-space: nowrap;
      }
      .link {
        margin: 36px 0;
        a {
          background-color: transparent;
          color: #202e55;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          border: 2px solid #202e55;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .fixture-card-container {
    padding: 0 24px;
    .fixture-card {
      position: relative;
      padding-top: 32px;
      padding-bottom: 8px;
      .ticket {
        .link {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
        .no_ticket {
          font-size: 16px;
          padding: 12px 24px;
        }
      }

      .fixture-deets1 {
        .league-icon {
          margin-right: unset;
          img {
            width: 28px;
          }
        }
        .fixture-card-venue {
          display: none;
        }
        .date {
          display: none;
        }
      }
      .fixture-card-top-small {
        display: flex;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        padding: 0 24px;
        justify-content: space-between;
        .date-small {
          //   position: absolute;
          display: flex;
          left: 12px;
          .time {
            .matchcard-time {
              font-size: 18px;
              margin-right: 8px;
            }
          }
          .matchcard-date {
            font-family: "Cabinet Grotesk";
            font-size: 14px;
            color: rgba($color: #000000, $alpha: 0.5);
          }
        }
        .fixture-card-venue {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.5);
        }
      }
      .fixture-deets2 {
        img {
          width: 48px;
        }
        .scoreline {
          margin-left: 18px;
          .cont {
            font-size: 20px;
            margin-left: unset;
          }
        }
        .opponent {
          .matchcard-venue {
            div {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .fixture-card-container {
    .fixture-card {
      padding: 38px 16px 0px 16px;
      .fixture-card-top-small {
        padding: 0 16px;
      }
      .ticket {
        margin: 0 auto;
        .link {
          margin: 36px auto;
        }
      }

      .deets-and-cta {
        flex-direction: column;
        .fixture-deets {
          .fixture-deets2 {
            .scoreline {
              .cont {
                font-size: 28px;
              }
              img {
                width: 64px;
                padding-left: 2vw;
              }
            }
            .opponent {
              div {
                font-size: 28px;
              }
              .matchcard-venue {
                div {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .fixture-card-container {
    padding: 0 16px;
    .fixture-card {
      .deets-and-cta {
        .fixture-deets {
          .fixture-deets1 {
            .league-icon {
              img {
                width: 24px;
              }
            }
          }
          .fixture-deets2 {
            .opponent {
              margin-left: 18px;
              .matchcard-venue {
                div {
                  font-size: 14px;
                }
              }
            }
            img {
              width: 48px;
            }
          }
        }
      }
    }
  }
}
