.clubhistory {
  .top {
    .img {
      background-color: #000000;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .title {
      color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 60px;
      }
      .back-to-prev {
        display: block;
        text-decoration: none;
        color: #ffffff;
        width: max-content;
        font-size: 28px;
        margin-bottom: 16px;
        width: max-content;
      }
    }
  }
  .content-container {
    background-color: #ffffff;
    .content {
      background-color: #ffffff;
      padding: 30px 64px;
      max-width: 1512px;
      margin: auto;
      .body-text {
        margin: 32px auto;
        // width: 50%;
        padding: 0 20%;
        h1 {
          font-size: 50px;
          margin-top: 40px;
          margin-bottom: 20px;
          color: #202e55;
        }
        h2 {
          font-size: 40px;
          margin: 15px 0;
          color: #202e55;
          text-transform: uppercase;
        }
        h3 {
          font-size: 0px;
          margin-bottom: 10px;
          color: #202e55;
        }
        p {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          margin-bottom: 10px;
          i,
          a,
          b {
            font-family: "Cabinet Grotesk";
          }
          a {
            // color: #4b7aee;
            text-decoration: none;
            border-bottom: 1px solid #000000;
            padding-bottom: 0px;
            // padding-bottom: 2px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .clubhistory {
    .top {
      .title {
        padding: 30px 24px;
        h1 {
          font-size: 48px;
        }
      }
    }
    .content-container {
      .content {
        padding: 30px 24px;
        .body-text {
          padding: 0 12%;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .clubhistory {
    .content-container {
      .content {
        // padding: 30px 16px;
        .body-text {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .clubhistory {
    .top {
      .title {
        padding: 30px 16px;
        .back-to-prev {
          font-size: 16px;
        }
        h1 {
          font-size: 40px;
        }
      }
    }
    .content-container {
      .content {
        padding: 30px 16px;
        .body-text {
          h1 {
            font-size: 36px;
          }
        }
      }
    }
  }
}
