.trophy {
	.member {
		margin: 20px 0;
		.members_display {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 30px;
			.trophy_card {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 30px;
				box-shadow: 0px 0px 5px v.$primary;
				border-radius: 8px;
				overflow: hidden;
				.img {
					overflow: hidden;
					img {
						border-top-left-radius: 8px;
						border-bottom-left-radius: 8px;
						height: 100%;
						object-fit: cover;
						width: 100%;
						transition: all ease-in-out 0.4s;
						&:hover {
							transform: scale(1.2);
						}
					}
				}
				.detail {
					padding: 20px 20px 20px 0;
					.name {
						margin: 5px 0;
						font-size: 30px;
						font-family: "Gratelos";
						letter-spacing: 1px;
					}
					.won {
						font-size: 20px;
					}
					.dates {
						margin: 5px 0;
						font-size: 18px;
						font-family: "Cabinet Grotesk";
						font-weight: normal;
					}
				}
			}
		}
	}
}
