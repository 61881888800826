.positions {
  max-width: 1512px;
  margin: auto;
  margin-top: 64px;
}

@media screen and (max-width: 414px) {
  .positions {
    margin-top: 41px;
  }
}
