@keyframes ldio-frbl7jhtid9 {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-frbl7jhtid9 div {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 10px solid #ffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-frbl7jhtid9 div {
  animation: ldio-frbl7jhtid9 1s linear infinite;
  top: 50px;
  left: 50px;
}
.white div {
  // border: 10px solid #ffffff;
  border-top-color: transparent;
  border-radius: 50%;
}
.black div {
  border: 10px solid #000000;
  border-top-color: transparent;
  border-radius: 50%;
}
.loadingio-spinner-rolling-d9kbrilsnbr {
  width: 40px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-frbl7jhtid9 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.4);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-frbl7jhtid9 div {
  box-sizing: content-box;
}
