.footer {
  height: 90px;
  background-color: #000000;
  padding: 42px 64px;
  padding-bottom: 53px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .social-links {
    display: flex;
    // justify-content: space-between;
    // gap: 24px;
    a {
      margin-right: 24px;
    }
  }
  .back-to-top {
    cursor: pointer;
    p {
      font-size: 16px;
      font-family: "Cabinet Grotesk";
      color: #ffffff;
      text-decoration: underline;
    }
  }

  .upper-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .lower-links {
    display: flex;
    justify-content: space-between;
    ul {
      display: flex;
      gap: 60px;
      a {
        text-decoration: none;
        p {
          font-size: 16px;
          font-family: "Cabinet Grotesk";
          color: #ffffff;
        }
      }
    }
  }

  .copyright {
    p {
      font-size: 16px;
      font-family: "Cabinet Grotesk";
      color: #ffffff;
      //   text-decoration: underline;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 42px 24px;
    .lower-links {
      margin-top: 20px;
      .footer-menu {
        width: 50%;
        ul {
          gap: unset;
          flex-wrap: wrap;
          li {
            // width: 46%;
            margin-right: 24px;
            margin-bottom: 24px;
            a {
              p {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .footer {
    height: unset;
    .lower-links {
      margin-top: 20px;
      flex-direction: column;
      .footer-menu {
        width: unset;
        margin-bottom: 32px;
        ul {
          li {
            width: unset;
            a {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
      .copyright {
        p {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .footer {
    padding: 48px 16px;
  }
}
