.overall {
  // display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  .landing_table {
    // flex-grow: 1;
    margin: 20px 0;
    padding: 40px 0px;
  }
  .landing_counter {
    flex-grow: 1;
    .next {
      color: grey;
      margin-bottom: 20px;
      font-size: 13px;
    }
    .countdown {
      background-color: #181f39;
      // min-width: 80%;
      // border-radius: 4px;
      padding: 32px 244px;
      color: #ffffff;
      display: flex;
      align-items: center;
      // gap: 5vw;
      .name {
        text-align: right;

        p {
          font-family: "Cabinet Grotesk";
          font-size: 32px;
          // font-weight: 700;
          // letter-spacing: -0.001em;
          color: #ffffff;
        }
      }
      .count {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-size: 20px;
        // gap: 16px;
        span {
          margin-top: -24px;
          font-size: 50px;
          font-family: "Gratelos";
        }
        .time {
          font-size: 72px;
          font-family: "Gratelos";
          display: flex;
          flex-direction: column;
          gap: 0;
          span {
            align-self: center;
            font-size: 16px;
            font-family: "Cabinet Grotesk";
            margin-top: 4px;
          }
        }
      }
      .count > * + * {
        margin-left: 16px;
      }
      .ticket {
        .link {
          a {
            background-color: v.$primary;
            color: #ffffff;
            font-size: 24px;
            font-family: "Gratelos";
            white-space: nowrap;
            text-overflow: ellipsis;
            text-decoration: none;
            padding: 15px 20px;
            border: 2px solid;
            border-radius: 26px;
            transition: all ease-in-out 0.4s;
            text-transform: uppercase;
            @media screen and (max-width: 760px) {
              font-size: 16px;
            }
          }

          :hover {
            background-color: #ffffff;
            color: #202e55;
            border: 2px solid #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .overall {
    .landing_counter {
      .countdown {
        padding: 32px 64px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .overall {
    .landing_counter {
      .countdown {
        .name {
          p {
            font-size: 20px;
          }
        }
        .count {
          // gap: 8px;
          .time {
            font-size: 52px;
          }
          span {
            font-size: 30px;
          }
        }
        .count > * + * {
          margin-left: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .overall {
    .landing_counter {
      .countdown {
        flex-direction: column;
        gap: 16px;
        padding: 32px 16px;
        .name {
          display: flex;
          p {
            padding-right: 4px;
          }
        }
        .count {
          margin-bottom: 24px;
          .time {
            font-size: 72px;
          }
          span {
            font-size: 50px;
          }
        }
        .ticket {
          margin-bottom: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .overall {
    .landing_counter {
      .countdown {
        .name {
          p {
            font-size: 14px;
          }
        }
        .count {
          margin-bottom: 24px;
          .time {
            font-size: 56px;
          }
          span {
            font-size: 40px;
          }
        }
        .ticket {
          margin-bottom: 12px;
          .link {
            a {
              font-size: 16px;
              padding: 12px 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .overall {
    .landing_counter {
      .countdown {
        background-color: #181f39;
        padding: 32px 64px;
        color: #ffffff;
        display: flex;
        align-items: center;
        .count,
        .ticket {
          width: 33%;
          .link {
            padding-left: 44px;
            @media screen and (max-width: 759px) {
              padding-left: 0;
            }
          }
        }
        .name {
          text-align: right;
          width: 33%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          padding-right: 44px;
        }
      }
    }
  }
}
