// Global styles
$brown: #6b4f3a; // Define brown color

.academy-container {
  .academy-top {
    position: relative;

    .academy-image {
      width: 100%;
      img {
        width: 100%;
        height: 60vh;
        object-fit: cover;
        opacity: 0.7;
      }
    }

    .academy-title {
      color: #ffffff;
      padding: 20px 32px;
      max-width: 1512px;
      margin: auto;
      h1 {
        font-size: 60px;
      }
    }
  }

  .academy-content-wrapper {
    background-color: #ffffff;
    padding: 30px 0 0 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    .academy-content-header {
      color: white;
      font-size: 30px;
      font-family: "Cabinet Grotesk";
      text-align: center;
    }

    .academy-cta-1 {
      width: 100%;
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background-image: url("../../assets/images/team.jpg");
      background-size: cover;
      background-position: center;
      color: white;
      text-align: center;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1;
      }

      h2 {
        font-size: 72px;
      }

      p,
      h1,
      h2,
      .cta-button {
        z-index: 2;
        margin: 10px 0;
        border-radius: 45px;
      }

      .cta-button {
        text-transform: uppercase;
        background-color: #202e55;
        color: #ffffff;
        text-decoration: none;
        font-size: 24px;
        font-family: "Gratelos";
        padding: 14px 24px;
        border-radius: 26px;
        border: 2px solid #202e55;
        transition: all ease-in-out 0.2s;
        transition-delay: 0.3s;
        width: max-content;

        &:hover {
          background-color: #ffffff;
          color: #202e55;
          border: 1px solid #ffffff;
        }
      }
    }

    .academy-cta-2 {
      // width: 100%;
      height: auto;
      // gap: 5px;
      background-color: black;
      color: white;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .column {
        padding: 50px;
        font-family: "Cabinet Grotesk";
        width: 45%;
        text-align: left;
        line-height: 1.2;
        p {
          margin-bottom: 15px;
          font-family: "Cabinet Grotesk";
        }

        h1,
        h2 {
          font-family: "Cabinet Grotesk";
          margin: 10px 0;
        }
      }
    }
  }

  .homekit-promo {
    padding-left: 450px;
    position: relative;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: left;
    height: auto;

    .homekit-image {
      width: 533px;
      height: 533px;
      overflow: hidden;
      position: absolute;
      left: 5%;
      z-index: 2;
      display: flex;
      justify-content: center;
    }

    .homekit-description {
      background-color: #4b7aee;
      padding: 137px 30%;
      top: 0;
      right: 0;
      left: 40%;
      height:auto;
      // text-align: left;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .subtitle {
        font-family: "Cabinet Grotesk";
        font-size: 16px;
        letter-spacing: 0.2em;
        margin-bottom: 9px;
      }
      
      .title {
        font-size: 72px;
        text-transform: uppercase;
        margin-bottom: 42px;
        text-align: center;
      }

      .body-text {
      width:90%;
        font-family: "Cabinet Grotesk";
        font-size: 20px;
      }

      .all-home-kits {
        text-transform: uppercase;
        margin-top: 72px;
        text-align: center;
        a {
          background-color: #ffffff;
          color: #202e55;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
        }

        :hover {
          background-color: #f2f2f2;
          color: #202e55;
        }
      }
    }
  }

  /* Responsive styles */

  @media screen and (max-width: 1500px) {
    .academy-top .academy-title h1 {
      font-size: 48px;
    }
    .academy-content-wrapper .academy-cta-1 h2 {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 1300px) {
    .academy-cta-2 {flex-direction: row;} .column {
      width: 40%;
      // flex-direction: column;
    }
    .homekit-promo .homekit-description {
      padding: 137px 20%;
    }
    .homekit-promo {
      padding-left: unset;
      flex-direction: column;
      margin-top: 48px;
      .homekit-image {
        left: unset;
        display: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .academy-title h1 {
      font-size: 40px;
    }
    .academy-cta-1 h2 {
      font-size: 36px;
    }
    .academy-cta-1 {
      height: 50vh;
    }
    .academy-cta-2 {
      flex-direction: column;
    }
    .column {
      width: 100%;
      padding: 10px;
      // text-align: center;
    }
    .homekit-promo {
      flex-direction: column;
      padding-left: unset;
      margin-top: 48px;
      .homekit-image {
        width: 100%;
        height: 100%;
        left: unset;
        position: relative;
        display: none;
      }
      .homekit-description {
        // width: 100%;
        padding: 50px 20px;
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .academy-top .academy-title {
      padding: 10px 20px;
      h1 {
        font-size: 32px;
      }
    }
    .academy-cta-1 h2 {
      font-size: 28px;
    }
    .academy-cta-1 p {
      font-size: 18px;
    }
    .academy-cta-2 {
      flex-direction: column;
    }
    .column {
      width: 100%;
      // padding: 0, 30px;
      // margin-bottom: 20px;

      h1 {
        font-size: 18px;
      }
    }
    .homekit-promo {
      padding-left: 0;
      .homekit-image {
        width: 100%;
        display: none;
        height: auto;
        position: relative;
        left: unset;
      }
      .homekit-description {
        padding: 40px 20px;
        margin-top: 20px;
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .academy-title h1 {
      font-size: 24px;
    }
    .academy-cta-1 h2 {
      font-size: 20px;
    }
    .academy-cta-1 p {
      font-size: 16px;
    }
    .academy-cta-2 {
      flex-direction: column;
      width: 100%;
    }
    .column h1 {
      font-size: 16px;
      // width: 80%;
    }
    .homekit-promo .homekit-description {
      padding: 30px 20px;
      font-size: 16px;
    }
  }
}
