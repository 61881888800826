.landing_standing {
	display: flex;
	max-width: 1512px;
	margin: auto;
	gap: 32px;
	padding: 20px 64px;

	.div {
		flex-grow: 1;
		margin: auto;
		// padding: 0 64px;
		padding-bottom: 40px;

		.table-top {
			display: flex;
			justify-content: space-between;
			margin-bottom: 16px;
			.league {
				display: flex;
				align-items: center;
				gap: 10px;
				color: grey;
				font-size: 16px;
				font-family: "Cabinet Grotesk";
				// letter-spacing: 1px;
				.icon {
					height: 20px;
					img {
						height: 100%;
					}
				}
				.name {
					p {
						font-family: "Cabinet Grotesk";
					}
				}
			}
			.link {
				a {
					color: v.$primary;
					text-decoration: none;
					font-size: 16px;
					// font-weight: 700;
					font-family: "Cabinet Grotesk";
					// letter-spacing: -0.02em;
				}
			}
		}
		table {
			border: 1px solid rgba(0, 0, 0, 0.16);
			border-spacing: 0;
			border-radius: 4px;
			height: 100%;
			width: 100%;
			color: v.$primary;
		}
		thead {
			background-color: #f2f2f2;
		}
		td,
		th {
			text-align: left;
			padding: 24px;
			font-size: 24px;
			font-family: "Cabinet Grotesk";
		}

		tr {
			height: 65px;
		}

		.position {
			width: 5%;
			text-align: center;
			font-family: "Cabinet Grotesk";
		}

		.position-content {
			text-align: center;
			font-size: 24px;
			font-family: "Gratelos";
		}

		// .team {
		//   padding-left: 48px;
		// }

		.team-content {
			column-width: 300px;
			font-size: 32px;
			font-family: "Gratelos";
			text-transform: uppercase;
			// padding-left: 48px;
		}

		.played {
			width: 5%;
			text-align: center;
		}

		.played-content {
			text-align: center;
		}

		.gd {
			width: 5%;
			text-align: center;
		}

		.gd-content {
			text-align: center;
		}

		.points {
			width: 5%;
			text-align: center;
		}

		.points-content {
			text-align: center;
			// font-weight: 700;
		}

		.col-header {
			font-size: 18px;
			// font-weight: 700;
		}
	}
	.sl {
		background-color: #202e55;
		color: #ffffff;
	}
}

@media screen and (max-width: 1280px) {
	.landing_standing {
		flex-direction: column;
		.div {
			margin: 0;
		}
	}
}

@media screen and (max-width: 1024px) {
	.landing_standing {
		padding: 0 24px;
	}
}

@media screen and (max-width: 760px) {
	.landing_standing {
		padding: 0 24px;
		.div {
			.table-top {
				.link {
					a {
						font-size: 16px;
					}
				}
			}
			.position-content {
				font-size: 16px;
			}
			.team-content {
				font-size: 26px;
			}
			td,
			th {
				padding: 12px;
				font-size: 20px;
			}
		}
	}
}

@media screen and (max-width: 414px) {
	.landing_standing {
		gap: 0px;
		padding: 0 16px;
		.div {
			tr {
				height: 48px;
			}
			td,
			th {
				padding: 8px;
				font-size: 16px;
			}
			.col-header {
				font-size: 12px;
			}
			.team-content {
				font-size: 24px;
			}
			.table-top {
				.league {
					.name {
						p {
							font-size: 12px;
						}
					}
				}
				.link {
					a {
						font-size: 12px;
					}
				}
			}
		}
	}
}
