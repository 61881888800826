.league-table-container {
	background-color: #ffffff;
	padding-top: 40px;
	.league-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;
		.league_name {
			display: block;
		}
		.league_abb {
			display: none;
		}
		img {
			width: 48px;
		}
		p {
			font-family: "Cabinet Grotesk";
			font-size: 24px;
			font-weight: bold;
			color: #000000;
		}
		.each_table {
			scroll-margin-top: 100px;
		}
	}

	.standing {
		margin-top: 40px;
		max-width: 1512px;
		margin: auto;
		padding: 0 64px;
		padding-bottom: 40px;

		.group {
			margin: 20px 0;
			color: v.$accent;
			font-family: "Gratelos";
			font-size: 28px;
			text-transform: uppercase;
		}
		table {
			border: none;
			border-spacing: 0;
			border-radius: 4px;
			border-collapse: collapse;
			height: 100%;
			width: 100%;
			color: v.$primary;

			thead {
				background-color: #f2f2f2;
				tr {
					height: 57px;
					margin-bottom: 6px;
				}
			}
			tr {
				height: 50px;
			}
			td {
				text-align: left;
				padding: 8px;
				font-family: "Cabinet Grotesk";
			}
			th {
				text-align: left;
				padding: 8px;
				font-family: "Cabinet Grotesk";
			}
		}

		.ga {
			width: 5%;
			text-align: center;
		}

		.col-header {
			font-size: 20px;
			font-weight: bolder;
		}
		.form {
			width: 16%;
			//   text-align: center;
		}
		.progress-content {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0;
			img {
				width: 10px;
				text-align: center;
			}
		}
		.position {
			text-align: center;
		}
		.position-content {
			font-family: "Cabinet Grotesk";
			font-size: 20px;
			font-weight: bold;
			text-align: center;
		}
		.team_content {
			font-family: "Cabinet Grotesk";
			font-size: 20px;
			font-weight: bold;
			text-transform: capitalize;
		}
		.sl {
			background-color: #202e55;
			color: #ffffff;
		}
		.sl2 {
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		}
		.ga_content {
			text-align: center;
		}
		.col-style {
			font-size: 20px;
			font-weight: bold;
			font-weight: normal;
		}
		.form-content {
			display: flex;
			//   gap: 8px;
			//   justify-content: center;
			.win {
				background-color: #59c150;
			}
			.draw {
				background-color: #808080;
			}
			.lose {
				background-color: #be0017;
			}
			.outcome {
				font-family: "Cabinet Grotesk";
				font-size: 18px;
				font-weight: bold;
				color: #ffffff;
				width: 18px;
				padding: 4px;
				text-align: center;
				margin-right: 8px;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.league-table-container {
		.league-icon {
			img {
				width: 40px;
			}
			p {
				font-size: 20px;
			}
		}
		.standing {
			padding: 0 24px;
			padding-bottom: 40px;
			.col-style,
			.col-header {
				font-size: 16px;
			}
			.form-content {
				gap: 4px;
				.outcome {
					font-size: 14px;
					width: 14px;
				}
			}
		}
	}
}

@media screen and (max-width: 760px) {
	.league-table-container {
		.league-icon {
			.league_name {
				display: none;
			}
			.league_abb {
				display: block;
			}
		}
		.standing {
			//   padding: 0 24px;
			.col-style,
			.col-header {
				font-size: 16px;
			}
			.form-content {
				display: none;
				.outcome {
					font-size: 14px;
					width: 14px;
				}
			}
		}
		.form {
			display: none;
		}
	}
	.gf,
	.gf_data,
	.gc,
	.gc_data {
		display: none;
	}
}

@media screen and (max-width: 414px) {
	.league-table-container {
		.standing {
			padding: 0 16px;
			padding-bottom: 40px;
		}
		.league-icon {
			p {
				display: none;
			}
		}
		.form,
		.tie,
		.tie_data,
		.lost,
		.lost_data {
			display: none;
		}
	}
}
