.sidebar {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  background-color: v.$accent;
  z-index: 2;
  color: transparent;
  .side-links {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    padding: 48px 24px;
    color: #ffffff;
    transition: all ease-in-out 0.5s;
    opacity: 1;
    margin-left: 0;

    h2 {
      margin: 10px 0;
      font-size: 30px;
      display: none;
    }
    .search-input {
      margin-bottom: 40px;
      .active {
        transition: all ease-in-out 0.3s;
        width: 100%;
        background-color: rgba($color: #ffffff, $alpha: 0.1);
        height: 100%;
        align-items: center;
        display: flex;
        border-radius: 8px;
        // border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.3);
        img {
          //   margin-left: 8px;
          width: 24px;
        }
        .input {
          // outline: none;
          border: none;
          padding: 10px 32px 10px 40px;
          font-size: 30px;
          color: #ffffff;
          box-sizing: border-box;
          width: 100%;
          background-color: transparent;
          &::placeholder {
            color: rgba($color: #ffffff, $alpha: 0.3);
          }
        }
        .close {
          font-size: 20px;
          cursor: pointer;
          color: #000000;
          margin-right: 8px;
          // background-color: #ffffff;
          //   width: 20px;
        }
      }
    }
    .links {
      flex-grow: 1;
      //   border-top: 1px solid rgba($color: #ffffff, $alpha: 0.3);
      .link {
        margin: 24px 0;
        a {
          font-size: 40px;
          color: inherit;
          text-decoration: none;
        }
      }
    }
    .social {
      .icon {
        margin: 2vh 0;
        display: flex;
        gap: 5%;
        a {
          color: inherit;
          font-size: 25px;
        }
      }
    }
  }
}

.hide {
  overflow: hidden;
  width: 0;
  transition: width ease-in-out 0.35s;
  .side-links {
    color: transparent;
    transition: all ease-in-out 0.15s;
    opacity: 0;
    margin-left: -20px;
  }
}
@media screen and (min-width: 761px) {
  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .sidebar {
    .side-links {
      .search-input {
        .active {
          .input {
            // outline: 4px solid #4b7aee;
            outline-color: #4b7aee;
            outline-width: 2px;
            border-radius: 8px;
          }
          img {
            margin-left: 8px;
            position: absolute;
          }
          .close {
            position: absolute;
            right: 49px;
            margin-bottom: 22px;
          }
        }
      }
      .social {
        background-color: unset;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .sidebar {
    .side-links {
      padding: 48px 16px;
    }
  }
}
