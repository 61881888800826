.player_stats {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 100px;
	row-gap: 32px;
	max-width: 1512px;
	margin: auto;
	padding-bottom: 80px;
	.title {
		font-size: 72px;
		text-transform: uppercase;
		margin-bottom: 0px;
	}
	.stats_details {
		.item {
			//   width: 90%;
			margin: 16px 0;
			padding: 16px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			.stat_name {
				font-family: "Cabinet Grotesk";
				font-size: 20px;
				text-transform: capitalize;
			}
			.item_right {
				text-transform: uppercase;
				font-size: 56px;
				color: #59c150;
			}
		}
	}
	.left {
		.bio {
			height: 200px;
			overflow: hidden;
			transition: all ease-in-out 0.3s;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				z-index: 1;
				bottom: 0;
				left: 0;
				pointer-events: none;
				background-image: linear-gradient(
					to bottom,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 1) 90%
				);
				width: 100%;
				height: 4em;
			}
			p,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin: 15px 0;
				color: initial;
			}
		}
		.bio_more {
			transition: all ease-in-out 0.3s;
			height: fit-content;
			&:after {
				display: none;
			}
		}
		.more {
			border: 2px solid v.$primary;
			border-radius: 20px;
			padding: 8px 15px;
			width: fit-content;
			margin: 20px auto;
			cursor: pointer;
		}
	}
	.right {
		.info {
			color: initial;
		}
	}
}

@media screen and (max-width: 1024px) {
	.player_stats {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 760px) {
	.player_stats {
		grid-template-columns: 1fr;
		.title {
			font-size: 56px;
		}
		.stats_details {
			.item {
				margin: 12px 0;
				padding: 12px 0;
			}
		}
	}
}

@media screen and (max-width: 414px) {
	.player_stats {
		grid-template-columns: 1fr;
		.title {
			font-size: 36px;
		}
		.stats_details {
			.item {
				margin: 8px 0;
				padding: 8px 0;
				.stat_name {
					font-size: 16px;
				}
				.item_right {
					font-size: 36px;
				}
			}
		}
	}
}
