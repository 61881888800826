.partner {
  .member {
    margin: 20px 0;

    .members_display {
      // display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      .parner_card {
        display: grid;
        grid-template-columns: 0.3fr 1fr;
        border-radius: 8px;
        box-shadow: 0px 0px 64px rgba($color: #000000, $alpha: 0.04);
        margin-bottom: 64px;
        background-color: #ffffff;
        .img {
          display: flex;
          background: linear-gradient(to bottom left, #fadf4a, #181f39);
          justify-content: center;
          align-items: center;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          padding: 5px;
          img {
            width: 160px;
          }
        }
        .detail {
          padding: 24px 32px 64px 32px;
          .name {
            margin: 16px 0;
            font-size: 48px;
            text-transform: uppercase;
            // font-family: "Cabinet Grotesk";
            // font-weight: bold;
            color: v.$accent;
          }
          .des {
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 4;
            -webkit-line-clamp: 4;
            font-size: 20px;
            overflow: hidden;

            text-overflow: ellipsis;
            font-weight: thin;
            white-space: normal;
            p {
              font-family: "Cabinet Grotesk";
              //   margin-bottom: 16px;
              b,
              i,
              u {
                font-family: "Cabinet Grotesk";
                // margin-bottom: 16px;
              }
            }
          }
          .more {
            margin: 20px 0;
            margin-top: 48px;
            a {
              padding: 14px 24px;
              text-decoration: none;
              background-color: v.$accent;
              color: #ffffff;
              border-radius: 26px;
              border: 2px solid #202e55;
              font-size: 24px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .partner {
    .member {
      .members_display {
        .parner_card {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          .img {
            border-radius: 0;
            padding: 0;
            height: 25vh;
          }
          .detail {
            padding: 24px 16px;
            .name {
              font-size: 36px;
            }
            .des {
              p {
                font-size: 16px;
              }
            }
            .more {
              a {
                padding: 12px 24px;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
