.viewmembers {
  margin-top: v.$header_h;
  background-color: #fff;
  padding: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    .form-control-btn {
      width: 100%;
      color: #ffffff;
      cursor: pointer;
      background-color: v.$accent;
      padding: 10px;

      font-size: 20px;
      display: block;
      outline: none;
      font-family: "Cabinet Grotesk";
      border: none;
      border-radius: 8px;
    }
  }
  .btn-download {
    width: 150px;
    color: #ffffff;
    cursor: pointer;
    background-color: v.$accent;
    padding: 10px;
    margin: 20px 0;
    font-size: 20px;
    display: block;
    outline: none;
    font-family: "Cabinet Grotesk";
    border: none;
    border-radius: 8px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: calc(100% - 20px);
    overflow: auto;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 6px;
    font-family: "Cabinet Grotesk" !important;
    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }

  @media only screen and (max-width: 1300px),
    (min-device-width: 100px) and (max-device-width: 1300px) {
    /* Force table to not be like tables anymore */

    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
      font-size: 16px;
    }
    tr {
      border-top: 1px solid black;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(odd) {
      background: #ccc;
    }
    tr:nth-child(even) {
      background: #fff;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 0;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    /*
  Label the data
  You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  */
    td:nth-of-type(1):before {
      content: "First Name";
    }
    td:nth-of-type(2):before {
      content: "Last Name";
    }
    td:nth-of-type(3):before {
      content: "Nick name";
    }
    td:nth-of-type(4):before {
      content: "Email";
    }
    td:nth-of-type(5):before {
      content: "Phone";
    }
    td:nth-of-type(6):before {
      content: "Gender";
    }
    td:nth-of-type(7):before {
      content: "DOB";
    }
    td:nth-of-type(8):before {
      content: "Country";
    }
    td:nth-of-type(9):before {
      content: "Tier";
    }
    td:nth-of-type(10):before {
      content: "Joined";
    }
    td:nth-of-type(11):before {
      content: "Expire";
    }
    td:nth-of-type(12):before {
      content: "Payment Ref";
    }
    td:nth-of-type(13):before {
      content: "Currency";
    }
    td:nth-of-type(14):before {
      content: "Paid?";
    }
  }
}
