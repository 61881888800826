.review-container {
  .hero-scoreboard {
    // max-width: 1512px;
    display: flex;
    height: 90vh;
    margin: auto;
    justify-content: center;
    align-items: center;
    // margin-top: 146px;/
    // padding: 0 64px;
    background-color: #000000;
    overflow: hidden;
    // .results {
    //   position: absolute;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   width: 100%;
    //   height: 90vh;
    // }
    img {
      opacity: 0.4;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // opacity: 0.4;
  }
  .review-top {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      opacity: 1;
    }
    .date-venue {
      margin-bottom: 68px;
      .date {
        font-weight: bold;
        font-size: 20px;
        margin-top: 8px;
      }
      p {
        text-align: center;
        font-family: "Cabinet Grotesk";
        font-size: 18px;
        color: #ffffff;
      }
    }
    .match-detail {
      display: flex;
      justify-content: center;
      gap: 1vw;
      height: 130px;
      width: 100%;
      .host {
        // margin-top: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        width: 33%;
      }
      .opponent {
        // margin-top: 44px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 33%;
      }
      .host-name {
        text-align: right;
        color: #ffffff;
        font-size: 54px;
      }
      .opponent-name {
        color: #ffffff;
        font-size: 54px;
      }

      .versus {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        .host-logo,
        .opponent-logo {
          // min-width: 130px;
          width: 120px;
          height: 120px;
          text-align: center;
          img {
            height: 120px;
            width: auto;
          }
        }
        .scoreline {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          min-width: 213px;
          margin: auto 32px;
          gap: 24px;
          .score {
            font-size: 124px;
            color: #ffffff;
            width: 33%;
          }
          .timestamp {
            p {
              font-family: "Cabinet Grotesk";
              font-size: 20px;
              font-weight: bold;
              background-color: #ffffff;
              padding: 4px 8px;
              border-radius: 4px;
            }
          }
        }
      }
      .goals-scorer-host {
        margin-top: 0px;
        p {
          color: #ffffff;
          font-family: "Cabinet Grotesk";
          font-size: 18px;
          font-weight: bold;
          text-align: right;
          margin-top: 4px;
        }
      }
      .goals-scorer-opponent {
        margin-top: 0px;
        p {
          color: #ffffff;
          font-family: "Cabinet Grotesk";
          font-size: 18px;
          font-weight: bold;
          margin-top: 4px;
        }
      }
    }
  }
}
.loading {
  background-color: #ffffff;
  margin-top: v.$header_h;
  padding: 30px;
  h1 {
    width: max-content;
    margin: auto;
  }
}
.match_display {
  .content {
    background-color: #ffffff;
    padding: 0px 23%;
    .asset_image {
      width: 70%;
      text-align: center;
      display: flex;
      margin: 0 auto;
      border-radius: 8px;
    }
    .desc {
      // margin: 32px auto;
      width: fit-content;
      font-family: "Cabinet Grotesk";
      margin: 16px auto;
      font-size: 12px;
      text-align: center;
      font-style: italic;
    }
    .intro {
      // padding-top: 30px;
      p,
      h1,
      h2,
      h3 {
        font-size: 30px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 50px;
      margin: 10px 0;
    }
    p,
    h4,
    h5,
    h6 {
      margin-bottom: 32px;
      color: initial;
      font-family: "Cabinet Grotesk";
      font-size: 20px;
      a {
        font-family: "Cabinet Grotesk";
        text-decoration: none;
        font-weight: 700;
        color: #4b7aee;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .review-container {
    .match_display {
      .content {
        padding: 30px 12%;
        .intro {
          p,
          h1,
          h2,
          h3 {
            font-size: 30px;
          }
        }
        h1,
        h2,
        h3 {
          font-size: 50px;
          margin: 10px 0;
        }
        p,
        h4,
        h5,
        h6 {
          margin-bottom: 32px;
          color: initial;
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          white-space: break-spaces;
          a {
            font-family: "Cabinet Grotesk";
            text-decoration: none;
            font-weight: 700;
            color: #4b7aee;
          }
        }
      }
    }
    .review-top {
      .match-detail {
        .host {
          position: absolute;
          left: 0px;
          top: 240px;
          display: none;
        }
        .opponent {
          position: absolute;
          right: 0px;
          top: 240px;
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .review-container {
    .match_display {
      .content {
        padding: 30px 24px;
        .intro {
          p,
          h1,
          h2,
          h3 {
            font-size: 24px;
          }
        }
        h1,
        h2,
        h3 {
          font-size: 50px;
          margin: 10px 0;
        }
        p,
        h4,
        h5,
        h6 {
          margin-bottom: 32px;
          color: initial;
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          a {
            font-family: "Cabinet Grotesk";
            text-decoration: none;
            font-weight: 700;
            color: #4b7aee;
          }
        }
      }
    }
    .review-top {
      .match-detail {
        .host {
          left: 0px;
          margin-top: unset;
        }
        .opponent {
          right: 0px;
          margin-top: unset;
        }
        .host-name,
        .opponent-name {
          font-size: 32px;
        }
        .versus {
          height: max-content;
          gap: unset;
          .host-logo,
          .opponent-logo {
            width: 80px;
            height: 80px;
            img {
              height: 80px;
            }
          }
          .scoreline {
            gap: 16px;
            margin: auto 16px;
            .score {
              font-size: 90px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .review-container {
    .match_display {
      .content {
        padding: 30px 16px;
        .asset_image {
          width: 100%;
        }
        .intro {
          p,
          h1,
          h2,
          h3 {
            font-size: 20px;
          }
        }
        h1,
        h2,
        h3 {
          font-size: 50px;
          margin: 10px 0;
        }
        p,
        h4,
        h5,
        h6 {
          margin-bottom: 32px;
          color: initial;
          font-family: "Cabinet Grotesk";
          font-size: 16px;
          white-space: break-spaces;
          a {
            font-family: "Cabinet Grotesk";
            text-decoration: none;
            font-weight: 700;
            color: #4b7aee;
          }
        }
      }
    }
    .review-top {
      .match-detail {
        .host {
          margin-top: unset;
          left: 0px;
        }
        .opponent {
          margin-top: unset;
          right: 0px;
        }
        .host-name,
        .opponent-name {
          font-size: 20px;
        }
        .goals-scorer-host,
        .goals-scorer-opponent {
          p {
            font-size: 12px;
          }
        }
        .versus {
          .host-logo,
          .opponent-logo {
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            img {
              height: 70px;
            }
          }
          .scoreline {
            gap: 8px;
            min-width: 103px;
            margin: auto 8px;
            .score {
              font-size: 64px;
            }
            .timestamp {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
