.shop_carousel {
  // margin-top: 30px;
  .swiper {
    // min-height: 450px;
    height: max-content;
    // padding-top: 10px;
    // padding: 0 64px;
    padding-bottom: 40px;
    margin-top: -75px;

    iframe {
      border-radius: 10px;
    }
    .slider {
      color: #ffffff;
      .details {
        margin-top: 20px;
        font-weight: 100;
        .date {
          margin-top: 5px;
          font-size: 15px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  .swiper-wrapper {
    margin-top: 75px;
  }

  .swiper-pagination {
    top: 95%;
    bottom: 0 !important;
    // display: none;
  }
  .swiper-pagination-bullet {
    background: v.$primary;
  }
  .swiper-pagination-bullet-active {
    background: v.$primary;
  }

  .swiper-slide .swiper-slide-active {
    margin-right: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 0%;
    margin-top: 0;
    &::after {
      font-size: 24px;
    }
  }

  .swiper-button-prev {
    right: 122px;
    font-size: 32px !important;
    font-weight: bold;
    left: auto;
    margin-right: -46px;
    color: #202e55;
  }

  .swiper-button-next {
    right: 0;
    font-size: 32px !important;
    font-weight: bold;
    color: #202e55;
  }
}

@media screen and (max-width: 1500px) {
  .shop_carousel {
    .swiper {
      padding: 0 64px;
      padding-bottom: 40px;
    }
    .swiper-button-next {
      right: 64px;
    }
    .swiper-button-prev {
      right: 186px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .shop_carousel {
    .swiper {
      padding: 0 24px;
      padding-bottom: 40px;
    }
    .swiper-button-next {
      right: 24px;
    }
    .swiper-button-prev {
      right: 146px;
    }
  }
}

@media screen and (max-width: 414px) {
  .shop_carousel {
    .swiper {
      height: 370px;
      padding: 0 16px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      margin-top: 7px;
      &::after {
        font-size: 20px;
      }
    }
    .swiper-button-next {
      right: 16px;
    }
    .swiper-button-prev {
      margin-right: -55px;
      right: 130px;
    }
  }
  .shop_card {
    height: max-content;
    .details {
      .name {
        font-size: 16px;
        font-family: "Cabinet Grotesk";
        text-transform: capitalize;
        font-weight: bold;
        color: v.$accent;
        height: 40px;
      }
      .link {
        a {
          font-size: 16px;
          padding: 6px 14px;
          padding-left: 26px;
        }
      }
    }
    .img {
      width: 200px;
    }
  }
}
