.player_details {
	background-color: #f2f2f2;
	margin-top: 80px;
	.top {
		// padding: 0 60px;
		padding: 0 64px;
		position: relative;
		max-width: 1512px;
		margin: auto;
		.left {
			position: relative;
			width: 739px;
			//   padding-left: 64px;
		}
		.right {
			position: absolute;
			left: 40%;
			top: 10%;
			right: 0;
			padding-right: 64px;
			.right_card {
				background-color: v.$primary;
				color: #ffffff;
				height: 400px;
				padding: 61px 48px;
				box-sizing: border-box;

				.top_card {
					.first {
						font-family: "Cabinet Grotesk";
						font-size: 44px;
						text-transform: uppercase;
						margin: 0;
					}
					.last {
						font-size: 170px;
						text-transform: uppercase;
						margin: 0;
					}
					.pos {
						margin-top: 10px;
						font-family: "Cabinet Grotesk";
						font-size: 24px;
						font-weight: bold;
						text-transform: capitalize;
					}
				}
				.bottom_card {
					border-top: 1px solid rgba(255, 255, 255, 0.1);
					margin-top: 20px;
					padding-top: 20px;
					display: flex;
					justify-content: space-between;
					p {
						font-family: "Cabinet Grotesk";
						font-size: 20px;
					}
					.item {
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: thin;
						.big {
							font-family: "Gratelos";
							font-size: 98px;
							color: #4b7aee;
						}
					}
				}
			}
		}
	}
	.sticky-player-menu {
		position: -webkit-sticky;
		position: sticky;
		top: v.$header_h;
		z-index: 2;
		.player-menu {
			padding: 24px 0 12px 0;
			display: flex;
			margin-top: 72px;
			justify-content: center;
			background-color: #ffffff;
			color: v.$primary;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			.holder {
				display: flex;
				border-bottom: 2px solid rgba(0, 0, 0, 0.3);
				box-sizing: border-box;
			}
			.tab_item {
				cursor: pointer;
				color: rgba($color: #000000, $alpha: 0.3);
				padding: 0 30px;
				padding-bottom: 10px;
				font-size: 33px;
				text-transform: uppercase;
				transition: all ease-in-out 0.3s;
				border-bottom: 2px solid transparent;
				margin-bottom: -3px;
			}
			.active {
				padding-bottom: 10px;
				color: v.$accent;
				border-bottom: 4px solid v.$primary;
			}
		}
	}

	.content {
		background-color: #ffffff;
		color: v.$accent;
		padding: 80px 64px;
		padding-bottom: 0;
		.player_data {
			.card {
				margin: 30px 0;
				h1 {
					font-size: 50px;
				}
				.data {
					margin-top: 20px;
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					gap: 20px;
				}
			}
		}
	}
	.bottom {
		background-color: v.$primary;
	}
}

@media screen and (max-width: 1280px) {
	.player_details {
		.top {
			.left {
				width: 650px;
			}
			.right {
				.right_card {
					.top_card {
						.last {
							font-size: 130px;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.player_details {
		.top {
			padding: 0;
			display: flex;
			.left {
				width: 50%;
			}
			.right {
				position: static;
				padding-right: 0px;
				width: 50%;
				// margin-left: 24px;
				.right_card {
					padding: 76px 32px;
					height: 100%;
					.top_card {
						.last {
							font-size: 100px;
						}
					}
				}
			}
		}
		.sticky-player-menu {
			.player-menu {
				margin-top: unset;
			}
		}
		.content {
			padding: 80px 24px;
			padding-bottom: 0;
		}
	}
}

@media screen and (max-width: 760px) {
	.player_details {
		.top {
			padding: 0;
			margin-top: -36px;
			flex-direction: column;
			.left {
				width: unset;
			}
			.right {
				width: unset;
				.right_card {
					padding: 24px;
				}
			}
		}
		.sticky-player-menu {
			top: 47px;
			.player-menu {
				padding-top: 16px;
				padding-bottom: 4px;
				.tab_item {
					font-size: 20px;
				}
			}
		}
		.content {
			padding: 40px 24px;
		}
	}
}

@media screen and (max-width: 414px) {
	.player_details {
		.top {
			.right {
				.right_card {
					padding: 16px;
					.top_card {
						.first {
							font-size: 24px;
						}
						.last {
							font-size: 64px;
						}
						.pos {
							font-size: 16px;
						}
					}
					.bottom_card {
						margin-top: 16px;
						padding-top: 16px;
						p {
							font-size: 16px;
						}
						.item {
							.big {
								font-size: 64px;
							}
						}
					}
				}
			}
		}
		.content {
			padding: 24px 16px;
		}
	}
}
