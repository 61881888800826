.schedule-container {
  background-color: #f2f2f2;
  padding: 2px 0;
  padding-bottom: 40px;
  // height: 70vh;
  // overflow: auto;
  // -ms-overflow-style: none;
  // overflow: -moz-scrollbars-none;
  // &::-webkit-scrollbar {
  // 	width: 0 !important;
  // }
  .result-link {
    margin: 32px auto 0px auto;
    width: max-content;
    p {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
      // font-weight: bold;
      color: #000000;
    }
  }
  .click {
    cursor: pointer;
  }
}
.cut {
  margin-top: -80px;
}

@media screen and (max-width: 760px) {
  .schedule-container {
    .result-link {
      p {
        font-size: 16px;
      }
    }
  }
  .cut {
    margin-top: -50px;
  }
}
// @media screen and (max-width: 760px) {
// 	.schedule-container {
// 		padding: 0;
// 	}
// }
