.competitions-container {
  background-color: #ffffff;
  padding: 48px 64px;
  .competitions {
    max-width: 1512px;
    margin: auto;
    h2 {
      font-size: 28px;
      color: #202e55;
      margin-bottom: 36px;
    }
    .write-up {
      display: flex;
      gap: 5vw;
      .competitions-info {
        display: flex;
        width: 50%;
        .league-logo {
          margin-top: -15px;
        }
        h2 {
          font-size: 36px;
          color: #202e55;
          margin-bottom: 16px;
        }
        img {
          width: 138px;
          margin-right: 32px;
        }
        p {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          margin-bottom: 30px;
        }
        a {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          font-weight: bold;
          color: #202e55;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .competitions-container {
    padding: 24px;
    .competitions {
      .write-up {
        flex-direction: column;
        .competitions-info {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .competitions-container {
    .competitions {
      .write-up {
        // gap: 48px;
        .competitions-info {
          margin: 24px 0;
          h2 {
            font-size: 28px;
          }
          img {
            width: 72px;
            margin-right: 16px;
          }
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .competitions-container {
    .competitions {
      .write-up {
        .competitions-info {
          img {
            width: 56px;
          }
        }
      }
    }
  }
}
