.carousel {
  .myswiper {
    width: 100%;
    height: 90vh;
    // bottom: 10vh;
    background: #000000;
    .swiperslide {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.7;
    }
    .card-container {
      position: absolute;
      width: 100%;
      height: auto;
      bottom: 0px;
      padding: 0 64px;
      .card {
        bottom: 0px;
        margin-bottom: 80px;
        left: 64px;
        // position: absolute;
        z-index: 1;
        width: 100%;
        max-width: 860px;
        height: max-content;
        padding: 32px;
        background-color: white;
        border-radius: 8px;
        color: v.$accent;
        box-sizing: content-box;
        h1 {
          // white-space: nowrap;
          font-family: "Gratelos";
          font-size: 84px;
          width: 100%;
          text-transform: uppercase;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .top {
          display: flex;
          align-items: center;

          // gap: 4px;
          .icon {
            font-size: 30px;
            color: v.$primary;
            margin-right: 4px;
          }
          .category {
            font-size: 16px;
            font-family: "Cabinet Grotesk";
            // letter-spacing: -0.001em;
            margin-right: 4px;
            // font-weight: 700;
          }
          .duration {
            font-size: 16px;
            font-family: "Cabinet Grotesk";
            // font-weight: 700;
            // letter-spacing: -0.001em;
            color: rgba(0, 0, 0, 0.4);
            margin-right: 4px;
          }
        }

        .read {
          margin-top: 2vh;
          a {
            font-family: "Gratelos";
            font-size: 26px;
            letter-spacing: -0.002em;
            text-decoration: none;
            color: v.$primary;
          }
        }
      }
    }

    .swiper-pagination {
      text-align: initial !important;
      left: 64px !important;
      bottom: 32px !important;
    }

    .swiper-button-next {
      right: 64px;
    }
    .swiper-button-prev {
      left: 64px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 20px;
      height: 20px;
      &::after {
        font-size: 16px !important;
        background-color: rgba($color: #000000, $alpha: 0.5);
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 12px;
      }
    }

    .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.8);
      width: 10px;
      height: 10px;
    }

    .swiper-pagination-bullet-active {
      background: rgba(255, 255, 255);
    }
  }
}

@media screen and (max-width: 1024px) {
  .carousel {
    .myswiper {
      .card-container {
        display: flex;
        justify-content: center;
        padding: 0 24px;
        width: unset;
        .card {
          width: 100%;
          // left: auto;
          // right: auto;
          // margin-left: 64px;
        }
      }
      .swiper-pagination {
        left: 24px !important;
      }
      .swiper-button-next {
        right: 24px;
      }
      .swiper-button-prev {
        left: 24px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .carousel {
    .myswiper {
      .card-container {
        .card {
          padding: 24px;
          margin-bottom: 60px;
          .top {
            .icon {
              font-size: 24px;
            }
            .category,
            .duration {
              font-size: 14px;
            }
          }
          h1 {
            font-size: 56px;
          }
          .read {
            a {
              font-size: 18px;
            }
          }
        }
      }
      .swiper-pagination {
        text-align: center !important;
        left: 0px !important;
      }
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .carousel {
    .myswiper {
      .card-container {
        padding: 0 16px;
        .card {
          // width: 83%;
          padding: 16px;
          .top {
            .icon {
              font-size: 20px;
            }
            .category,
            .duration {
              font-size: 12px;
            }
          }
          h1 {
            font-size: 40px;
          }
          .read {
            a {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
