// .swiper-slide-prev,
// .swiper-slide-active {
//   margin-right: 16px !important;
// }
.match_card {
  min-width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  color: #202e55;
  height: 270px;
  overflow: hidden;
  // margin-right: -16px;

  .card_top {
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;

    .date {
      display: flex;
      // gap: 10px;
      align-items: center;
      .time {
        display: flex;
        flex-direction: column;
        // letter-spacing: -0.001em;
        color: #202e55;
        margin-top: 2px;
        justify-content: center;
      }
      .matchcard-date {
        font-family: "Gratelos";
        font-size: 40px;
        // letter-spacing: -0.002em;
        color: #202e55;
        text-transform: uppercase;
      }

      .matchcard-time {
        font-family: "Cabinet Grotesk";
        font-size: 14px;
        // font-weight: 700;
        // letter-spacing: -0.001em;
      }
    }

    .date > * + * {
      margin-left: 10px;
    }

    .matchcard-venue {
      font-family: "Cabinet Grotesk";
      font-size: 18px;
      // font-weight: 700;
      text-transform: capitalize;
      // letter-spacing: -0.001em;
    }
  }
  .next {
    background-color: v.$primary;
    color: #ffffff;
    .date {
      .time {
        color: #ffffff;
      }
      .matchcard-date {
        color: #ffffff;
      }
    }
  }
  .yellow {
    background-color: v.$match_next;
    color: v.$primary;

    .date {
      .time {
        color: v.$primary;
      }
      .matchcard-date {
        color: v.$primary;
      }
    }
  }
  .card_body {
    display: flex;
    // align-items: center;
    padding: 24px 15px;
    gap: 20px;
    height: 78px;
    .opp_logo {
      width: 58px;
      height: 58px;
      display: flex;
      margin-top: 2px;
      // align-items: center;
      // justify-content: center;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    .scoreline {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 4px;
      // font-size: 30px;
      .sta {
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        font-family: "Cabinet Grotesk";
        color: rgba(0, 0, 0, 0.5);
      }
      .opponent {
        color: rgba(0, 0, 0, 0.5);
        text-transform: uppercase;
      }
      .opp {
        text-transform: uppercase;
      }
      .rev {
        display: flex;
        flex-direction: column-reverse;
      }
      .cont {
        display: flex;
        gap: 4px;
        font-family: "Gratelos";
        font-size: 32px;
      }
      .score {
        width: 20px;
        text-align: center;
      }

      .line {
        align-self: center;
        display: inline-block;
        margin: 0 10px;
        height: 20px;
        border: 1px solid rgba(0, 0, 0, 0.16);
      }
      .hi {
        margin: 0 12px;
        border: none;
      }
    }

    .league-icon {
      // padding-top: 8px;
      margin-top: 4px;
      height: 56px;
      width: 24px;
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }
  .card_bottom {
    text-align: center;

    p {
      text-align: left;
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-family: "Cabinet Grotesk";
      width: max-content;
      margin: auto;
    }
    .link {
      margin: 15px 0;
      .no_ticket {
        background-color: transparent;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24px;
        font-family: "Gratelos";
        width: max-content;
        display: inline;
        margin: 36px auto;
        text-transform: uppercase;
        padding: 14px 24px;
        cursor: not-allowed;
        border-radius: 26px;
        border: 2px solid rgba(0, 0, 0, 0.5);
      }
      a {
        background-color: transparent;
        color: #202e55;
        text-decoration: none;
        font-size: 24px;
        font-family: "Gratelos";
        padding: 14px 24px;
        border-radius: 26px;
        border: 2px solid #202e55;
        transition: all ease-in-out 0.4s;
        text-transform: uppercase;
        &:hover {
          color: #ffffff;
          background-color: #202e55;
        }
        @media screen and (max-width: 760px) {
          font-size: 16px;
        }
      }
    }
  }
}
.team_name {
  box-orient: vertical;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

@media screen and (max-width: 1280px) {
  .match_card {
    .card_body {
      .scoreline {
        .cont {
          font-size: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .match_card {
    .card_top {
      .date {
        .matchcard-date {
          font-size: 36px;
        }
        .time {
          .matchcard-time {
            font-size: 12px;
          }
        }
      }
      .matchcard-venue {
        font-size: 12px;
      }
    }
    .card_body {
      gap: unset;
      .opp_logo {
        margin-right: 8px;
        // margin-top: 2px;
      }
      .scoreline {
        // margin-top: 8px;
        margin-right: 8px;
        gap: unset;
        .sta {
          font-size: 12px;
          margin-top: 8px;
        }
        .cont {
          font-size: 26px;
          // padding-bottom: 4px;
        }
        .opponent {
          padding-top: 4px;
        }
      }
      .league-icon {
        width: 20px;
      }
    }
    .card_bottom {
      .link {
        a,
        .no_ticket {
          font-size: 16px;
          padding: 12px 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .match_card {
    min-width: unset;
    .card_body {
      .scoreline {
        .cont {
          gap: 0;
        }
      }
      .league-icon {
        display: none;
      }
    }
  }
}
