.hero-content {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
  .img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.7;
    }
  }
  .card-container {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0px;
    padding: 0 64px;
    .card {
      bottom: 0px;
      margin-bottom: 80px;
      left: 64px;
      // position: absolute;
      z-index: 1;
      width: 100%;
      max-width: 860px;
      height: max-content;
      padding: 32px;
      background-color: white;
      border-radius: 8px;
      color: v.$accent;
      box-sizing: content-box;
      .top {
        display: flex;
        align-items: center;
        // gap: 4px;
        .category,
        .duration {
          font-size: 16px;
          font-family: "Cabinet Grotesk";
          letter-spacing: 0.01em;
          font-weight: bold;
          margin-right: 4px;
        }
        .duration {
          color: rgba(0, 0, 0, 0.4);
          margin-right: 4px;
        }
      }
      h1 {
        font-family: "Gratelos";
        font-size: 84px;
        width: 100%;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .read {
        margin-top: 2vh;
        a {
          font-family: "Gratelos";
          font-size: 26px;
          letter-spacing: -0.002em;
          text-decoration: none;
          color: #181f39;
        }
      }
    }
  }
}

.latest-videos {
  background-color: #181f39;
  padding: 48px 0px;
  max-width: 1512px;
  margin: 0 auto;
  .tv-header {
    font-family: "Gratelos";
    // padding: 0 64px;
    color: #ffffff;
    h1 {
      font-size: 56px;
      //   padding: 0 64px;
      color: #ffffff;
    }
  }
}

.content-videos {
  background-color: #f2f2f2;
  .categories {
    // background-color: #ffffff;
    padding: 48px 0px;
    max-width: 1512px;
    margin: 0 auto;
    // background-color: #ffffff;
    .tv-header {
      font-family: "Gratelos";
      // padding: 0 64px;
      h1 {
        text-transform: uppercase;
        font-size: 56px;
        font-family: "Gratelos";
        //   padding: 0 64px;
        color: #202e55;
      }
    }
  }
  .swiper-button-prev {
    color: #202e55;
  }
  .swiper-button-next {
    color: #202e55;
  }

  .slider {
    .details {
      p {
        color: #202e55;
      }

      .date {
        color: rgba($color: #000000, $alpha: 0.5) !important;
      }
    }
  }
}

.tv_carousel {
  margin-top: -45px;
  .swiper-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .swiper {
    padding-right: 0;
  }
  //   .swiper-slide {
  //     margin-right: 30px !important;
  //   }
  .swiper-button-prev {
    margin-right: -46px;
  }
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    &::after {
      font-size: 24px !important;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1500px) {
  .latest-videos {
    .tv-header {
      padding: 0 64px;
    }
  }
  .categories {
    .tv-header {
      padding: 0 64px;
    }
  }
  .tv_carousel {
    .swiper {
      padding: 0 64px;
    }
    .swiper-button-next {
      right: 64px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .hero-content {
    .card-container {
      display: flex;
      justify-content: center;
      padding: 0 24px;
      width: unset;
      .card {
        width: 100%;
      }
    }
  }
  .latest-videos {
    // padding: 48px 24px;
    .tv-header {
      padding: 0 24px;
    }
  }
  .content-videos {
    .categories {
      // padding: 48px 24px;
      .tv-header {
        padding: 0 24px;
      }
    }
  }
  .tv_carousel {
    .swiper {
      padding: 0 24px;
    }
    .swiper-button-next {
      right: 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .hero-content {
    .card-container {
      .card {
        padding: 24px;
        margin-bottom: 60px;
        .top {
          .category,
          .duration {
            font-size: 14px;
          }
        }
        h1 {
          font-size: 56px;
        }
        .read {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .hero-content {
    .card-container {
      padding: 0 16px;
      .card {
        padding: 16px;
        .top {
          .category,
          .duration {
            font-size: 12px;
          }
        }
        h1 {
          font-size: 40px;
        }
        .read {
          a {
            font-size: 16px;
          }
        }
      }
    }
  }
  .latest-videos {
    // padding: 48px 16px;
    .tv-header {
      padding: 0 16px;
      h1 {
        font-size: 36px;
      }
    }
  }
  .content-videos {
    .categories {
      // padding: 24px 16px 0 16px;
      .tv-header {
        padding: 0 16px;
        h1 {
          font-size: 36px;
        }
      }
    }
  }
  .tv_carousel {
    .swiper {
      padding: 0 16px;
    }
    .swiper-button-prev {
      margin-right: -55px;
    }
    .swiper-button-next {
      right: 16px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      margin-top: 7px;
      &::after {
        font-size: 20px !important;
      }
    }
  }
}
