.players-page {
	// margin-top: 150px;
	// color: #ffffff;
	.players-title {
		max-width: 1512px;
		margin: auto;
		margin-top: 146px;
		padding: 0 64px;
		h2 {
			font-size: 56px;
			color: #ffffff;
			margin-bottom: 32px;
		}
		.tabs {
			// padding: 10px 65px;
			display: flex;
			z-index: 1;
			margin-top: 30px;
			// margin-bottom: 10px;
			background-color: v.$primary;
			// gap: 32px;
			p {
				color: #ffffff;
				font-size: 28px;
			}
			.tab_item {
				cursor: pointer;
				padding-bottom: 0px;
				transition: all ease-in-out 0.2s;
				border-bottom: 4px solid v.$primary;
				text-transform: uppercase;
				margin-right: 32px;
			}
			.active {
				border-bottom: 4px solid #ffffff;
			}
		}
	}

	.content {
		background-color: #ffffff;
		color: v.$accent;
		padding-top: 20px;
		.filter {
			position: -webkit-sticky;
			position: sticky;
			top: v.$header_h;
			background-color: #ffffff;
			// width: fit-content;
			justify-content: center;
			padding: 10px 0;
			display: flex;
			gap: 20px;
			z-index: 1;
			margin: auto;
			margin-bottom: -20px;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;
			&::-webkit-scrollbar {
				// width: 0 !important;
				display: none;
			}
			.item {
				background-color: transparent;
				color: #202e55;
				text-decoration: none;
				font-size: 24px;
				font-family: "Gratelos";
				padding: 14px 24px;
				border-radius: 26px;
				border: 2px solid #202e55;
				cursor: pointer;
				text-transform: uppercase;
			}
			.active {
				transition: all ease-in-out 0.3s;
				color: #ffffff;
				background-color: v.$primary;
			}
		}
		.player_data {
			padding: 0 64px;
			padding-bottom: 88px;

			.card {
				// margin: 30px 0;

				.title {
					font-size: 56px;
					text-transform: uppercase;
				}
				.data {
					margin-top: 30px;
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					// grid-template-columns: 320px 320px 320px 320px;
					gap: 20px;
				}
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.players-page {
		.content {
			.player_data {
				.card {
					.data {
						grid-template-columns: repeat(3, 1fr);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.players-page {
		.players-title {
			padding: 0 24px;
		}
		.content {
			.player_data {
				padding: 0 24px;
				padding-bottom: 88px;
				.card {
					.title {
						font-size: 48px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 760px) {
	.players-page {
		.players-title {
			margin-top: 130px;
			h2 {
				font-size: 40px;
				margin-bottom: 16px;
			}
			.tabs {
				margin-top: unset;
				p {
					font-size: 20px;
				}
			}
		}
		.content {
			padding-top: unset;
			.filter {
				overflow: auto;
				top: 47px;
				gap: 4px;
				scrollbar-color: none;
				padding: 10px 16px;
				.item {
					padding: 12px 24px;
					font-size: 16px;
				}
			}
			.player_data {
				.card {
					.title {
						font-size: 36px;
					}
					.data {
						grid-template-columns: repeat(2, 1fr);
						gap: 16px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 414px) {
	.players-page {
		.players-title {
			padding: 0 16px;
		}
		.content {
			.filter {
				justify-content: unset;
				// padding-bottom: 0;
			}
			.player_data {
				padding: 0 16px;
				padding-bottom: 88px;
				.card {
					.data {
						margin-top: 16px;
						gap: 9px;
					}
				}
			}
		}
	}
}
