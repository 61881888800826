.next-match-card-container {
  padding: 0 64px;
  max-width: 1512px;
  margin: auto;
  margin-top: 42px;
  margin-bottom: 42px;
  .next-match-card {
    // background-color: #181f39;
    background-color: #ffffff;
    // display: flex;
    max-width: 1512px;
    margin: auto;
    align-items: center;
    padding: 32px 24px;
    border-radius: 8px;
    border: 2px solid rgba(89, 193, 80, 0.3);
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      p {
        // color: #ffffff;
        color: rgba(0, 0, 0, 0.7);
        font-family: "Cabinet Grotesk";
        font-size: 16px;
      }
    }
    .match-detail {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3vw;
      .host-name,
      .opponent-name {
        width: 386px;
      }
      .host-name {
        text-align: right;
        // color: #ffffff;
        font-size: 44px;
        width: 100%;
      }
      .opponent-name {
        // color: #ffffff;
        font-size: 44px;
        text-align: left;
        width: 100%;
      }
      .versus {
        display: flex;
        width: 100%;
        justify-content: center;
        .host-logo,
        .opponent-logo {
          display: flex;
          width: 100%;
          text-align: center;
          align-items: center;
          gap: 16px;
          img {
            height: 130px;
            width: 130px;
            margin: 0 auto;
          }
          .img {
            width: 130px;
          }
        }
        .host-logo {
          gap: 0px;
        }
        .match-time-and-venue {
          text-align: center;
          min-width: 143px;
          margin: auto 32px;
          .matchcard-time {
            font-size: 64px;
            // color: #ffffff;
          }
          .matchcard-date {
            font-family: "Cabinet Grotesk";
            font-size: 20px;
            // font-weight: 700;
            // color: #ffffff;
            color: rgba(0, 0, 0, 0.7);
          }
          .matchcard-venue {
            font-family: "Cabinet Grotesk";
            font-size: 14px;
            // font-weight: 700;
            // color: #ffffff;
            color: rgba(0, 0, 0, 0.7);
          }
        }
      }
    }
    .count {
      //   flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //   font-size: 20px;
      gap: 16px;
      color: #59c150;
      margin: 48px 0;
      span {
        margin-top: -24px;
        font-size: 48px;
        font-family: "Gratelos";
        color: #59c150;
      }
      .time {
        font-size: 64px;
        font-family: "Gratelos";
        display: flex;
        flex-direction: column;
        gap: 0;
        span {
          align-self: center;
          font-size: 16px;
          font-family: "Cabinet Grotesk";
          margin-top: 4px;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }
    .ticket {
      text-align: center;
      margin-top: 48px;
      margin-bottom: 32px;
      .link {
        a {
          background-color: #ffffff;
          color: v.$accent;
          font-size: 24px;
          font-family: "Gratelos";
          white-space: nowrap;
          text-overflow: ellipsis;
          text-decoration: none;
          padding: 15px 20px;
          border: 2px solid v.$accent;
          border-radius: 26px;
          text-transform: uppercase;
          transition: all ease-in-out 0.4s;
        }

        :hover {
          background-color: v.$accent;
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .next-match-card-container {
    padding: 0 24px;
    .next-match-card {
      .match-detail {
        .host-name,
        .opponent-name {
          text-align: center;
          width: 100%;
        }
        .versus {
          width: 100%;
          .match-time-and-venue {
            margin: 24px 8px;
            margin-bottom: auto;
          }
          .host-logo {
            flex-direction: column-reverse;
            flex-grow: 2;
            gap: 16px;
          }
          .opponent-logo {
            flex-direction: column;
            flex-grow: 2;
          }
          .host-logo,
          .opponent-logo {
            width: 130px;
          }
        }
      }
      .ticket {
        margin-top: 32px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .next-match-card-container {
    .next-match-card {
      .match-detail {
        .host-name,
        .opponent-name {
          font-size: 18px;
        }
        .versus {
          .match-time-and-venue {
            min-width: 112px;
            margin: 12px 8px;
            margin-bottom: auto;
            .matchcard-time {
              font-size: 40px;
              margin-bottom: 4px;
            }
            .matchcard-date {
              font-size: 14px;
              margin-bottom: 4px;
              font-weight: 400;
            }
            .matchcard-venue {
              font-size: 10px;
              font-weight: 400;
            }
          }
          .host-logo {
            .img {
              width: 90px;
            }
          }
          .host-logo,
          .opponent-logo {
            width: 90px;
            flex-grow: 1;
            gap: unset;
            img {
              width: 90px;
              height: 90px;
              margin-bottom: 16px;
            }
          }
        }
      }
      .count {
        margin-top: 30px;
      }

      .ticket {
        .link {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .next-match-card-container {
    padding: 0 16px;
    .next-match-card {
      padding: 16px 8px;
      .top {
        p {
          font-size: 14px;
        }
      }
      .match-detail {
        .versus {
          .match-time-and-venue {
            margin: 2px 8px;
            margin-bottom: auto;
          }
          .host-logo {
            .img {
              width: 72px;
            }
          }
          .host-logo,
          .opponent-logo {
            img {
              width: 72px;
              height: 72px;
            }
          }
        }
      }
      .count {
        gap: 8px;
        .time {
          font-size: 48px;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
