.reset {
  background-color: #ffffff;
  margin-top: v.$header_h;
  padding: 15vh 0;

  .reset_container {
    font-family: "Cabinet Grotesk";
    margin: 0 auto;
    width: 50vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    .back {
      font-size: 35px;
      color: v.$accent;
      width: fit-content;
    }
    .logo_container {
      margin: 10px auto;
      margin-bottom: 40px;
      font-size: 10em;
      width: fit-content;
      h1 {
        font-size: 30px;
        font-family: "Cabinet Grotesk";
      }
    }
    .title {
      font-size: 56px;
      margin-bottom: 30px;
      color: #202e55;
      text-transform: uppercase;
      text-align: center;
      // font-family: "Cabinet Grotesk";
      span {
        cursor: pointer;
        color: red;
        text-decoration: underline;
      }
    }
    .sub_title {
      margin-bottom: 20px;
      font-family: "Cabinet Grotesk";
      font-size: 16px;
      text-align: center;
    }
    form {
      width: 100%;
      .fields {
        margin-top: 24px;
        margin-bottom: 40px;
        .input_container {
          border: 2px solid #808080;
          border-radius: 8px;
          margin-bottom: 16px;
          padding: 20px 10px;
          .form-control {
            //   padding: 20px 10px;
            width: 100%;
            //   margin: 20px 0;
            font-size: 20px;
            outline: none;
            font-family: "Cabinet Grotesk";
            border: none;
            border-radius: 8px;
            //   border-bottom: 1px solid #808080;
          }
        }
      }
      .form-control-btn {
        width: 100%;
        height: 60px;
        display: block;
        margin: 20px auto;
        font-size: 20px;
        cursor: pointer;
        outline: none;
        font-family: "Cabinet Grotesk";
        border: none;
        border-radius: 8px;
        background-color: v.$accent;
        color: #ffffff;
        border-bottom: 1px solid #808080;
        transition: all ease-in-out 0.3s;
        box-sizing: border-box;
      }
    }

    // .space {
    // 	margin: 50px 0;
    // 	.btn_reset {
    // 		width: 150px;
    // 		height: 60px;
    // 		background: #0d556b;
    // 		border: 1px solid rgba(13, 85, 107, 0.3);
    // 		border-radius: 10px;
    // 		color: #ffffff;
    // 		font-size: large;
    // 		cursor: pointer;
    // 	}
    // }
  }
}

@media screen and (max-width: 760px) {
  .reset {
    .reset_container {
      width: 90vw;
    }
  }
}

@media screen and (max-width: 414px) {
  .reset {
    .reset_container {
      .sub_title {
        font-size: 14px;
      }
      .form {
        .fields {
          .input_container {
            .form-control {
              font-size: 16px;
            }
          }
        }
        .form-control-btn {
          font-size: 16px;
        }
      }
    }
  }
}
