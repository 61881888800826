.signup {
	background-color: #ffffff;
	.hero {
		background-color: #000000;
		overflow: hidden;
		height: 30vh;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			opacity: 0.7;
		}
		margin-bottom: 50px;
	}
	.form {
		width: 50vw;
		margin: auto;
		padding: 50px 0;
		h1 {
			font-size: 56px;
			margin-bottom: 30px;
			color: #202e55;
			text-transform: uppercase;
			text-align: center;
		}
		.subtitle {
			font-family: "Cabinet Grotesk";
			font-size: 16px;
			text-align: center;
		}
		.fields {
			margin-top: 24px;
			margin-bottom: 40px;
			.input_container {
				border: 2px solid #808080;
				border-radius: 8px;
				margin-bottom: 16px;
				padding: 20px 10px;

				.form-control {
					//   padding: 20px 10px;
					width: 100%;
					//   margin: 20px 0;
					font-size: 20px;
					outline: none;
					font-family: "Cabinet Grotesk";
					border: none;
					border-radius: 8px;
					//   border-bottom: 1px solid #808080;
				}
				.form-control-2 {
					width: 100%;
					//   margin: 20px 0;
					font-size: 20px;
					outline: none;
					font-family: "Cabinet Grotesk";
					border: none;
					border-radius: 8px;
					//   border-bottom: 1px solid #808080;
				}

				.password {
					//   width: 102%;
					//   border-bottom: 1px solid #808080;
					display: flex;
					input {
						// padding: 20px 10px;
						// font-size: 20px;
						// outline: none;
						// width: 93%;
						// font-family: "Cabinet Grotesk";
						// border: none;
					}
					span {
						align-self: center;
					}
				}
				.eyes {
					cursor: pointer;
					font-size: 24px;
				}
			}
		}
		.form-control-btn {
			padding: 20px 10px;
			width: 100%;
			color: #ffffff;
			cursor: pointer;
			background-color: v.$accent;
			height: 70px;
			margin: 20px auto;
			font-size: 20px;
			display: block;
			outline: none;
			font-family: "Cabinet Grotesk";
			border: none;
			border-radius: 8px;
			margin-top: 24px;
		}
		.select {
			width: 102%;
		}
		.password {
			//   width: 103%;
			//   border-bottom: 1px solid #808080;
			display: flex;
			input {
				// padding: 20px 10px;
				font-size: 20px;
				outline: none;
				width: 93%;
				font-family: "Cabinet Grotesk";
				border: none;
			}
			span {
				align-self: center;
			}
		}
		.eyes {
			cursor: pointer;
			font-size: 18px;
		}
		.or {
			text-align: center;
			font-size: 20px;
			font-family: "Cabinet Grotesk";
		}
		.google_container {
			border: 1px solid #4285f4;
			width: max-content;
			margin: 20px auto;
			//   padding: 10px 20px;
			border-radius: 8px;
			cursor: pointer;
			background-color: #4285f4;
			overflow: hidden;
			.google {
				display: flex;
				align-items: center;
				font-size: 20px;
				// column-gap: 20px;

				.g_icon {
					//   margin-top: 6px;
					background-color: #ffffff;
					width: 56px;
					height: 56px;
					font-size: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				span {
					p {
						font-family: system-ui, -apple-system, BlinkMacSystemFont,
							"Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
							"Helvetica Neue", sans-serif;
						font-weight: 700;
						color: #ffffff;
						margin-right: 50px;
						margin-left: 50px;
					}
				}
			}
		}
		.account {
			width: max-content;
			margin: 20px auto;
			padding: 10px 20px;
			border-radius: 20px;

			.google {
				font-family: "Cabinet Grotesk";
				font-size: 20px;

				a {
					font-family: "Cabinet Grotesk";
					text-decoration: none;
					color: v.$accent;
					text-decoration: underline;
				}
			}
		}
	}
}
.label {
	font-family: "Cabinet Grotesk";
	font-size: 18px;
	display: block;
	margin: 10px 5px;
}

@media screen and (max-width: 760px) {
	.signup {
		.form {
			width: 90vw;
		}
	}
}

@media screen and (max-width: 414px) {
	.signup {
		.form {
			.subtitle {
				font-size: 14px;
			}
			.fields {
				.input_container {
					.form-control,
					.form-control-2 {
						font-size: 16px;
					}
				}
			}
			.form-control-btn {
				font-size: 16px;
			}
			.google_container {
				.google {
					font-size: 16px;
					.g_icon {
						font-size: 32px;
					}
					span {
						p {
							margin-right: 20px;
							margin-left: 20px;
						}
					}
				}
			}
			.account {
				.google {
					font-size: 16px;
				}
			}
		}
	}
}
