.news_display {
  .content {
    background-color: #ffffff;
    padding: 30px 23%;
    .desc {
      // margin: 32px auto;
      width: fit-content;
      font-family: "Cabinet Grotesk";
      margin: 16px auto;
      font-size: 12px;
      text-align: center;
      font-style: italic;
    }
    .intro {
      p,
      h1,
      h2,
      h3 {
        font-size: 30px;
      }
    }
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 32px;
      color: initial;
      font-family: "Cabinet Grotesk";
      // font-size: 20px;
      a {
        font-family: "Cabinet Grotesk";
        text-decoration: none;
        font-weight: 800;
        color: #4b7aee;
        font-size: 20px;
      }
    }

    h2 {
      margin-top: 64px;
      b {
        font-family: "Gratelos";
        text-transform: uppercase;
        color: v.$accent;
        font-size: 48px;
        font-weight: normal;

        @media screen and (max-width: 760px) {
          font-size: 32px;
        }
      }
    }

    p {
      font-size: 20px;
      white-space: break-spaces;
    }
    h6 {
      font-size: 24px;
      font-weight: 700px;
      white-space: break-spaces;
    }
    h5 {
      font-size: 36px;
      // font-weight: 700px;
      font-family: "Gratelos";
      text-transform: uppercase;
    }
    .asset_image {
      width: 80%;
      display: block;
      margin: 32px auto;
      margin-bottom: 16px;
      border-radius: 8px;
    }
    .video-react {
      margin: 32px auto;
      background-color: transparent;
    }
    .video-react > .video-react-poster {
      background-size: cover;
      border-radius: 8px;
    }
    .video-react-big-play-button {
      border-radius: 16px;
      //   padding: 12px 24px;
      background: rgba($color: #202e55, $alpha: 0.8);
      border: none;
      height: 56px;
      color: #ffffff;
    }
    .video-react-big-play-button:before {
      position: static;
      width: 100%;
      margin: auto;
    }
    i,
    b,
    u {
      font-family: "Cabinet Grotesk";
    }
    b {
      font-weight: bold;
    }
  }

  .more_new {
    background-color: #f2f2f2;
    padding: 30px 64px;
    .news {
      .news_container {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, minmax(250px, 1fr));
        gap: 20px;
        .items {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }
      .link {
        width: fit-content;
        margin: auto;
        margin-top: 64px;
        margin-bottom: 24px;
        a {
          background-color: #202e55;
          color: #ffffff;
          white-space: nowrap;
          font-size: 24px;
          font-family: "Gratelos";
          text-decoration: none;
          padding: 14px 24px;
          border-radius: 26px;
        }
      }
    }
    .news-title {
      h1 {
        text-transform: none;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .news_display {
    .content {
      padding: 30px 12%;
    }
    .more_new {
      padding: 30px 24px;
      .news {
        .news_container {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .news_display {
    .content {
      padding: 30px 24px;
      h6 {
        font-size: 20px;
        white-space: break-spaces;
      }
      h5 {
        margin-bottom: 8px;
      }
      .intro {
        p {
          font-size: 24px;
        }
      }
    }
    .more_new {
      padding: 30px 24px;
      .news {
        .section_title {
          font-size: 32px;
        }
        .news_container {
          grid-template-columns: repeat(1, 1fr);
          .items {
            height: 250px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .news_display {
    .content {
      padding: 30px 16px;
      h5 {
        font-size: 24px;
      }
      h6 {
        font-size: 16px;
        white-space: break-spaces;
      }
      .intro {
        p,
        h1,
        h2,
        h3 {
          font-size: 20px;
        }
      }
      p {
        font-size: 16px;
      }
      .asset_image {
        width: 100%;
        margin-bottom: 16px;
      }
      .desc {
        font-family: "Cabinet Grotesk";
        margin-top: 16px;
        font-size: 12px;
        text-align: center;
        font-style: italic;
      }
    }
    .more_new {
      padding: 30px 16px;
      .news {
        .news_container {
          grid-template-columns: repeat(1, 1fr);
          .items {
            height: 250px;
          }
        }
        .link {
          a {
            padding: 12px 24px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
