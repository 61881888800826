.news-menu {
  background-color: #ffffff;
  position: sticky;
  position: -webkit-sticky;
  top: v.$header_h;
  z-index: 2;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);

  .news-menu-container {
    height: 50px;
    max-width: 1512px;
    margin: auto;
    padding: 0 64px;
    @media screen and (max-width: 414px) {
      height: 44px;
    }

    ul {
      display: flex;
      // gap: 32px;
      align-items: center;
      height: 100%;
      list-style: none;
      overflow: auto;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }

      li {
        // margin-right: 32px;
        .tab {
          color: v.$accent;
          font-family: "Cabinet Grotesk";
          font-size: 20px;
          font-weight: bold;
          list-style: none;
          text-transform: capitalize;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          padding-bottom: 3px;
          transition: all ease-in-out 0.2s;
          white-space: nowrap;
          @media screen and (max-width: 550px) {
            font-size: 18px;
          }
          @media screen and (max-width: 414px) {
            font-size: 16px;
          }
        }
        .active {
          transition: all ease-in-out 0.2s;
          border-bottom: 2px solid v.$accent;
        }
      }
    }
    ul > * + * {
      margin-left: 32px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .news-menu {
    .news-menu-container {
      padding: 0 0px;
      ul {
        padding: 0 24px;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .news-menu {
    top: 47px;
  }
}

@media screen and (max-width: 414px) {
  .news-menu {
    .news-menu-container {
      padding: 0 0;
      ul {
        padding: 0 16px;
        padding-top: 8px;

        .tab {
          font-size: 16px;
        }
      }
    }
  }
}
