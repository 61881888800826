.tv_carousel {
  max-width: 1512px;
  margin: 0 auto;
  margin-top: -45px;
  .swiper {
    // height: fit-content;
    // min-height: 388px;
    height: max-content;
    overflow: hidden;

    a {
      text-decoration: none;
      &:hover > .slider > .img_cont > .play {
        width: 34px;
      }
      .slider {
        color: #ffffff;
        .img_cont {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          // height: 250px;
          .tv_image {
            width: 100%;
            border-radius: 8px;
            object-fit: cover;
            height: 250px;
            @media screen and (max-width: 1024px) {
              height: 180px;
            }
            @media screen and (max-width: 760px) {
              height: 150px;
            }
            @media screen and (max-width: 414px) {
              height: 120px;
            }
          }
          .play {
            position: absolute;
            z-index: 1;
            // top: 45%;
            // left: 45%;
            width: 32px;
            transition: all ease-in-out 0.2s;
          }
        }
        .details {
          margin-top: 20px;
          p {
            font-family: "Cabinet Grotesk";
            font-size: 20px;
            // font-weight: bold;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
          }

          .date {
            margin-top: 5px;
            font-family: "Cabinet Grotesk";
            font-size: 16px;
            color: rgba($color: #ffffff, $alpha: 0.5);
          }
        }
      }
    }
  }

  .swiper-pagination {
    top: 95%;
    bottom: 0 !important;
  }
  .swiper-pagination-bullet {
    background: gold;
    display: none;
  }
  .swiper-pagination-bullet-active {
    background: #ffffff;
    display: none;
  }

  .swiper-wrapper {
    margin-left: 64px !important;
    margin-right: 64px !important;
    margin-top: 75px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 0%;
    margin-top: 0;
  }

  .swiper-button-prev {
    right: 122px;
    font-size: 32px !important;
    font-weight: bold;
    left: auto;
  }

  .swiper-button-next {
    right: 72px;
    font-size: 32px !important;
    font-weight: bold;
  }
}

@media screen and (max-width: 1500px) {
  .tv_carousel {
    .swiper-button-prev {
      right: 186px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tv_carousel {
    .swiper-button-prev {
      right: 146px;
    }
  }
}

@media screen and (max-width: 414px) {
  .tv_carousel {
    .swiper {
      a {
        .slider {
          .details {
            margin-top: 12px;
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
    .swiper-button-prev {
      left: auto;
      right: 130px;
    }
  }
}
