.shophome {
  background-color: #ffffff;
  overflow: hidden;
  .shop_top {
    width: 100%;
    max-height: 90vh;
    object-fit: cover;
    position: relative;
    background-color: #000000;
      // margin-top: 40px;
      align-items: center;
      display: flex;
      justify-content: center;

    overflow: hidden;
    .img {
      img {
        width: 100%;
        opacity: 0.7;
      }
    }

    .overlay {
      position: absolute;
      bottom: 30%;
      color: #f1f1f1;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        font-size: 220px;
        text-transform: uppercase;
        margin-bottom: 40px;
      }
      .apply {
        a {
          background-color: #202e55;
          color: #ffffff;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          border: 2px solid #202e55;
          transition: all ease-in-out 0.2s;
          transition-delay: 0.3s;
        }

        :hover {
          background-color: #ffffff;
          color: #202e55;
          border: 1px solid #ffffff;
        }
      }
    }
  }

  .content-container {
    background-color: #ffffff;
    padding: 48px 64px;
    max-width: 1512px;
    margin: 0 auto;
    .shop-kits {
      background-color: white;
      padding: 64px;
      h1 {
        font-size: 84px;
        text-align: center;
        text-transform: uppercase;
        color: #202e55;
      }
    }
    .kits-menu {
      margin-bottom: 20px;
      padding-bottom: 0px;
      width: fit-content;
      margin: auto;
      border-bottom: 1.5px solid rgba($color: #ffffff, $alpha: 0.3);
      //   border-bottom: none;
      // height: 100%;
      .menu-list {
        display: flex;
        justify-content: center;
        gap: 40px;
        width: max-content;
        height: auto;
        margin-bottom: 15px;
        overflow: auto;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        .focus {
          border-bottom: 3px solid rgb(0, 0, 0);
          z-index: 2;
        }
        .list-item {
          list-style-type: none;
          cursor: pointer;

          p {
            padding-bottom: 13px;
            font-family: "Cabinet Grotesk";
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            color: rgba($color: #000000, $alpha: 0.3);
            letter-spacing: 0.2em;
            text-align: center;
            white-space: nowrap;
          }
        }
      }
    }
    .asection {
      margin: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .products-section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
      margin-top: 35px;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      padding: 0 64px;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .buy {
        padding-bottom: 40px;
      }
      .product {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        .product-image {
          background-color: #fafafa;
          display: flex;
          justify-content: center;
          width: 100%;
          border-radius: 8px;
          padding: 16px 0;
          align-items: center;
          .img {
            .shop_card_carousel {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                // height: 90%;
                object-fit: cover;
              }
            }
            .swiper-pagination {
              display: block !important;
              bottom: 0 !important;
            }
            .swiper-pagination-bullet-active {
              background: v.$accent !important;
            }
          }
        }
        .product-details {
          text-align: center;
          margin-top: 30px;
          .product-name {
            font-family: "Cabinet Grotesk";
            font-size: 24px;
            margin-bottom: 16px;
          }
          .price-tag {
            font-family: "Cabinet Grotesk";
            font-size: 24px;
            font-weight: bold;
          }
          .buy_container {
            margin-top: 20px;

            .buy {
              background-color: v.$accent;
              color: #ffffff;
              padding: 10px 20px;
              border-radius: 20px;
              font-size: 20px;
              white-space: nowrap;
              text-decoration: none;
            }
          }
        }
      }
    }
    .all-products {
      margin-top: 42px;
      margin-bottom: 20px;
      text-align: center;
      a {
        background-color: #202e55;
        color: #ffffff;
        text-decoration: none;
        font-size: 24px;
        font-family: "Gratelos";
        padding: 14px 24px;
        border-radius: 26px;
        border: 2px solid #202e55;
      }

      :hover {
        background-color: transparent;
        color: #202e55;
      }
    }
  }
  .homekit-promo {
    // padding: 0 64px;
    padding-left: 450px;
    position: relative;
    // max-width: 1512px;
    // margin: auto;
    height: fit-content;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    .homekit-image {
      width: 533px;
      height: 533px;
      overflow: hidden;
      position: absolute;
      left: 5%;
      z-index: 2;
      display: flex;
      justify-content: center;
      .img {
        height: 100%;
      }
    }
    .homekit-description {
      background-color: #4b7aee;
      // position: absolute;
      padding: 137px 30%;
      top: 0;
      right: 0;
      left: 40%;
      // z-index: -1;
      height: 401px;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .subtitle {
        font-family: "Cabinet Grotesk";
        font-size: 16px;
        letter-spacing: 0.2em;
        margin-bottom: 9px;
      }
      .title {
        font-size: 72px;
        text-transform: uppercase;
        margin-bottom: 42px;
      }
      .body-text {
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        // width: 50%;
        // margin: auto;
      }
      .all-home-kits {
        margin-top: 72px;
        text-align: center;
        a {
          background-color: #ffffff;
          color: #202e55;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          // border: 2px solid #202e55;
        }

        :hover {
          background-color: #f2f2f2;
          color: #202e55;
        }
      }
    }
  }

  .new-in {
    width: 100%;
    max-height: 65vh;
    // object-fit: cover;
    position: relative;
    // margin-top: 92px;
    // margin-bottom: 92px;
    display: flex;
    overflow: hidden;
    background-color: #000000;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    .cta {
      background-color: #000000;
      padding: 56px;
      .host-logo {
        min-width: 130px;
        text-align: center;
        margin-bottom: 64px;
        img {
          height: 130px;
        }
      }
      p {
        width: 90%;
        margin: auto;
        font-family: "Cabinet Grotesk";
        font-size: 24px;
        text-align: center;
        color: #ffffff;
      }

      .link {
        margin: 46px auto;
        width: fit-content;
        color: #ffffff;
        background-color: transparent;
        font-family: "Gratelos";
        font-size: 24px;
        text-decoration: none;
        letter-spacing: 0;
        padding: 8px 24px;
        border-radius: 26px;
        border: 2px solid #ffffff;

        a,
        .paystack_button {
          cursor: pointer;
          display: flex;
          align-items: center;
          width: fit-content;
          background-color: transparent;
          color: #ffffff;
          font-family: "Gratelos";
          font-size: 24px;
          text-decoration: none;
          letter-spacing: 0;
          padding: 8px 24px;
          // padding-left: 36px;
          border-radius: 26px;
          border: 2px solid #ffffff;
          span {
            font-size: 24px;
            margin-top: 4px;
          }
        }
      }
    }

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        width: 100%;
        opacity: 0.7;
      }
    }

    .overlay {
      position: absolute;
      // bottom: 10%;
      color: #f1f1f1;
      width: 100%;
      // height: 100%;
      box-sizing: border-box;
      padding: 0 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .subtitle {
        font-family: "Cabinet Grotesk";
        letter-spacing: 0.2em;
        margin-bottom: 9px;
      }
      h2 {
        font-size: 72px;
        text-transform: uppercase;
        margin-bottom: 40px;
      }
      .apply {
        a,
        .ext-button {
          background-color: #202e55;
          color: #ffffff;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          border: 2px solid #202e55;
          transition: all ease-in-out 0.2s;
          transition-delay: 0.3s;
          width: max-content;
        }

        :hover {
          background-color: #ffffff;
          color: #202e55;
          border: 1px solid #ffffff;
        }
      }
    }
  }

  .subtitlee {
    font-family: "Gratelos";
  color: #202e55;
    font-size: 56px;
    // letter-spacing: 0.2em;
    margin-bottom: 9px;
    text-align: center;
  }

  .awaykit-promo {
    // padding: 0 64px;
    padding-right: 450px;
    position: relative;
    // max-width: 1512px;
    // margin: auto;
    height: fit-content;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .homekit-image {
      width: 533px;
      height: 533px;
      overflow: hidden;
      position: absolute;
      right: 5%;
      z-index: 2;
      display: flex;
      justify-content: center;
      .img {
        height: 100%;
      }
    }
    .awaykit-description {
      background-color: #59c150;
      // position: absolute;
      padding: 137px 30%;
      top: 0;
      right: 40%;
      left: 0%;
      // z-index: -1;
      height: 401px;
      text-align: center;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .subtitle {
        font-family: "Cabinet Grotesk";
        font-size: 16px;
        letter-spacing: 0.2em;
        margin-bottom: 9px;
      }
      .title {
        font-size: 72px;
        text-transform: uppercase;
        margin-bottom: 42px;
      }
      .body-text {
        font-family: "Cabinet Grotesk";
        font-size: 20px;
        // width: 50%;
        // margin: auto;
      }
      .all-home-kits {
        margin-top: 72px;
        text-align: center;
        a {
          background-color: #ffffff;
          color: #202e55;
          text-decoration: none;
          font-size: 24px;
          font-family: "Gratelos";
          padding: 14px 24px;
          border-radius: 26px;
          // border: 2px solid #202e55;
        }

        :hover {
          background-color: #f2f2f2;
          color: #202e55;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .shophome {
    .content-container {
      padding: 48px 0;
    }
  }
}

@media screen and (max-width: 1300px) {
  .shophome {
    .homekit-promo,
    .awaykit-promo {
      .homekit-image {
        width: 480px;
        height: 480px;
      }
    }
    .homekit-promo {
      .homekit-description {
        padding: 137px 20%;
      }
    }
    .awaykit-promo {
      .awaykit-description {
        padding: 137px 20%;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .shophome {
    .shop_top {
      .overlay {
        padding: 0 24px;
        h2 {
          font-size: 90px;
        }
      }
    }
    .content-container {
      //   padding: 48px 24px;
      .shop-kits {
        padding: 24px 48px;
        padding-top: 16px;
        h1 {
          font-size: 64px;
        }
      }
      .products-section {
        overflow: auto;
        padding: 0 24px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
    .homekit-promo {
      flex-direction: column;
      padding-left: unset;
      margin-top: 48px;
      .homekit-image {
        left: unset;
      }
      .homekit-description {
        margin-top: 380px;
      }
    }
    .awaykit-promo {
      flex-direction: column;
      padding-right: unset;
      margin-top: 48px;
      .homekit-image {
        right: unset;
      }
      .awaykit-description {
        margin-top: 380px;
      }
    }
    .new-in {
      .overlay {
        padding: 0 24px;
      }
    }
  }
}

@media screen and (max-width: 904px) {
  .shophome {
    .content-container {
      .kits-menu {
        width: 100%;
        height: 100%;
        overflow: auto;

        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
        border-bottom: none;
        .menu-list {
          margin: 8px 24px;
          .list-item {
            p {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .shophome {
    .shop_top {
      
      .overlay {
        h2 {
          font-size: 64px;
        }
      }
    }
    .content-container {
      .shop-kits {
        h1 {
          font-size: 56px;
        }
      }
      .kits-menu {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-bottom: 0;
        .menu-list {
          margin: 8px 24px;
          .list-item {
            p {
              white-space: nowrap;
            }
          }
        }
      }
      .products-section {
        overflow: auto;
        grid-template-columns: repeat(2, 1fr);
        .product {
          .product-details {
            .product-name {
              font-size: 20px;
            }
          }
        }
      }
      .all-products {
        a {
          font-size: 16px;
          padding: 12px 24px;
        }
      }
    }
    .homekit-promo,
    .awaykit-promo {
      .homekit-image {
        width: 380px;
        height: 380px;
      }
    }
    .homekit-promo {
      .homekit-description {
        margin-top: 280px;
        padding: 137px 10%;
        .title {
          font-size: 56px;
        }
        .all-home-kits {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
    .awaykit-promo {
      .awaykit-description {
        margin-top: 280px;
        .title {
          font-size: 56px;
        }
        .all-home-kits {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
    .new-in {
      max-height: unset;
      height: max-content;
      .overlay {
        h2 {
          font-size: 56px;
        }
        .apply {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
  }
}



@media screen and (max-width: 414px) {
  .shophome {
    .shop_top {
      // max-height: 50vh;
      .overlay {
        padding: 0 16px;
        h2 {
          font-size: 40px;
        }
        .apply {
          a {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
    .content-container {
      // height: 100vh;
      .kits-menu {
        padding: 8px 0;
        padding-bottom: 0;
        .menu-list {
          margin: 8px 16px;
        }
      }
      .products-section {
        grid-template-columns: 1fr;
        gap: 64px;
        // margin-bottom: 20px;
      }
      .shop-kits {
        h1 {
          font-size: 40px;
        }
      }
    }
    .homekit-promo,
    .awaykit-promo {
      .homekit-image {
        width: 280px;
        height: 280px;
      }
    }
    .homekit-promo {
      .homekit-description {
        margin-top: 220px;
        padding: 56px 10%;
        .title {
          font-size: 36px;
        }
        .body-text {
          font-size: 16px;
        }
      }
    }
    .awaykit-promo {
      .awaykit-description {
        margin-top: 220px;
        padding: 56px 10%;
        .title {
          font-size: 36px;
        }
        .body-text {
          font-size: 16px;
        }
      }
    }
    .new-in {
      .overlay {
        padding: 0 16px;
        h2 {
          font-size: 36px;
        }
        .apply {
          .ext-button {
            font-size: 16px;
          }
        }
      }
      .cta {
        padding: 56px 24px;
        p {
          width: unset;
          font-size: 20px;
        }
        .link {
          font-size: 16px;
        }
      }
    }
  }
}
