.landing_news {
  background-color: #f2f2f2;
  // padding: 30px 0;
  // margin-top: -72px;
  border: none;
  a {
    text-decoration: none;
  }
  .news {
    background-color: #f2f2f2;
    padding: 40px 64px;
    max-width: 1512px;
    margin: auto;

    h1 {
      margin-bottom: 30px;
    }
    .news_container {
      display: grid;
      grid-template-areas:
        "o th th th f f"
        "t th th th fi fi";
      grid-gap: 16px;
      grid-auto-rows: 278px;
      @media screen and (max-width: 1100px) {
        grid-template-areas:
          "th th th"
          "th th th"
          "th th th"
          "o o o"
          "f f f"
          "fi fi fi"
          "t t t";
        grid-auto-rows: 150px;
      }
      .item1 {
        grid-area: o;
      }
      .item2 {
        grid-area: t;
      }
      .item3 {
        grid-area: th;
      }
      .item4 {
        grid-area: f;
      }
      .item5 {
        grid-area: fi;
      }
      .item6 {
        // grid-area: s;
        display: none;
      }
      .items {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          //   border-radius: 8px;
        }
        .yes {
          @media screen and (max-width: 1100px) {
            color: v.$accent;
            // position: relative;
            width: 62%;
            .duration {
              color: rgba($color: #000000, $alpha: 0.4);
            }
            .news-title {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
      .news-title {
        h1 {
          text-transform: none;
        }
      }
    }
    .link {
      margin: 5vh auto 30px auto;
      width: max-content;
      a {
        background-color: v.$accent;
        color: #ffffff;
        font-size: 24px;
        font-family: "Gratelos";
        text-decoration: none;
        padding: 14px 24px;
        border-radius: 26px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .landing_news {
    .news {
      padding: 40px 24px;
    }
  }
}

@media screen and (max-width: 760px) {
  .landing_news {
    .news {
      padding: 40px 16px;
      .link {
        a {
          font-size: 16px;
          padding: 12px 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .landing_news {
    .news {
      .news_container {
        grid-auto-rows: 120px;
        .news-title {
          h1 {
            font-size: 26px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .landing_news {
    .news {
      .news_container {
        grid-auto-rows: 90px;
      }
    }
  }
}
