.player_profile {
  display: grid;
  grid-template-columns: 1fr 0 1fr;
  gap: 72px;
  max-width: 1512px;
  //   height: 100%;
  margin: auto;
  .title {
    font-size: 72px;
    // margin-bottom: 24px;
  }
  .left {
    // height: 50%;
    // border-right: 1px solid;
    // padding-right: 72px;
    .bio {
      //   height: 75%;
      height: 400px;
      overflow: hidden;
      transition: all ease-in-out 0.3s;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 90%
        );
        width: 100%;
        height: 4em;
      }
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      b,
      i,
      u {
        margin: 32px 0;
        color: initial;
        font-family: "Cabinet Grotesk";
      }
    }
    p {
      font-size: 28px;
    }
    .bio_more {
      transition: all ease-in-out 0.3s;
      height: fit-content;
      &:after {
        display: none;
      }
    }
    .more {
      border: 2px solid v.$primary;
      border-radius: 20px;
      padding: 8px 15px;
      width: fit-content;
      margin: 20px auto;
      white-space: nowrap;
      margin-bottom: 80px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .divider {
    width: 1.5px;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.1);
  }
  .right {
    height: 50%;
    .info {
      color: initial;
      padding-bottom: 80px;
      .item {
        width: 90%;
        margin: 32px 0;
        padding-bottom: 32px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        p {
          font-family: "Cabinet Grotesk";
          font-size: 20px;
        }
        .info_low {
          text-transform: uppercase;
          font-family: "Gratelos";
          font-size: 56px;
          color: #59c150;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .player_profile {
    display: flex;
    flex-direction: column;
    gap: unset;
    .title {
      font-size: 56px;
    }
    .left {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      b,
      i,
      u {
        font-size: 24px;
      }
      .title {
        font-size: 56px;
      }
      .bio {
        height: 200px;
      }
      .bio_more {
        height: fit-content;
      }
    }
    .right {
      .info {
        .item {
          width: 100%;
          .info_low {
            font-size: 48px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .player_profile {
    .title {
      font-size: 32px;
    }
    .left {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      b,
      i,
      u {
        font-size: 20px;
      }
      .more {
        margin-bottom: 40px;
      }
      .title {
        font-size: 32px;
      }
    }
    .right {
      .info {
        padding-bottom: 0;
        .item {
          margin: 16px 0;
          padding-bottom: 16px;
          width: 100%;
          p {
            font-size: 16px;
          }
          .info_low {
            font-size: 36px;
          }
        }
      }
    }
  }
}
