.profile {
  margin-top: v.$header_h;
  //   background-color: #181f39;
  //   padding: 30px 30px 30px 30px;

  .content {
    // max-width: 1512px;
    margin: auto;
    // padding: 0 20vw;
    .profile_top {
      display: none;
      justify-content: space-between;
      .section_title {
        color: #ffffff;
        margin-left: 10px;
      }
    }

    .details {
      .view_profile {
        margin: 20px 0;
        .img {
          position: relative;
          width: 170px;
          height: 170px;
          border-radius: 100%;

          .avatar {
            width: 170px;
            height: 170px;
            border-radius: 100px;
            margin: 10px 0;
          }
          .avatar2 {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
          .badge {
            position: absolute;
            width: 3rem;
            height: 3rem;
            border-radius: 100px;
            bottom: 0;
            left: 6rem;
            top: 80%;
          }
          .badge2 {
            position: absolute;
            width: 3rem;
            height: 3rem;
            border-radius: 100px;
            bottom: -10px;
            left: 4rem;
          }
        }
        .text {
          background-color: #59c150;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 6px 0px 6px 0px;
          p {
            font-family: "Cabinet Grotesk";
            text-transform: uppercase;
            font-size: 5rem;
          }
        }
        .info_container {
          font-size: 30px;
          display: flex;
          margin: 20px 0;
          gap: 30px;
          span {
            font-family: "Cabinet Grotesk";
          }
        }
      }

      .edit_profile {
        background-color: #ffffff;
        // margin: 20px 0;
        padding: 48px 0;
        .container {
          width: 50%;
          margin: auto;
          .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            align-items: center;
            h1 {
              margin: 0;
              padding: 0;
              font-size: 56px;
              color: v.$accent;
            }
            .view_pro {
              cursor: pointer;
              font-size: 24px;
              background-color: v.$accent;
              color: #ffffff;
              padding: 10px 20px;
              border-radius: 8px;
            }
          }
          .avi {
            display: flex;
            // justify-content: center;
            align-items: center;
            .img {
              background: #000000;
              .avatar2 {
                opacity: 0.5;
              }
            }
          }
          .form {
            width: 50vw;
            margin: auto;
            // padding: 50px 0;
            .fields {
              margin-top: 24px;
              margin-bottom: 40px;
              .input_container {
                border: 2px solid #808080;
                border-radius: 8px;
                margin-top: 16px;
                margin-bottom: 32px;
                padding: 0 10px;
                .form-control {
                  //   padding: 20px 10px;
                  width: 100%;
                  //   margin: 20px 0;
                  font-size: 20px;
                  outline: none;
                  font-family: "Cabinet Grotesk";
                  border: none;
                  border-radius: 8px;
                  padding: 0;
                  //   border-bottom: 1px solid #808080;
                }
              }
            }
          }
          .password_modal {
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #0000004c;
            position: absolute;

            .top {
              display: flex;
              justify-content: space-between;
              span {
                font-size: 40px;
                cursor: pointer;
              }
            }
            .password_change {
              background-color: #ffffff;
              width: 50%;
              max-width: 1512px;
              margin: 30vh auto;
              padding: 20px;

              .form {
                width: 100%;
                .btn {
                  margin-top: 20px;
                }
                .password {
                  border-bottom: 1px solid #808080;
                  display: flex;
                  input {
                    padding: 20px 10px;
                    font-size: 20px;
                    outline: none;
                    width: 100%;
                    font-family: "Cabinet Grotesk";
                    border: none;
                  }
                  span {
                    align-self: center;
                  }
                }
                .eyes {
                  cursor: pointer;
                  font-size: 18px;
                }
              }
            }
          }
          .img {
            position: relative;
            width: 170px;
            height: 170px;
            border-radius: 100%;
            overflow: hidden;
            .avatar2 {
              width: 100%;
              height: 100%;
              border-radius: 100px;
            }
          }
          .upload-pic {
            width: fit-content;
            // margin-bottom: 20px;
            position: absolute;
            margin-left: 26px;
            margin-right: 26px;
            .upload-btn-wrapper {
              overflow: hidden;
              //   display: inline;
              position: relative;
            }
            .btn {
              font-size: 20px;
              padding: 10px 20px;
              border: 1px solid transparent;
              background-color: transparent;
              color: #ffffff;
              text-transform: uppercase;
            }

            .upload-btn-wrapper input[type="file"] {
              position: absolute;
              left: 0;
              top: 20%;
              height: 100%;
              width: 100%;
              opacity: 0;
            }
          }
          .detail_container {
            #progress {
              font-size: 18px;
            }
            #file_name {
              margin: 5px 0;
              font-size: 18px;
            }
          }
          .form {
            h1 {
              font-size: 50px;
              margin-bottom: 30px;
            }
            label {
              font-size: 30px;
              text-transform: uppercase;
              color: v.$accent;
            }
            .form-control {
              padding: 10px 10px;
              width: 100%;
              margin: 20px 0;
              font-size: 20px;
              outline: none;
              font-family: "Cabinet Grotesk";
              border: none;
              border-bottom: 1px solid #808080;
            }
            .btn {
              display: flex;
              justify-content: space-between;
              .form-control-btn {
                width: 150px;
                height: 60px;
                font-size: 24px;
                text-transform: uppercase;
                outline: none;
                // font-family: "Cabinet Grotesk";
                border: none;
                border-radius: 8px;
                background-color: v.$accent;
                color: #ffffff;
                border-bottom: 1px solid #808080;
                transition: all ease-in-out 0.3s;
                box-sizing: border-box;
                border: 1px solid transparent;
                cursor: pointer;
                &:hover {
                  border: 1px solid v.$accent;
                  background-color: #ffffff;
                  color: v.$accent;
                }
              }
              .form-control-change {
                width: max-content;
                padding: 0 10px;
                height: 60px;
                cursor: pointer;
                background-color: transparent;
                font-size: 24px;
                text-transform: uppercase;
                outline: none;
                border: 2px solid v.$accent;
                border-radius: 8px;
                color: v.$accent;
                // border-bottom: 1px solid #808080;
                transition: all ease-in-out 0.3s;
                box-sizing: border-box;
                &:hover {
                  background-color: v.$accent;
                  color: #ffffff;
                }
              }
            }

            .select {
              width: 102%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .profile {
    .content {
      .details {
        .edit_profile {
          .password_modal {
            .password_change {
              width: 70%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .profile {
    .content {
      .details {
        .edit_profile {
          .container {
            .top {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 40px;
              flex-direction: column;
              align-items: initial;
            }
          }
          .password_modal {
            .password_change {
              width: 90%;
            }
          }
        }
      }
    }
  }
}
