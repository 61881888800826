.blog-container {
  background-color: #f2f2f2;
  padding: 48px 64px;
  .title {
    p {
      font-size: 28px;
      color: #202e55;
    }
  }
  .team-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    .host-logo,
    .opponent-logo {
      text-align: center;
      img {
        height: 84px;
        opacity: 1;
      }
    }
    p {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
    }
  }
  .stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 40px;
    .stats-content {
      font-size: 56px;
      color: #202e55;
    }
    p {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
    }
  }

  .line-stat {
    margin-top: 5px;
    padding: 6px 0;
    width: 100%;
    position: relative;
    background: rgba($color: #000000, $alpha: 0.3);
    .host-percentage {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 52%;
      height: 100%;
      background-color: #202e55;
    }
  }
  .card-stat {
    display: flex;
    // gap: 158px;
    justify-content: space-between;
    margin-top: 20px;
    .card-host,
    .card-opponent {
      display: flex;
      gap: 20px;
    }
    .yellow {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
      font-weight: bold;
      padding: 8px 4px;
      background-color: #fadf4a;
      min-width: 30px;
      text-align: center;
    }
    .red {
      font-family: "Cabinet Grotesk";
      font-size: 20px;
      font-weight: bold;
      padding: 8px 4px;
      background-color: red;
      color: #ffffff;
      min-width: 30px;
      text-align: center;
    }
  }
}
.more_match_news {
  padding: 20px 30px;
  .news {
    .news_container {
      overflow: hidden;
      display: grid;
      gap: 30px;
      grid-auto-rows: 350px;
    }
    .items {
      .news_card {
        overflow: hidden;
        border-radius: 7px;
        img {
          opacity: initial;
        }
        .card {
          overflow: hidden;
          border-radius: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .blog-container {
    padding: 48px 0px;
  }
}

@media screen and (max-width: 414px) {
  .blog-container {
    .team-icons {
      p {
        display: none;
      }
    }
    .stats {
      .stats-content {
        font-size: 36px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
