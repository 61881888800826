.match_review {
  max-width: 1512px;
  margin: 32px auto;
  margin-bottom: 0;
  transform: scale(1);
  margin-top: -45px;
  margin-bottom: 45px;
  padding: 0 64px;
  .swiper {
    height: fit-content;
    min-height: 388px;
    overflow: hidden;
  }
  .swiper-pagination {
    top: 95%;
    bottom: 0 !important;
  }
  .swiper-pagination-bullet-active {
    background: v.$accent;
  }

  .swiper-wrapper {
    margin-top: 75px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    top: 0%;
    margin-top: 0;
    &::after {
      font-size: 24px !important;
    }
  }

  .swiper-button-prev {
    right: 136px;
    font-size: 32px !important;
    left: auto;
    color: #202e55;
    margin-right: -60px;
  }

  .swiper-button-next {
    right: 0px;
    font-size: 32px !important;
    color: #202e55;
  }
}

@media screen and (max-width: 1024px) {
  .match_review {
    padding: 0 24px;
  }
}

@media screen and (max-width: 760px) {
  .match_review {
    margin-bottom: 15px;
    padding: 0;
    .swiper-button-next {
      right: 24px !important;
    }
    .swiper-button-prev {
      margin-right: -36px;
    }
  }
}

@media screen and (max-width: 414px) {
  .match_review {
    padding: 0;
    .swiper-button-prev,
    .swiper-button-next {
      margin-top: 7px;
      &::after {
        font-size: 20px !important;
      }
    }
    .swiper-button-prev {
      margin-right: -56px;
    }
    .swiper-button-next {
      right: 23px !important;
    }
  }
}

@media screen and (max-width: 360px) {
  .match_review {
    padding: 0 16px;
    .swiper-button-prev {
      margin-right: -70px;
    }
    .swiper-button-next {
      right: 7px !important;
    }
  }
}
