.player_carousel {
  // margin-top: 30px;
  .swiper {
    // min-height: 450px;
    height: max-content;
    // padding-top: 10px;
    padding-bottom: 40px;
    margin-top: -45px;
  }
  .swiper-wrapper {
    margin-top: 75px;
  }
  .swiper-pagination {
    top: 95% !important;
  }
  .swiper-pagination-bullet {
    background: gold;
  }
  .swiper-pagination-bullet-active {
    background: #ffffff;
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 0%;
    margin-top: 0;
    &::after {
      font-size: 24px;
    }
  }

  .swiper-button-prev {
    right: 122px;
    font-size: 32px !important;
    font-weight: bold;
    left: auto;
    margin-right: -46px;
  }

  .swiper-button-next {
    right: 0;
    font-size: 32px !important;
    font-weight: bold;
  }
}

@media screen and (max-width: 1500px) {
  .player_carousel {
    .swiper {
      padding: 0 64px;
      padding-bottom: 40px;
    }
    .swiper-button-next {
      right: 64px;
    }
    .swiper-button-prev {
      right: 186px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .player_carousel {
    .swiper {
      padding: 0 24px;
      padding-bottom: 40px;
    }
    .swiper-button-next {
      right: 24px;
    }
    .swiper-button-prev {
      right: 146px;
    }
  }
}

@media screen and (max-width: 414px) {
  .player_carousel {
    .swiper {
      // min-height: 370px;
      padding: 0 16px;
      padding-bottom: 50px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      margin-top: 7px;
      &::after {
        font-size: 20px !important;
      }
    }
    .swiper-button-next {
      right: 16px;
    }
    .swiper-button-prev {
      right: 130px;
      margin-right: -55px;
    }
  }
}
