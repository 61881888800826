.product-page {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;

  .product-image {
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    //   object-fit: fill;
    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      border: 1px solid #ddd;
    }
  }

  .product-details {
    flex: 1;
    max-width: 1200px;

    h1 {
      font-size: 4rem;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    //   font-family: "Cabinet Grotesk";
    }

    p {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      font-family: "Cabinet Grotesk";
      font-weight: bold;
    }

    ul {
      margin-bottom: 1rem;
      list-style: disc;
      padding-left: 20px;
      font-size: 20px;
      font-family: "Cabinet Grotesk";
    }

    li{
        font-family: "Cabinet Grotesk";
    }
    
    .form-section {
        margin: 15px 0;
        .label{
            font-size: 20px;

        }
      .options {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        button {
          font-size: 20px;
          padding: 8px 15px;
          border: 1px solid #ccc;
          background: #f9f9f9;
          border-radius: 4px;
          cursor: pointer;

          &.active {
            background: #202e55;
            color: white;
            border-color: #202e55;
          }

          &:hover {
            background: #4b7aee;
            color: white;
          }
        }
      }

      .radio-group {
        display: flex;
        gap: 20px;

        label {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .quantity-container {
      display: flex;
      align-items: center;
      gap: 5px;

      button {
        padding: 5px 10px;
        background-color: #202e55;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #202e55;
        }
      }

      input {
        text-align: center;
        width: 50px;
      }
    }

    .radio-group{
        font-family: "Cabinet Grotesk";
        font-size: 18px;
    }

    .price-section {
      margin: 15px 0;
    //   font-size: 1.2rem;
      font-weight: bold;
      display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   height: 100%;
    }

    .add-to-cart-btn {
      padding: 10px 15px;
      background: #202e55;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: #4b7aee;
      }
    }
  }

  .group{
    display: flex;
    flex-direction: column;
    gap: 10px;
    // gap: 10px;
    // align-items: center;
    // justify-content: center;
    height: 100%;
    .input{
        border-radius: 5px;
        height: 30px;
        width: 150px;
        padding-left: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border-style: none;
        font-size: 1.2rem;
        font-family: "Cabinet Grotesk";
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .product-image {
      margin-bottom: 20px;
    }

    .product-details {
      width: 100%;
    }
  }
}
