.result-card-container {
  padding: 0 64px;
  max-width: 1512px;
  margin: auto;
  margin-top: 24px;
  .result-card {
    background-color: #ffffff;
    display: flex;
    max-width: 1512px;
    margin: auto;
    align-items: center;
    padding: 0 24px;
    border-radius: 8px;
    // box-shadow: #000000;
    .result-card-top-small {
      display: none;
    }
    .deets-and-cta {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .result-deets {
        display: flex;
        .result-deets1 {
          display: flex;
          align-items: center;
          .result-card-venue {
            font-family: "Cabinet Grotesk";
            font-size: 16px;
            font-weight: 700;
            color: rgba($color: #000000, $alpha: 0.5);
            margin-right: 2vw;
          }
          .league-icon {
            margin-right: 2vw;
            img {
              width: 36px;
            }
          }
          .matchcard-time {
            font-size: 36px;
          }
          .date {
            width: 150px;
            .matchcard-date {
              font-family: "Cabinet Grotesk";
              font-size: 20px;
              font-weight: 700;
              color: rgba($color: #000000, $alpha: 0.5);
            }
          }
        }
        .result-deets2 {
          display: flex;
          flex-grow: 2;
          align-items: center;
          img {
            width: 72px;
            padding-left: 4vw;
          }
          .scoreline {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            // gap: 8px;
            margin-left: 32px;
            // font-size: 30px;
            .opponent {
              color: rgba(0, 0, 0, 0.5);
              margin-top: 4px;
            }
            .cont {
              display: flex;
              //   gap: 10px;
              font-family: "Gratelos";
              font-size: 36px;
              text-transform: uppercase;
            }
            .rev {
              display: flex;
              flex-direction: column-reverse;
              text-transform: uppercase;
            }
            .score {
              display: flex;
              width: 20px;
              text-align: center;
              justify-content: center;
            }

            .line {
              align-self: center;
              display: inline-block;
              margin: 0 10px;
              height: 20px;
              border: 1px solid rgba(0, 0, 0, 0.16);
            }
          }
        }
      }
    }

    .link {
      margin: 36px 0;
      a {
        background-color: transparent;
        color: #202e55;
        text-decoration: none;
        font-size: 24px;
        font-family: "Gratelos";
        padding: 14px 24px;
        border-radius: 26px;
        border: 2px solid #202e55;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .result-card-container {
    padding: 0 24px;
    .result-card {
      position: relative;
      padding-top: 32px;
      padding-bottom: 8px;
      .link {
        a {
          font-size: 16px;
          padding: 12px 24px;
        }
      }

      .result-deets1 {
        .league-icon {
          margin-right: unset;
          img {
            width: 28px;
          }
        }
        .result-card-venue {
          display: none;
        }
        .date {
          display: none;
        }
      }
      .result-card-top-small {
        display: flex;
        position: absolute;
        top: 12px;
        left: 0;
        right: 0;
        padding: 0 24px;
        justify-content: space-between;
        .date-small {
          //   position: absolute;
          display: flex;
          left: 12px;
          .time {
            .matchcard-time {
              font-size: 20px;
              margin-right: 8px;
            }
          }
          .matchcard-date {
            font-family: "Cabinet Grotesk";
            font-size: 14px;
            color: rgba($color: #000000, $alpha: 0.7);
          }
        }
        .result-card-venue {
          font-family: "Cabinet Grotesk";
          font-size: 14px;
          color: rgba($color: #000000, $alpha: 0.7);
        }
      }
      .result-deets2 {
        img {
          width: 48px;
        }
        .scoreline {
          margin-left: 18px;
          .cont {
            font-size: 20px;
            margin-left: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .result-card-container {
    .result-card {
      padding: 38px 16px 0px 16px;
      .result-card-top-small {
        padding: 0 16px;
      }
      .link {
        margin: 36px auto;
      }
      .deets-and-cta {
        flex-direction: column;
        .result-deets {
          .result-deets2 {
            .scoreline {
              .cont {
                font-size: 28px;
              }
              img {
                width: 64px;
                padding-left: 2vw;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .result-card-container {
    padding: 0 16px;
    .result-card {
      padding: 38px 8px 0px 8px;
      .result-card-top-small {
        padding: 0 8px;
      }
      .deets-and-cta {
        .result-deets {
          .result-deets1 {
            .league-icon {
              img {
                width: 24px;
              }
            }
          }
          .result-deets2 {
            img {
              width: 48px;
            }
            .scoreline {
              margin-left: 18px;
              .cont {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
  }
}
